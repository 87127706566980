import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, FieldAttributes, Form, Formik, useFormikContext, FieldProps } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../store';
import { BIRTHDATE_DATE_INVALID, FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import { registerProfileStepTwo } from '../store/authentication/auth.actions';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countries, { getName, getNames } from "i18n-iso-countries";
import fr from 'date-fns/locale/fr';
import EmojiFlags from "emoji-flags";
import { nationalityMapping } from '../utils/helpers/nationality-mapping';
import Select, { OnChangeValue } from 'react-select';
import { UserRegistrationProgressStep } from '../services/auth.service';
import { africanCountryCodes, prioritizedCountryCodes } from '../utils/config/country-code.config';
import { CountrySelectProps } from '../types/country-select.types';
import { SelectOption } from '../types/select.types';
import Logo from '../assets/images/logo.png';
import userService, { GeoLocationData } from '../services/user.service';

registerLocale('fr', fr);
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

interface FormValues {
  birthdate: Date | null;
  nationality: string;
  addressCountry: string;
}

const RegisterProfileStepTwo = () => {
  const [successful, setSuccessful] = useState(false);
  const [geoInfo, setGeoInfo] = useState<GeoLocationData | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useAppDispatch();
  
  const { message } = useAppSelector(state => state.message);

  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  const userRegistrationNextStep = useAppSelector(state => state.auth.userRegistrationNextStep);

  const initialNavigate = useRef(navigate);
  const initialUserRegistrationNextStep = useRef(userRegistrationNextStep);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    const fetchGeoInfo = async () => {
      const geoInfo = await userService.getGeoInfo();
      setGeoInfo(geoInfo);
    }

    fetchGeoInfo();
  }, [dispatch]);

  useEffect(() => {
    if (
      navigate === initialNavigate.current &&
      userRegistrationNextStep === initialUserRegistrationNextStep.current
    ) {
      if (userRegistrationNextStep !== UserRegistrationProgressStep.PROFILE_PART_TWO_PROFILE_TO_COMPLETE) {
        navigate('/login');
      }
    }
  }, [navigate, userRegistrationNextStep]);

  const initialValues: FormValues = {
    birthdate: null,
    nationality: '',
    addressCountry: '',
  };

  const Birthdate = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
  
    const handleBirthdateChange = (date: Date | null) => {
      setFieldValue('birthdate', date);
    };
  
    return (
      <div>
        <DatePicker
          id='birthdate'
          name='birthdate'
          selected={values.birthdate}
          onChange={handleBirthdateChange}
          showMonthDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={100}
          dateFormat="dd/MM/yyyy"
          placeholderText="JJ/MM/AAAA"
          maxDate={new Date()}
          openToDate={new Date("01/01/2000")}
          locale="fr"
          className="form-input w-full h-16 text-base"
        />
        <div className="text-base mt-1 text-rose-500">
          <ErrorMessage name='birthdate' />
        </div>
      </div>
    );
  }

  const CountrySelect: React.FC<CountrySelectProps> = ({ field, form, options, placeholder }) => {
    const handleChange = (selectedOption: OnChangeValue<SelectOption, false>) => {
      if (selectedOption && 'value' in selectedOption) {
        form.setFieldValue(field.name, selectedOption.value);
      }
    };

    const selectedOption = options.find((option) => option.value === field.value);

    return (
      <Select
        {...field}
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isSearchable
        classNames={{
          control: (state) =>
            state.isFocused ? '!border-amber-300 !shadow-none form-input w-full h-16 text-base' : '!border-gray-200 form-input w-full h-16 text-base',
          placeholder: () => '!text-gray-400',
        }}
      />
    );
  };

  const handleSubmit = async (values: FormValues) => {
    const { birthdate, nationality, addressCountry} = values;
  
    const userId = user?.user?.id;

    setSuccessful(false);
    setDisableSubmit(true);
    try {
      await dispatch(registerProfileStepTwo({ userId, profile: { birthdate, nationality, addressCountry } })).unwrap();
      setSuccessful(true);
      setDisableSubmit(false);
    } catch (err) {
      setSuccessful(false);
      setDisableSubmit(false);
    }
  };

  const validationSchema = Yup.object({
    birthdate: Yup.date().typeError(BIRTHDATE_DATE_INVALID).required(FIELD_REQUIRED),
    nationality: Yup.string().required(FIELD_REQUIRED),
    addressCountry: Yup.string().required(FIELD_REQUIRED),
  });

  const getNationalityName = (countryCode: string, fallbackName: string) => {
    return nationalityMapping[countryCode] || fallbackName;
  };

  const nationalityOptions = Object.entries(getNames("fr", { select: "official" })).map(([code, name]) => ({
    value: code,
    label: `${EmojiFlags.countryCode(code).emoji} ${getNationalityName(code, name)}`,
  } as SelectOption));

  const prioritizedNationalities = prioritizedCountryCodes.map((code) => ({
    value: code,
    label: `${EmojiFlags.countryCode(code).emoji} ${getNationalityName(code, getName(code, "fr", { select: "official" }))}`,
  } as SelectOption));

  const otherNationalities = nationalityOptions.filter(
    (option) => !prioritizedCountryCodes.includes(option.value)
  );

  const sortedNationalityOptions = [
    ...prioritizedNationalities,
    ...otherNationalities,
  ];

  const addressCountryOptions = Object.entries(getNames("fr", { select: "official" }))
    .filter(([code]) => africanCountryCodes.includes(code))
    .map(([code, name]) => ({ value: code, label: `${EmojiFlags.countryCode(code).emoji} ${name}` } as SelectOption));

  const prioritizedAddressCountries = prioritizedCountryCodes.map((code) => ({
    value: code,
    label: `${EmojiFlags.countryCode(code).emoji} ${getName(code, "fr", { select: "official" })}`,
  } as SelectOption));

  const otherCountries = addressCountryOptions.filter(
    (option) => !prioritizedCountryCodes.includes(option.value)
  );

  const sortedAddressCountryOptions = [
    ...prioritizedAddressCountries,
    ...otherCountries,
  ];

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            {!successful && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6">Super ! Continuons à enrichir votre profil avec quelques informations supplémentaires</h1>
                {/* Form */}

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="space-y-8">
                      <div>
                        <label className="block text-base font-medium mb-1" htmlFor="birthdate">Date de naissance</label>
                        <Birthdate name="birthdate" />
                      </div>

                      <div>
                        <label className="block text-base font-medium mb-1" htmlFor="nationality">Nationalité</label>
                        <Field
                          name="nationality"
                          placeholder="Sélectionnez votre nationalité"
                          options={sortedNationalityOptions}
                          component={CountrySelect}
                          className="form-input w-full h-16 text-base"
                        />
                        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="nationality"/></div>
                      </div>


                      <div>
                        <label className="block text-base font-medium mb-1" htmlFor="addressCountry">Pays de résidence</label>
                        <Field
                          name="addressCountry"
                          placeholder="Sélectionnez votre pays de résidence"
                          options={sortedAddressCountryOptions}
                          component={CountrySelect}
                          className="form-input w-full h-16 text-base"
                          defaultValue={geoInfo?.country}
                        />
                        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="addressCountry"/></div>
                      </div>

                    </div>
                    <div className="justify-center mt-6">
                      {message && (
                        <div className="text-center mt-4">
                          <div className="text-base mt-1 text-rose-500">{ message }</div>
                        </div>
                      )}

                      <div className="text-center mt-4">
                        <button type="submit" disabled={disableSubmit} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Enregistrer</button>
                      </div>
                    </div>
                  </Form>
                </Formik>

              </div>
            )}

            {successful && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Féliciations, votre compte a été créé !</h1>
                <div className="text-lg text-center">
                  Connectez-vous afin de commencer à investir maintenant.
                </div>
                <div className="text-center mt-4">
                  <Link to={'/login'}>
                    <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Connectez-vous</button>
                  </Link>
                </div>
              </div>
              )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default RegisterProfileStepTwo;