export interface DocumentTypeByCountry {
  [key: string]: string[];
}

export interface DocumentTypeName {
  [key: string]: string;
}

export const documentTypesByCountry: DocumentTypeByCountry = {
  CI: ['PASSPORT', 'IDENTITY_CARD', 'DRIVERS_LICENSE', 'RESIDENT_ID', 'HEALTH_CARD'],
  BJ: ['PASSPORT', 'IDENTITY_CARD', 'DRIVERS_LICENSE', 'VOTER_ID', 'RESIDENT_ID'],
  DZ: ['PASSPORT', 'IDENTITY_CARD', 'DRIVERS_LICENSE', 'HEALTH_CARD'],
  AO: ['PASSPORT', 'IDENTITY_CARD', 'RESIDENT_ID', 'VOTER_ID', 'DRIVERS_LICENSE'],
  BW: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  BF: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  BI: ['DRIVERS_LICENSE', 'PASSPORT', 'VOTER_ID'],
  CM: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'OCCUPATION_CARD', 'PASSPORT', 'VOTER_ID'],
  CV: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  CF: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  TD: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'VOTER_ID'],
  KM: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT'],
  CG: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  CD: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  DJ: ['DRIVERS_LICENSE', 'HEALTH_CARD', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  EG: ['DRIVERS_LICENSE', 'HEALTH_CARD', 'IDENTITY_CARD', 'PASSPORT'],
  GQ: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  ER: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  SZ: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  ET: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  GA: ['DRIVERS_LICENSE', 'HEALTH_CARD', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  GM: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  GH: ['DRIVERS_LICENSE', 'HEALTH_CARD', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  GN: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  GW: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'VOTER_ID'],
  KE: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  LS: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  LR: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  LY: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  MG: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  MW: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  ML: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  MR: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  MU: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  YT: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  MA: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  MZ: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  NA: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  NE: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  NG: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  RE: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  RW: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  SH: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  ST: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  SN: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  SC: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  SL: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  SO: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  ZA: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  SS: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  SD: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  TZ: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  TG: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  TN: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  UG: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  EH: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID'],
  ZM: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
  ZW: ['DRIVERS_LICENSE', 'IDENTITY_CARD', 'PASSPORT', 'RESIDENT_ID', 'VOTER_ID'],
};

export const documentTypeNames: DocumentTypeName = {
  'PASSPORT': 'Passeport',
  'IDENTITY_CARD': 'Carte d\'identité nationale ou Carte d\'identité consulaire ou Carte d\'identité diplomatique',
  'DRIVERS_LICENSE': 'Permis de conduire',
  'HEALTH_CARD': 'Carte d\'assurance Maladie ou Carte de Santé',
  'VOTER_ID': 'Carte d\'identité d\'électeur',
  'RESIDENT_ID': 'Carte de résident ou Permis de résident',
};