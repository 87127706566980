import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { login, logout, signUp, sendResetPasswordLink, verify, registerProfileStepOne, registerProfileStepTwo, saveKycInfo } from "./auth.actions";
import { LoginFailedResponse, LoginResponse, SignUpResponse, UserRegistrationProgressStep } from "../../services/auth.service";
import { ErrorMessageCode } from "../../error-message-code";
import { isTokenExpired } from "../../utils/helpers/is-token-expired";


interface UserState {
  user : {  
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
    isKycCompleted?: boolean;
    residenceCountryCode?: string;
  },
  accessToken?: string;
}
interface AuthState {
  isLoggedIn: boolean;
  user: UserState | null | undefined;
  userRegistrationNextStep: UserRegistrationProgressStep | null;
}

let user;
const userItem = localStorage.getItem("user");
if (userItem) {
  user = JSON.parse(userItem);
}

const initialState = user && !isTokenExpired(user?.accessToken) ? { isLoggedIn: true, user} as AuthState : { isLoggedIn: false, user: null } as AuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUserRegistrationNextStep: (state: AuthState) => {
      state.userRegistrationNextStep = null;
    },
    setUserKycCompleted: (state: AuthState) => {
      if (state.user && state.user.user) {
        state.user.user.isKycCompleted = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.fulfilled, (state: AuthState, action: PayloadAction<{ user : SignUpResponse }>) => {
        state.isLoggedIn = false;
        state.user = action.payload;
        state.userRegistrationNextStep = UserRegistrationProgressStep.EMAIL_TO_VERIFY;
      })
      .addCase(signUp.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(verify.fulfilled, (state: AuthState) => {
        state.userRegistrationNextStep = UserRegistrationProgressStep.PROFILE_PART_ONE_PROFILE_TO_COMPLETE;
        state.isLoggedIn = false;
      })
      .addCase(verify.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(registerProfileStepOne.fulfilled, (state: AuthState) => {
        state.userRegistrationNextStep = UserRegistrationProgressStep.PROFILE_PART_TWO_PROFILE_TO_COMPLETE;
        state.isLoggedIn = false;
      })
      .addCase(registerProfileStepOne.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(registerProfileStepTwo.fulfilled, (state: AuthState) => {
        state.userRegistrationNextStep = null;
        state.isLoggedIn = false;
      })
      .addCase(registerProfileStepTwo.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state: AuthState, action: PayloadAction<{ loginResponse: LoginResponse | null }>) => {
        state.isLoggedIn = true;
        state.user = action.payload.loginResponse;
      })
      // TODO: update to fix issues on type unknown when using createAsyncThunk return rejectWithValue https://github.com/reduxjs/redux-toolkit/issues/1707
      .addCase(login.rejected, (state: AuthState, action: PayloadAction<unknown>) => {
        let loginFailedResponse = action.payload as LoginFailedResponse;

        switch (loginFailedResponse.messageCode) {
          case ErrorMessageCode.USER_ACCOUNT_EMAIL_NOT_VERIFIED_CODE:
            state.userRegistrationNextStep = UserRegistrationProgressStep.EMAIL_TO_VERIFY;
            break;
        
            case ErrorMessageCode.USER_PROFILE_PART_ONE_NOT_COMPLETED_CODE:
              state.userRegistrationNextStep = UserRegistrationProgressStep.PROFILE_PART_ONE_PROFILE_TO_COMPLETE;
            break;

            case ErrorMessageCode.USER_PROFILE_PART_TWO_NOT_COMPLETED_CODE:
              state.userRegistrationNextStep = UserRegistrationProgressStep.PROFILE_PART_TWO_PROFILE_TO_COMPLETE;
            break;
          default:
            break;
        }

        state.isLoggedIn = false;
        state.user = loginFailedResponse;
      })
      .addCase(logout.fulfilled, (state: AuthState) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(sendResetPasswordLink.fulfilled, (state: AuthState) => {
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(sendResetPasswordLink.rejected, (state: AuthState) => {
        state.isLoggedIn = false;
        state.user = null;
      })
  }
});

export const { clearUserRegistrationNextStep, setUserKycCompleted } = authSlice.actions;

export default authSlice.reducer;