import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'

interface MessageState {
  message: string;
};

const initialState = {
  message: '',
} as MessageState;

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state: MessageState, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    clearMessage: (state: MessageState) => {
      state.message = ""
    },
  },
});

export const { setMessage, clearMessage } = messageSlice.actions

export default messageSlice.reducer;