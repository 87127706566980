import axios from "axios";
import authHeader from "../utils/helpers/auth-header";

const paydunyaApiBaseUrl = process.env.REACT_APP_PAYDUNYA_API_BASE_URL;
const isDev = process.env.REACT_APP_NODE_ENV === 'development';

export interface PaydunyaMakePaymentParams {
  token: string;
  phoneNumber: string;
  customer: {
    fullname?: string;
    email: string;
  }
  countryIsoCode2: string;
  paymentTypeCode: string;
  otpValue?: string;
}

export interface PaydunyaMakePaymentResponse {
  success: boolean;
  message: string;
  url?: string;
}

const buildMakePaymentParams = (paydunyaMakePaymentParams: PaydunyaMakePaymentParams) => {
  if (isDev) {
    return {
      phone_phone: paydunyaMakePaymentParams.phoneNumber,
      customer_email: paydunyaMakePaymentParams.customer.email,
      password: paydunyaMakePaymentParams.otpValue,
      invoice_token: paydunyaMakePaymentParams.token
    };
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'CI' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return {
          orange_money_ci_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          orange_money_ci_email: paydunyaMakePaymentParams.customer.email,
          orange_money_ci_phone_number: paydunyaMakePaymentParams.phoneNumber,
          orange_money_ci_otp: paydunyaMakePaymentParams.otpValue,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MTN':
        return {
          mtn_ci_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          mtn_ci_email: paydunyaMakePaymentParams.customer.email,
          mtn_ci_phone_number: paydunyaMakePaymentParams.phoneNumber,
          mtn_ci_wallet_provider: "MTNCI",
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MOOV':
        return {
          moov_ci_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          moov_ci_email: paydunyaMakePaymentParams.customer.email,
          moov_ci_phone_number: paydunyaMakePaymentParams.phoneNumber,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'WAVE':
        return {
          wave_ci_fullName: paydunyaMakePaymentParams.customer.fullname,
          wave_ci_email: paydunyaMakePaymentParams.customer.email,
          wave_ci_phone: paydunyaMakePaymentParams.phoneNumber,
          wave_ci_payment_token : paydunyaMakePaymentParams.token
        };
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'BJ' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'MTN':
        return {
          mtn_benin_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          mtn_benin_email: paydunyaMakePaymentParams.customer.email,
          mtn_benin_phone_number: paydunyaMakePaymentParams.phoneNumber,
          mtn_benin_wallet_provider: "MTNBENIN",
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MOOV':
        return {
          moov_benin_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          moov_benin_email: paydunyaMakePaymentParams.customer.email,
          moov_benin_phone_number: paydunyaMakePaymentParams.phoneNumber,
          payment_token: paydunyaMakePaymentParams.token
        };
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'BF' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return {
          name_bf: paydunyaMakePaymentParams.customer.fullname,
          email_bf: paydunyaMakePaymentParams.customer.email,
          phone_bf: paydunyaMakePaymentParams.phoneNumber,
          otp_code: paydunyaMakePaymentParams.otpValue,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MOOV':
        return {
          moov_burkina_faso_fullName: paydunyaMakePaymentParams.customer.fullname,
          moov_burkina_faso_email: paydunyaMakePaymentParams.customer.email,
          moov_burkina_faso_phone_number: paydunyaMakePaymentParams.phoneNumber,
          moov_burkina_faso_payment_token: paydunyaMakePaymentParams.token
        };
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'TG' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'T_MONEY':
        return {
          name_t_money: paydunyaMakePaymentParams.customer.fullname,
          email_t_money: paydunyaMakePaymentParams.customer.email,
          phone_t_money: paydunyaMakePaymentParams.phoneNumber,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MOOV':
        return {
          moov_togo_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          moov_togo_email: paydunyaMakePaymentParams.customer.email,
          moov_togo_customer_address: 'Lome',
          moov_togo_phone_number: paydunyaMakePaymentParams.phoneNumber,
          payment_token: paydunyaMakePaymentParams.token
        };
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'ML' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return {
          orange_money_mali_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          orange_money_mali_email: paydunyaMakePaymentParams.customer.email,
          orange_money_mali_phone_number: paydunyaMakePaymentParams.phoneNumber,
          orange_money_mali_customer_address: 'Bamako',
          orange_money_mali_wallet_otp: paydunyaMakePaymentParams.otpValue,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'MOOV':
        return {
          moov_ml_customer_fullname: paydunyaMakePaymentParams.customer.fullname,
          moov_ml_email: paydunyaMakePaymentParams.customer.email,
          moov_ml_phone_number: paydunyaMakePaymentParams.phoneNumber,
          moov_ml_customer_address: 'Bamako',
          payment_token: paydunyaMakePaymentParams.token
        };
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'SN' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return {
          customer_name: paydunyaMakePaymentParams.customer.fullname,
          customer_email: paydunyaMakePaymentParams.customer.email,
          phone_number: paydunyaMakePaymentParams.phoneNumber,
          authorization_code: paydunyaMakePaymentParams.otpValue,
          invoice_token: paydunyaMakePaymentParams.token
        };
      case 'FREE_MONEY':
        return {
          customer_name: paydunyaMakePaymentParams.customer.fullname,
          customer_email: paydunyaMakePaymentParams.customer.email,
          phone_number: paydunyaMakePaymentParams.phoneNumber,
          payment_token: paydunyaMakePaymentParams.token
        };
      case 'WAVE':
        return {
          wave_senegal_fullName: paydunyaMakePaymentParams.customer.fullname,
          wave_senegal_email: paydunyaMakePaymentParams.customer.email,
          wave_senegal_phone: paydunyaMakePaymentParams.phoneNumber,
          wave_senegal_payment_token : paydunyaMakePaymentParams.token
        };
    }
  }
}

const getPaydunyaPaymentUrl = (paydunyaMakePaymentParams: PaydunyaMakePaymentParams) => {
  if (isDev) {
    return `${paydunyaApiBaseUrl}/checkout/make-payment`;
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'CI' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/orange-money-ci`;
      case 'MTN':
        return `${paydunyaApiBaseUrl}/softpay/mtn-ci`;
      case 'MOOV':
        return `${paydunyaApiBaseUrl}/softpay/moov-ci`;
      case 'WAVE':
        return `${paydunyaApiBaseUrl}/softpay/wave-ci`;
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'BJ' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'MTN':
        return `${paydunyaApiBaseUrl}/softpay/mtn-benin`;
      case 'MOOV':
        return `${paydunyaApiBaseUrl}/softpay/moov-benin`;
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'BF' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/orange-money-burkina`;
      case 'MOOV':
        return `${paydunyaApiBaseUrl}/softpay/moov-burkina`;
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'TG' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'T_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/t-money-togo`;
      case 'MOOV':
        return `${paydunyaApiBaseUrl}/softpay/moov-togo`;
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'ML' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/orange-money-mali`;
      case 'MOOV':
        return `${paydunyaApiBaseUrl}/softpay/moov-mali`;
    }
  }

  if (paydunyaMakePaymentParams.countryIsoCode2 === 'SN' ) {
    switch (paydunyaMakePaymentParams.paymentTypeCode) {
      case 'ORANGE_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/orange-money-senegal`;
      case 'FREE_MONEY':
        return `${paydunyaApiBaseUrl}/softpay/free-money-senegal`;
      case 'WAVE':
        return `${paydunyaApiBaseUrl}/softpay/wave-senegal`;
    }
  }

  return `${paydunyaApiBaseUrl}/checkout/make-payment`;
}

const makePayment = async (paydunyaMakePaymentParams: PaydunyaMakePaymentParams): Promise<PaydunyaMakePaymentResponse>  => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const params = buildMakePaymentParams(paydunyaMakePaymentParams);

  const makePaymentResponse = await axios.post(getPaydunyaPaymentUrl(paydunyaMakePaymentParams), params);

  return makePaymentResponse.data;
};

const paydunyaService = {
  makePayment,
};

export default paydunyaService;