import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import PersonalInformations from './pages/PersonalInformations';
import Exchange from './pages/Exchange';
import ExchangeTracking from './pages/ExchangeTracking';
import RegisterVerifyEmail from './pages/RegisterVerifyEmail';
import Logout from './pages/Logout';
import ResetPassword from './pages/ResetPassword';
import RegisterProfileStepOne from './pages/RegisterProfileStepOne';
import RegisterProfileStepTwo from './pages/RegisterProfileStepTwo';
import AccountVerification from './pages/AccountVerification';
import KycProcessStepOne from './pages/KycProcessStepOne';
import KycProcessStepTwo from './pages/KycProcessStepTwo';
import { useAppSelector } from './store';
import ProtectedRoute from './components/ProtectedRoute';
import { isTokenExpired } from './utils/helpers/is-token-expired';
import TransactionHistory from './pages/TransactionHistory';
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";

const isProd = process.env.REACT_APP_NODE_ENV === 'production';

const useGAPageTracking = () => {
  let location = useLocation();

  useEffect(() => {
    if (isProd) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);
};

const useHotjarPageTracking = () => {
  let location = useLocation();

  useEffect(() => {
    if (isProd) {
      Hotjar.stateChange(location.pathname);
    }
  }, [location]);
};

function App() {
  const HOTJAR_ID = 3746611;
  const HOTJAR_VERSION = 6;
  const GA_ID = 'G-GXRF8X12EN';

  const navigate = useNavigate();

  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const user = useAppSelector(state => state.auth.user);
  const accessToken = user?.accessToken;
  const userId = user?.user?.id;
 
  useEffect(() => {
    if (accessToken && isTokenExpired(accessToken)) {
      navigate('/login');
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    if (isProd) {
      Hotjar.init(HOTJAR_ID, HOTJAR_VERSION);

      if (isLoggedIn) {
        Hotjar.identify(userId as string, { userProperty: 'value' });
      }
    }
  }, [isLoggedIn, userId]);

  useEffect(() => {
    if (isProd) {
      ReactGA.initialize(GA_ID);
    }
  }, []);

  useGAPageTracking();
  useHotjarPageTracking();

  return (
    <Routes>
      <Route path="/" element={<Navigate to="exchange" replace />}/>
      <Route path="sign-up" element={<SignUp />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="sign-up/verify-email" element={<RegisterVerifyEmail />} />
      <Route path="register/profile/step/1" element={<RegisterProfileStepOne />} />
      <Route path="register/profile/step/2" element={<RegisterProfileStepTwo />} />
      <Route path="account/verification" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <AccountVerification />
          </ProtectedRoute>
        } 
      />
      <Route path="kyc/process/step/1" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <KycProcessStepOne />
          </ProtectedRoute>
        } 
      />
      <Route path="kyc/process/step/2" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <KycProcessStepTwo />
          </ProtectedRoute>
        } 
      />
      <Route path="account/personal-informations" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <PersonalInformations />
          </ProtectedRoute>
        } 
      />
      <Route
          path="exchange"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Exchange />
            </ProtectedRoute>
          }
        />
      <Route 
        path="exchange/tracking/:exchangeId" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <ExchangeTracking />
          </ProtectedRoute>
        }
      />
      <Route 
        path="transaction-history" 
        element={
          <ProtectedRoute isLoggedIn={isLoggedIn}>
            <TransactionHistory />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default App;
