import { FC, useEffect, useState } from 'react';
import MiniLoadingSpinner from '../MiniLoadingSpinner';
import MailtoLink from '../MailtoLink';
import io, { Socket } from 'socket.io-client';
import { PaydunyaEventNotification, PaydunyaTransactionStatus } from '../../types/paydunya-event-notification.type';

interface PaydunyaPaymentComponentProps {
  token: string;
  phoneNumber: string;
  onSuccess: () => void;
  onRetry: () => void;
}

const PaydunyaPaymentNotificationComponent: FC<PaydunyaPaymentComponentProps> = ({ token, phoneNumber, onSuccess, onRetry }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [paymentStatus, setPaymentStatus] = useState<'success' | 'failed' | 'pending' | 'cancelled'>('pending'); 
  const [countdown, setCountdown] = useState<number>(5);

  useEffect(() => {
    if (countdown === 0 && paymentStatus === 'success') {
      onSuccess();
    }
  }, [countdown, paymentStatus, onSuccess]);  

  useEffect(() => {
    if (paymentStatus === 'success') {
      const intervalId = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
  
      return () => clearInterval(intervalId);
    }
  }, [paymentStatus]);

  useEffect(() => {
    initiatePayment(token);
  }, [token]);

  const initiatePayment = (token: string) => {
    const socket: Socket = io(process.env.REACT_APP_SWAPIER_SERVER_URL as string);

    socket.on(`payment-status-${token}`, (eventNotification: PaydunyaEventNotification) => {
      setLoading(false);
      switch (eventNotification.data.status) {
        case PaydunyaTransactionStatus.Completed:
          setPaymentStatus('success');
          break;
        case PaydunyaTransactionStatus.Failed:
          setPaymentStatus('failed'); 
          break;
        case PaydunyaTransactionStatus.Cancelled:
          setPaymentStatus('cancelled'); 
          break;
        default:
          console.error(`Hub2PaymentComponent type "${eventNotification.data.status}" not handled`);
          break;
      }
    });

    return () => {
      socket.disconnect();
    };
  }

  const handleRetryPayment = () => {
    setLoading(true);
    setPaymentStatus('pending');

    onRetry()
  }

  return (
    <div>
      <div className="mb-4 text-center">
        <label className="block font-medium">Numéro de téléphone</label>
        <div className="text-xl inline-flex font-bold">{phoneNumber}</div>
      </div>

      {loading && (
        <><MiniLoadingSpinner /></>
      )}

      {!loading && (
        <div className="mb-4 text-center font-medium text-lg">
          {paymentStatus === 'success' && (<div>
              <div className="w-12 h-12 rounded-full bg-green-500 flex mx-auto" aria-hidden="true">
                <svg className="fill-current text-white" viewBox="0 0 20 20">
                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                </svg>
              </div>
              Félicitations ! Votre paiement a été effectué avec succès.
              <div className='text-sm font-normal mt-2'>
                Cette fenêtre sera fermée dans {countdown} secondes...
              </div>
            </div>
          )}
          {(paymentStatus === 'failed' || paymentStatus === 'cancelled') && (<div>
              <div className="w-12 h-12 rounded-full bg-red-500 flex mx-auto" aria-hidden="true">
                <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              Nous sommes désolés, mais votre paiement n'a pas abouti.<br/>Veuillez réessayer.<br/>Si vous continuez à rencontrer des problèmes, notre équipe de support est là pour vous aider à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
              <button onClick={handleRetryPayment} className="bg-amber-500 my-4  hover:bg-amber-600 h-16 text-lg text-white px-4 py-2 rounded w-full">Réesayer</button>
              <MailtoLink email={'support@swapier.co'} title={'Contacter le support'} className="text-base font-medium text-amber-500 hover:text-amber-600"></MailtoLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PaydunyaPaymentNotificationComponent;
