import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { verify } from '../store/authentication/auth.actions';
import { useAppDispatch, useAppSelector } from '../store';
import { CODE_CONFIRMATION_LENGTH_REQUIRED, CODE_CONFIRMATION_TYPE_REQUIRED, FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import userService from '../services/user.service';
import authService, { UserRegistrationProgressStep } from '../services/auth.service';
import Logo from '../assets/images/logo.png';

interface FormValues {
  code: string | '' ;
}

const RegisterVerifyEmail = () => {
  const [successful, setSuccessful] = useState(false);

  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  const userRegistrationNextStep = useAppSelector(state => state.auth.userRegistrationNextStep);

  useEffect(() => {
    if (userRegistrationNextStep !== UserRegistrationProgressStep.EMAIL_TO_VERIFY) {  
      navigate('/login');
    }

  }, [navigate, userRegistrationNextStep]);

  // useEffect(() => {
  //   if (!userEmailToVerify && !successful) {
  //     navigate('/login');
  //   }
  // }, [navigate, successful, userEmailToVerify]);

  useEffect(() => {
    if (successful) {
      //dispatch(clearUserEmailToVerify());
    }
  }, [dispatch, successful]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues: FormValues = {
    code: '',
  };

  const handleSubmit = async (values: FormValues) => {
    const { code } = values;
    setSuccessful(false);
    setDisableSubmit(true);
    try {
      await dispatch(verify({ userId: user?.user.id, code })).unwrap();
      setSuccessful(true);
      setDisableSubmit(false);
    } catch (err) {
      setSuccessful(false);
      setDisableSubmit(false);
    }
  };

  const handleResendCodeClick = async (e: any) => {
    e.preventDefault();
    try {
      authService.registerResendEmailConfirmationCode(user?.user.id);
      dispatch(clearMessage());
    } catch (err) {
    }
  };

  const validationSchema = Yup.object({
    code: Yup.string().required(FIELD_REQUIRED).length(6, CODE_CONFIRMATION_LENGTH_REQUIRED).matches(/^[0-9]+$/, CODE_CONFIRMATION_TYPE_REQUIRED),
  });

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            {!successful && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6">Votre code de confirmation vous attend dans votre boite e-mail</h1>
                <div className="text-base mb-6">
                  Saisissez le code à 6 chiffres que nous vous avons envoyé sur <span className="font-semibold">{user?.user.email}</span> pour confirmer votre adresse e-mail.
                </div>
              {/* Form */}

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="space-y-4">
                      <div>
                        <label className="block text-base font-medium mb-1" htmlFor="email">Code de confirmation</label>
                        <Field name="code" className="form-input w-full h-16 text-base" type="code" placeholder="XXX XXX"/>
                        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="code"/></div>
                      </div>
                      
                      {message && (
                        <div className="text-center mt-4">
                          <div className="text-base mt-1 text-rose-500">{ message }</div>
                        </div>
                      )}

                      <div className="text-sm">
                        Votre code est peut-être dans vos spams.
                        <br/>Toujours rien ?  <button className="font-medium text-amber-500 hover:text-amber-600" onClick={handleResendCodeClick}> Envoyer un nouveau code</button>
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" disabled={disableSubmit} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Confirmer</button>
                      </div>
                    </div>
                  </Form>
                </Formik>

              </div>
            )}  

            {successful && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Votre adresse email a été vérifé !</h1>
                <div className="text-lg text-center">
                  Nous allons maintenant apprendre à mieux se connaître. 
                </div>
                <div className="text-center mt-4">
                  <Link to={'/register/profile/step/1'}>
                    <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Continuez</button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default RegisterVerifyEmail;