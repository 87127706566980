import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import authService, { AuthCredentials, KycJobResponse, LoginFailedResponse, RegisterProfileStepOne, RegisterProfileStepTwo, ResetPassword, SaveKycInfo, SendResetPasswordLink, VerifyUserAccountParams } from "../../services/auth.service";
import { LOGIN_FAILED_DEFAULT_MESSAGE, REGISTER_FAILED_DEFAULT_MESSAGE, REGISTER_PROFILE_DEFAULT_MESSAGE, RESET_PASSWORD_DEFAULT_MESSAGE, SAVE_KYC_INFO_DEFAULT_MESSAGE, SEND_RESET_PASSWORD_LINK_DEFAULT_MESSAGE, VERIFY_USER_ACCOUNT_FAILED_DEFAULT_MESSAGE } from "../../utils/constants/swapier-service-response-messages.constants";
import { setMessage } from "../message/message.slice";
import { ErrorMessageCode } from "../../error-message-code";

export interface RegisterProfileStepOneInput {
  userId?: string;
  profile: RegisterProfileStepOne;
}

export interface RegisterProfileStepTwoInput {
  userId?: string;
  profile: RegisterProfileStepTwo;
}

export interface SaveKycInfoInput {
  userId?: string;
  kycInfo: SaveKycInfo;
}

export interface SendKycImagesInput {
  userId?: string;
  kycImages: any;
}

export const signUp = createAsyncThunk(
  "auth/sign-up",
  async ({ email, password }: AuthCredentials, thunkAPI) => {
    try {
      const registerResponse = await authService.signUp({ email, password });

      return { user: registerResponse };
    } catch (error) {
      let message = REGISTER_FAILED_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }: AuthCredentials, thunkAPI) => {
    try { 
      const loginResponse = await authService.login({ email, password });

      return { loginResponse };
    } catch (error) {
      let message = LOGIN_FAILED_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        const messageCode = error?.response?.data?.messageCode;
        if ([ErrorMessageCode.USER_ACCOUNT_EMAIL_NOT_VERIFIED_CODE, ErrorMessageCode.USER_PROFILE_PART_ONE_NOT_COMPLETED_CODE, ErrorMessageCode.USER_PROFILE_PART_TWO_NOT_COMPLETED_CODE].includes(messageCode)) {
          return thunkAPI.rejectWithValue({
            user: error?.response?.data?.userLoginResponse,
            messageCode
          } as LoginFailedResponse);
        }
        
        message = error?.response?.data?.message;
      }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const verify = createAsyncThunk(
  "auth/verify-email",
  async ({ userId, code }: VerifyUserAccountParams, thunkAPI) => {
    try {
      await authService.registerVerifyEmail({ userId, code });
    } catch (error) {
      let message = VERIFY_USER_ACCOUNT_FAILED_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const registerProfileStepOne = createAsyncThunk(
  "auth/register/profile/step-one",
  async ({ userId, profile }: RegisterProfileStepOneInput, thunkAPI) => {
    const { gender, firstName, lastName, phoneNumber } = profile;
    try {
      const registerUserProfileResponse = await authService.registerProfileStepOne({ gender, firstName, lastName, phoneNumber }, userId);

      return { user: registerUserProfileResponse };
    } catch (error) {
      let message = REGISTER_PROFILE_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const registerProfileStepTwo = createAsyncThunk(
  "auth/register/profile/step-two",
  async ({ userId, profile }: RegisterProfileStepTwoInput, thunkAPI) => {
    const { birthdate, nationality, addressCountry } = profile;

    try {
      const registerUserProfileResponse = await authService.registerProfileStepTwo({ birthdate, nationality, addressCountry }, userId);

      return { user: registerUserProfileResponse };
    } catch (error) {
      let message = REGISTER_PROFILE_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const saveKycInfo = createAsyncThunk(
  "auth/kyc/info",
  async ({ userId, kycInfo }: SaveKycInfoInput, thunkAPI) => {
    const { idDocumentIssuingCountry, idDocumentNumber, idDocumentType } = kycInfo;
    try {
      await authService.saveKycInfo({ idDocumentIssuingCountry, idDocumentNumber, idDocumentType }, userId);
    } catch (error) {
      let message = SAVE_KYC_INFO_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const sendKycImages = createAsyncThunk(
  "auth/kyc/images",
  async ({ userId, kycImages }: SendKycImagesInput, thunkAPI) => {
    try {
      return await authService.sendKycImages(kycImages, userId);
    } catch (error) {
      let message = SAVE_KYC_INFO_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }

      thunkAPI.dispatch(setMessage(message));
  
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "auth/send-reset-password-link",
  async ({ email }: SendResetPasswordLink, thunkAPI) => {
    try {
      return await authService.sendResetPasswordLink(email);
    } catch (error) {
      let message = SEND_RESET_PASSWORD_LINK_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async ({ password, token }: ResetPassword, thunkAPI) => {
    try {
      return await authService.resetPassword({password, token});
    } catch (error) {
      let message = RESET_PASSWORD_DEFAULT_MESSAGE;
      if (error instanceof AxiosError && error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue(null);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  () => {
    authService.logout();
  }
);