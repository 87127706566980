import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import '../lib/smile-identity/smart-camera-web-1.0.0/smart-camera-web.js'
import { useAppSelector, useAppDispatch } from '../store';
import { sendKycImages } from '../store/authentication/auth.actions';
import LoadingBackground from '../components/LoadingBackground';
import LoadingSpinner from '../components/LoadingSpinner';
import { ResultCategory, kycErrorCodes } from '../kyc-result-code';
import Logo from '../assets/images/logo.png';
import MailtoButton from '../components/MailtoButton';
import userService from '../services/user.service';
import { setUserKycCompleted } from '../store/authentication/auth.slice';

interface ImagesComputedEvent extends Event {
  detail: any;
}

export enum KycResult {
  NOT_STARTED = 'NOT_STARTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_REVIEW = 'PENDING_REVIEW',
}

const KycProcessStepTwo = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [allowUserKycProcess, setAllowUserKycProcess] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [kycResult, setKycResult] = useState<KycResult>(KycResult.NOT_STARTED);
  const [kycRejectedReason, setKycRejectedReason] = useState<string>('');
  
  const user = useAppSelector(state => state.auth.user);

  const smartCameraRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (kycResult === KycResult.APPROVED) {
      dispatch(setUserKycCompleted());
    }
  }, [dispatch, kycResult]); 

  // TODO : fix bug on smart camera ref not well initialized when this code is uncommented
  // useEffect(() => {
  //   const userId = user?.user.id;
  //   if (!userId) {
  //     navigate(`/login`);
  //     return;
  //   };

  //   const getUserKycInfo = async (userId: string) => {
  //     setLoading(true);
  //     const userKycInfo = await userService.getUserKycInfo(userId);
  //     setAllowUserKycProcess(!userKycInfo || userKycInfo.verificationStatus === 'NOT_STARTED');
  //     setLoading(false);
  //   };

  //   getUserKycInfo(userId);
  // }, [navigate, user?.user.id]);

  useEffect(() => {
    if (!smartCameraRef.current) return;

    const SCW = document.createElement('smart-camera-web') as any;
    SCW.setAttribute('capture-id', 'back');
    smartCameraRef.current.appendChild(SCW);

    SCW.addEventListener('imagesComputed', async (e: ImagesComputedEvent) => {
      try {
        setLoading(true);
        const sendKycImagesResponse = await dispatch(sendKycImages({ userId: user?.user.id, kycImages: e.detail })).unwrap();
        
        const resultCode = sendKycImagesResponse.result.ResultCode;
        const result = kycErrorCodes[resultCode];

        if (result.category === ResultCategory.APPROVED || result.category === ResultCategory.REJECTED) {
          setKycResult( result.category === ResultCategory.APPROVED ? KycResult.APPROVED : KycResult.REJECTED);
          setKycRejectedReason(result.description)
        } else {
          setKycResult(KycResult.PENDING_REVIEW);
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw e;
      }
    });

    return () => {
      if (smartCameraRef.current) {
        smartCameraRef.current.removeChild(SCW);
      }
    };
  }, [dispatch, user?.user.id]);

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

       
            <div className="max-w-sm mx-auto px-2 py-6">
              {loading && (
                <><LoadingBackground /><LoadingSpinner /></>
              )}
              {kycResult === KycResult.APPROVED && (
                <div className="max-w-sm mx-auto px-2 py-6">
                  <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Félicitations, vos informations personnelles ont été validées !</h1>
                  <div className="text-lg text-center">
                    Vous pouvez commencer à investir dès maintenant
                  </div>
                  <div className="text-center mt-4">
                    <Link to={'/exchange'}>
                      <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Investir dès maintenant</button>
                    </Link>
                  </div>
                </div>
              )}
              {kycResult === KycResult.REJECTED && (
                <div className="max-w-sm mx-auto px-2 py-6">
                  <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Nous sommes désolés, vos informations personnelles n'ont pas été validées</h1>
                  <div className="text-lg text-center pt-4">
                    Afin que nous puissions procéder à une vérification manuelle de vos informations personnelles, merci de contacter notre service de support à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
                  </div>
                  <div className="text-center mt-4">
                    <MailtoButton email={'support@swapier.co'} title={'Contacter le support'} className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center"></MailtoButton>
                  </div>
                  <div className="text-center mt-4">
                    <Link className="text-base font-medium text-amber-500 hover:text-amber-600" to="/">Revenir à l'accueil</Link>
                  </div>
                </div>
              )}
              {kycResult === KycResult.PENDING_REVIEW && (
                <div className="max-w-sm mx-auto px-2 py-6">
                  <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Nous avons besoin de quelques minutes supplémentaires pour vérifier vos informations personnelles.</h1>
                  <div className="text-lg text-center">
                    Dès que nous aurons terminé la vérification, vous serez informer par mail.
                    Cela prend généralement moins de 10 minutes.
                  </div>
                  <div className="text-center mt-4">
                    <Link to={'/'}>
                      <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Revenir à l'acceuil</button>
                    </Link>
                  </div>
                </div>
                
              )}
              {!loading && kycResult === KycResult.NOT_STARTED && (
                <>
                  <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Prenez un selfie et photographiez votre document d'identité</h1>
                  <div ref={smartCameraRef}></div>
                </>
              )}

            {/* {!loading && !allowUserKycProcess && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Nous avons déjà procéder au traitement automatique de vos informations personnelles</h1>
                <div className="text-lg text-center pt-4">
                  Si vous souhaitez qu'on valide manuellement vos informations personnelles ou pour question, merci de contacter notre service de support à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
                </div>
                <div className="text-center mt-4">
                  <MailtoButton email={'support@swapier.co'} title={'Contacter le support'} className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center"></MailtoButton>
                </div>
                <div className="text-center mt-3">
                  <Link className="text-base font-medium text-amber-500 hover:text-amber-600" to="/">Revenir à l'accueil</Link>
                </div>
              </div>
            )} */}
           
            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default KycProcessStepTwo;