import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { signUp } from '../store/authentication/auth.actions';
import { useAppDispatch, useAppSelector } from '../store';
import { EMAIL_INVALID, FIELD_REQUIRED, INVALID_PASSWORD_FORMAT, PASSWORD_FORMAT_REGEX, PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH_REQUIRED, PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import Logo from '../assets/images/logo.png';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface FormValues {
  email: string;
  password: string;
}

const SignUp = () => {
  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const navigate = useNavigate();

  //const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };
  
  const handleSubmit = async (values: FormValues) => {
    const { email, password } = values;
    setDisableSubmit(true);
    try {
      await dispatch(signUp({ email, password})).unwrap();
      navigate('/sign-up/verify-email');
      setDisableSubmit(false);
    } catch (err) {
      setDisableSubmit(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(EMAIL_INVALID).required(FIELD_REQUIRED),
    password: Yup.string().required(FIELD_REQUIRED)
    .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED)
    .max(PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH_REQUIRED)
    .matches(PASSWORD_FORMAT_REGEX, INVALID_PASSWORD_FORMAT),
  });

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            {(
              <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">Commencez votre voyage dans le monde des cryptomonnaies !<br/>Rejoingnez-nous maintenant.</h1>
              {/* Form */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="space-y-8">
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="email">Adresse e-mail</label>
                      <Field name="email" className="form-input w-full h-16 text-base" type="email" placeholder="Saisissez votre adresse e-mail"/>
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="email"/></div>
                    </div>
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="password">Mot de passe</label>
                      <Field name="password" type="password" className="form-input w-full h-16 text-base" placeholder="Saisissez un mot de passe" autoComplete="on" />
                      {/* <button type="button" onClick={togglePasswordVisibility} className="absolute left-[550px] inset-y-0 pr-3 flex items-center text-sm leading-5">
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                      </button> */}
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="password"/></div>
                      <div className="text-xs">Veuillez entrer un mot de passe comportant au moins <span className="text-amber-500">6 caractères</span>, comprenant au moins <span className="text-amber-500">un chiffre</span>, <span className="text-amber-500">une lettre minuscule</span> et <span className="text-amber-500">une lettre majuscule</span>.</div>
                    </div>
                  </div>
                  <div className="justify-center mt-6">
                    <div className="flex items-center">
                      <span className="text-sm ml-2">En vous inscrivant, vous acceptez <Link className="text-amber-500 underline" to="https://www.swapier.co/cgv" target="_blank">les conditions générales de Swapier</Link></span>
                    </div>
                    {message && (
                      <div className="text-center mt-4">
                        <div className="text-base mt-1 text-rose-500">{ message }</div>
                      </div>
                    )}

                    <div className="text-center mt-4">
                      <button type="submit" disabled={disableSubmit} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Créez un compte</button>
                    </div>
                  </div>
                </Form>
              </Formik>


              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-base text-center">
                  Vous avez déjà un compte? <Link className="text-lg font-medium text-amber-500 hover:text-amber-600" to="/login">Connectez-vous</Link>
                </div>
              </div>
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default SignUp;