import axios from "axios";
import authHeader from "../utils/helpers/auth-header";

const usersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/users`;

export interface ReceiptUserInfos {
  value: string;
  paymentMethodId: string;
}

export interface ValidateReceiptUserInfosResponse {
  isValid: boolean;
  error?: {
    message: string;
  };
}

export interface GeoLocationData {
  range: string;
  country: string;
  region: string;
  city: string;
  ll: [number, number];
  metro: number;
  area: number;
  eu: string;
  timezone: string;
}

const validateReceiptUserInfos = async ({ value, paymentMethodId }: ReceiptUserInfos): Promise<ValidateReceiptUserInfosResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const receiptInfosValidateReponse = await axios.post(`${usersApiUrl}/receipt-infos/validate`, { value, paymentMethodId }, { headers: authHeaderData });

  return receiptInfosValidateReponse.data;
};

const getUserKycInfo = async (userId: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const userKycInfo = await axios.get(`${usersApiUrl}/${userId}/kyc/info`, { headers: authHeaderData });

  return userKycInfo.data;
}

const getUser = async (userId: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const getUserResult = await axios.get(`${usersApiUrl}/${userId}`, { headers: authHeaderData });

  return getUserResult.data;
}

const getGeoInfo = async (): Promise<GeoLocationData | null> => {
  const getGeoInfoResult = await axios.get(`${usersApiUrl}/geo/info`);

  return getGeoInfoResult.data;
}

const userService = {
  validateReceiptUserInfos,
  getUserKycInfo,
  getUser,
  getGeoInfo
};

export default userService;