import axios from "axios";
import authHeader from "../utils/helpers/auth-header";

const paymentMethodsApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/payment-methods`;

interface PaymentType {
  code: string;
  name: string;
  category: string;
}

export enum PaymentTypeCategory {
  CryptoCurrency = 'CRYPTO_CURRENCY',
  MobileMoney = 'MOBILE_MONEY',
  DigitalWallet = 'DIGITAL_WALLET',
}

interface Country {
  name: string;
  isoCode2: string;
  isoCode3: string;
}

export interface PaymentMethod {
  id: string;
  currency: string;
  currencySign: string;
  currencyCode: string;
  cryptoTokenStandard: string;
  paymentType: PaymentType;
  country: Country;
  convertThroughUsd: boolean;
  multipleOf: number;
}

export class AmountConfigurationResponse {
  min?: number;
  max?: number;
}

export class FeeConfigurationResponse {
  amount?: number;
  type?: string;
}

export interface PaymentMethodTradingPairConfiguration {
  id: string;
  fee?: FeeConfigurationResponse;
  sendAmount?: AmountConfigurationResponse;
  receiveAmount?: AmountConfigurationResponse;
}

export interface PaymentMethodTradingPair {
  id: string;
  sendPaymentMethodId: string;
  receivePaymentMethodId: string;
  enabled: boolean;
  configuration?: PaymentMethodTradingPairConfiguration;
}

export interface GetPaymentMethodFilters {
  sendPaymentMethodSelectedId?: string;
  receivePaymentMethodSelectedId?: string;
  countryCodeId?: string;
}

const getPaymentMethods = async (getPaymentMethodFilters?: GetPaymentMethodFilters): Promise<PaymentMethod[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const paymentMethods = await axios.get(`${paymentMethodsApiUrl}`, { headers: authHeaderData, params: getPaymentMethodFilters  });

  return paymentMethods.data;
}

const getPaymentMethodTradingPairs = async (getPaymentMethodFilters?: GetPaymentMethodFilters): Promise<PaymentMethod[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const paymentMethodTradingPairs = await axios.get(`${paymentMethodsApiUrl}/trade/pairs`, { headers: authHeaderData, params: getPaymentMethodFilters  });

  return paymentMethodTradingPairs.data;
}

const paymentMethodService = {
  getPaymentMethods,
  getPaymentMethodTradingPairs,
};

export default paymentMethodService;