import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../store/authentication/auth.actions';
import { useAppDispatch, useAppSelector } from '../store';
import { FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import authService, { UserRegistrationProgressStep } from '../services/auth.service';
import { clearMessage } from '../store/message/message.slice';
import Logo from '../assets/images/logo.png';
interface FormValues {
  email: string;
  password: string;
}

const Login = (props: any) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);
  const user = useAppSelector(state => state.auth.user);
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const isKycCompleted = user?.user.isKycCompleted;
  const userRegistrationNextStep = useAppSelector(state => state.auth.userRegistrationNextStep);

  useEffect(() => {
    if (userRegistrationNextStep === UserRegistrationProgressStep.EMAIL_TO_VERIFY) {  
      authService.registerResendEmailConfirmationCode(user?.user.id);
      navigate('/sign-up/verify-email');
    }

    if (userRegistrationNextStep === UserRegistrationProgressStep.PROFILE_PART_ONE_PROFILE_TO_COMPLETE) { 
      navigate('/register/profile/step/1'); 
    }

    if (userRegistrationNextStep === UserRegistrationProgressStep.PROFILE_PART_TWO_PROFILE_TO_COMPLETE) {  
      navigate('/register/profile/step/2');
    }

  }, [navigate, user?.user.id, userRegistrationNextStep]);

  const initialValues: FormValues = {
    email: '',
    password: '',
  };

  //let from = location?.state?.from?.pathname || "/exchange";

  const handleSubmit = async (values: FormValues) => {
    const { email, password } = values;
    setLoading(true);
    try {
      await dispatch(login({ email, password})).unwrap();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().required(FIELD_REQUIRED),
    password: Yup.string().required(FIELD_REQUIRED)
  });

  useEffect(() => {
    if (isLoggedIn) {
      if (!isKycCompleted) {
        navigate('/account/verification', { replace: true });
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [isKycCompleted, isLoggedIn, navigate]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  
  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-2 py-6">
            <h1 className="text-3xl text-slate-800 font-bold mb-6">Nous sommes heureux de vous revoir !<br/>Connectez-vous pour continuer.</h1>
            {/* Form */}

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="space-y-8">
                  <div>
                    <label className="block text-base font-medium mb-1" htmlFor="email">Adresse e-mail</label>
                    <Field name="email" className="form-input w-full h-16 text-base" type="email" placeholder="Saisissez votre adresse e-mail"/>
                    <div className="text-base mt-1 text-rose-500"><ErrorMessage name="email"/></div>
                  </div>
                  <div>
                    <label className="block text-base font-medium mb-1" htmlFor="password">Mot de passe</label>
                    <Field name="password" className="form-input w-full h-16 text-base" type="password" placeholder="Saisissez votre de passe" autoComplete="on" />
                    <div className="text-base mt-1 text-rose-500"><ErrorMessage name="password"/></div>
                  </div>
                </div>
                <div className="mt-6">
                  <div className="text-right">
                    <span className="text-sm ml-2 text-amber-500 hover:text-amber-600"><Link className="" to="/forgot-password">Mot de passe oublié ?</Link></span>
                  </div>
                  {message && (
                    <div className="text-center mt-4">
                      <div className="text-base mt-1 text-rose-500">{ message }</div>
                    </div>
                  )}

                  <div className="text-center mt-4">
                    <button type="submit" disabled={loading} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Connectez-vous</button>
                  </div>
                </div>
              </Form>
            </Formik>

            {/* Footer */}
            <div className="pt-5 mt-6 border-t border-slate-200">
              <div className="text-base text-center">
                Vous n'avez pas encore de compte? <Link className="text-lg font-medium text-amber-500 hover:text-amber-600" to="/sign-up">Créer un compte</Link>
              </div>
            </div>
            </div>
          

          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default Login;