import { useEffect, useState } from 'react';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridPaginationModel, GridRowParams } from '@mui/x-data-grid';
import { ExchangeResponse, ExchangeState, GetExchangesParams, getExchanges } from '../services/exchange.service';
import { AxiosError } from 'axios';
import { useAppSelector } from '../store';
import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const TransactionHistory = (props: any) => {
  const ITEMS_PER_PAGE_DEFAULT = 10;

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [exchangesTableData, setExchangesTableData] = useState<ExchangeResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  const user = useAppSelector(state => state.auth.user);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchExchanges = async () => {
      setLoading(true);
      try {
        const getExchangesParams: GetExchangesParams = {
          filter: {
              userId: user?.user.id,
          },
        };

        const getExchangesResponse = await getExchanges(getExchangesParams);
        setExchangesTableData(getExchangesResponse);
        setTotalItems(getExchangesResponse.length);
        setLoading(false);
      } catch (error) {
        let errorMsg = 'Une erreur est survenue lors de la récupération de la liste des transactions.'
        if (error instanceof AxiosError && error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
          errorMsg = error?.response?.data?.message;
        }
        setErrorMessage(errorMsg);
        setLoading(false);
      }
    };

    fetchExchanges();
  }, [user?.user.id]);

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setCurrentPage(newModel.page);
  };

  const handleRowClick = (param: GridRowParams, event: React.MouseEvent) => {
    navigate(`/exchange/tracking/${param.row.id}`);
  };

  const getColorByState = (state: ExchangeState) => {
    switch (state) {
      case ExchangeState.Pending:
        return "amber";
      case ExchangeState.PartiallyCompleted:
        return "blue";
      case ExchangeState.Completed:
        return "green";
      case ExchangeState.Canceled:
        return "gray";
      case ExchangeState.Failed:
        return "red";
      default:
        return"blue-gray";
    }
  };

  const translateStateInFrench = (state: ExchangeState) => {
    switch (state) {
      case ExchangeState.Pending:
        return "En attente de paiement";
      case ExchangeState.PartiallyCompleted:
        return "Partiellement complété";
      case ExchangeState.Completed:
        return "Complété";
      case ExchangeState.Canceled:
        return "Annulé";
      case ExchangeState.Failed:
        return "Echoué";
    }
  };

  const columns: GridColDef[] = [
    {
      field: "reference",
      headerName: "N° de transaction",
      flex: 1,
      sortable: false,
    },
    {
      field: "state",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.state}`,
      renderCell: (params) => {
        return <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 bg-${getColorByState(params.value)}-100 text-${getColorByState(params.value)}-600`}>{translateStateInFrench(params.value)}</div>
      },
    },
    {
      field: "amountToSend",
      headerName: "Montant à envoyer",
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
      `${params.row.sendPaymentOrder.amount} ${params.row.sendPaymentOrder.currency}`,
    },
    {
      field: "sendPaymentMethod",
      headerName: "Méthode d'envoi",
      flex: 1,
      valueGetter: (params) =>
      `${params.row.sendPaymentOrder.paymentMethod.paymentType.name}`,
    },
    {
      field: "amountToReceive",
      headerName: "Montant à recevoir",
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
      `${params.row.receivePaymentOrder.realAmount ?? params.row.receivePaymentOrder.amount} ${params.row.receivePaymentOrder.currency}`,
    },
    {
      field: "receivePaymentMethod",
      headerName: "Méthode de réception",
      flex: 1,
      valueGetter: (params) =>
      `${params.row.receivePaymentOrder.paymentMethod.paymentType.name}`,
    },
    {
      field: "createdAt",
      headerName: "Date d'opération",
      flex: 1,
      valueFormatter: (params) => moment(params?.value).format("lll"),
    },
  ];
  
  const columnsSmallScreen: GridColDef[] = [
    {
      field: "info",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        const exchange = params.row as ExchangeResponse;
        return <div className="grid grid-rows-3 my-1">
          <div className='text-base font-semibold'>N° <span></span>{exchange.reference}</div>
          <div>{moment(exchange?.createdAt).format("lll")}</div>
          <div className={`font-medium text-${getColorByState(exchange.state)}-600`}>
            {translateStateInFrench(exchange.state)}
          </div>
        </div>
      },
    },
    {
      field: "createdAt",
      headerName: "",
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-8 py-8 w-full mx-auto">
            <div className="bg-white shadow-lg rounded-sm">
              <header className="px-5 py-4">
                <h2 className="text-2xl font-semibold text-amber-500 text-center">Transactions</h2>
              </header>
              {errorMessage && (
                <div className="text-base text-center text-red-500">
                  {errorMessage}
                </div>
              )}
              <div className="lg:hidden">
                <DataGrid
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                      },
                    }}
                    disableColumnMenu={true} 
                    columnVisibilityModel={{
                      createdAt: false,
                    }}
                    rows={exchangesTableData} 
                    columns={columnsSmallScreen} 
                    sortingOrder={['desc', 'asc']}
                    autoHeight={true}
                    paginationModel={{ page: currentPage, pageSize: ITEMS_PER_PAGE_DEFAULT }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    rowCount={totalItems}
                    pagination
                    paginationMode="client"
                    onPaginationModelChange={handlePaginationModelChange}
                    loading={loading}
                    onRowClick={handleRowClick} 
                    density='comfortable'
                    localeText={{ noRowsLabel: "Il n'y a aucunes transactions" }}
                    sx={{
                      '.MuiDataGrid-cell:focus': {
                        outline: 'none'
                      },
                      '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer'
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        display: 'none'
                      },
                      '& .MuiDataGrid-root': {
                        borderLeft: 'none',
                        borderRight: 'none',
                      }
                    }}
                  />
              </div>
              <div className="hidden lg:block" >
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                  }}
                  disableColumnMenu={true} 
                  rows={exchangesTableData} 
                  columns={columns} 
                  sortingOrder={['desc', 'asc']}
                  autoHeight={true}
                  paginationModel={{ page: currentPage, pageSize: ITEMS_PER_PAGE_DEFAULT }}
                  pageSizeOptions={[10, 25, 50, 100]}
                  rowCount={totalItems}
                  pagination
                  paginationMode="client"
                  onPaginationModelChange={handlePaginationModelChange}
                  loading={loading}
                  onRowClick={handleRowClick} 
                  density='comfortable'
                  localeText={{ noRowsLabel: "Il n'y a aucunes transactions" }}
                  sx={{
                    '.MuiDataGrid-cell:focus': {
                      outline: 'none'
                    },
                    '& .MuiDataGrid-row:hover': {
                      cursor: 'pointer'
                    },
                    '& .MuiDataGrid-root': {
                      borderLeft: 'none',
                      borderRight: 'none',
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </main>

      </div>

    </div>
  );
}

export default TransactionHistory;