import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import { AmountDetailsResponse } from "./payment-order.service";

const exchangesApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/exchanges`;

// TODO: move to server into database. Dynamic link or attached to paymentType or method ?
export enum BlockChainBlockExplorerUrl {
  BLOCKCHAIR = 'https://blockchair.com/bitcoin',
  ETHERSCAN = 'https://etherscan.io',
  TRONSCAN = 'https://tronscan.org/#',
  BSCSCAN = 'https://bscscan.com',
}

export interface CreateExchange {
  amountDetails: AmountDetailsResponse | null;
  sendPaymentMethodId: string;
  receivePaymentMethodId: string;
  userReceiptInfo: string;
}

export enum ExchangeState {
  Pending = 'PENDING',
  PartiallyCompleted = 'PARTIALLY_COMPLETED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
}

export interface CreateExchangeResponse {
  id: string;
  state: ExchangeState;
  userId: string;
  sendPaymentOrderId: string;
  receivePaymentOrderId: string;
}

export enum PaymentOrderState {
  Pending = 'PENDING',
  PaymentProcessing = 'PAYMENT_PROCESSING',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
}

export enum FundsReceiptState {
  NotReceived = 'NOT_RECEIVED',
  Insufficient = 'INSUFFICIENT',
  Received = 'RECEIVED',
}

export enum PaymentSide {
  Send = 'SEND',
  Receive = 'RECEIVE',
}

export interface PaymentTypeExchangeResponse {
  category: string;
  code: string;
  name: string;
}

export interface CountryExchangeResponse {
  id: string;
  name: string;
  isoCode2: string;
  isoCode3: string;
}

export interface PaymentMethodExchangeResponse {
  id: string;
  currencyCode: string;
  currencySign?: string;
  cryptoTokenStandard?: string;
  paymentType: PaymentTypeExchangeResponse;
  country?: CountryExchangeResponse;
}

export interface PaymentOrderFeeExchangeResponse {
  amount: number;
  source: string;
}

export interface PaymentOrderExchangeResponse {
  id: string;
  amount: number;
  realAmount: number;
  currency: string;
  state: PaymentOrderState;
  fundsReceiptState: FundsReceiptState;
  side: PaymentSide;
  createdAt: Date;
  paidAt: Date;
  failedAt: Date;
  updatedAt: Date;
  receiveCurrencyToSendCurrencyRate: string;
  userReceiptInfo: string;
  paymentMethod: PaymentMethodExchangeResponse;
  paymentOrderFees: PaymentOrderFeeExchangeResponse[];
}

export interface UserExchangeResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ExchangeResponse {
  id: string;
  reference: string;
  state: ExchangeState;
  user: UserExchangeResponse;
  createdAt: Date;
  sendPaymentOrder: PaymentOrderExchangeResponse;
  receivePaymentOrder: PaymentOrderExchangeResponse;
}

export interface ExchangeFilter {
  userId?: string;
}

export interface GetExchangesParams {
  filter?: ExchangeFilter;
}

const translateStateInFrench = (state: ExchangeState) => {
  switch (state) {
    case ExchangeState.Pending:
      return "En attente de paiement";
    case ExchangeState.PartiallyCompleted:
      return "Partiellement complété";
    case ExchangeState.Completed:
      return "Complété";
    case ExchangeState.Canceled:
      return "Annulé";
    case ExchangeState.Failed:
      return "Echoué";
  }
};

const createExchange = async (amountDetails: AmountDetailsResponse | null, sendPaymentMethodId: string, receivePaymentMethodId: string, userReceiptInfo: string): Promise<CreateExchangeResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const createExchange: CreateExchange = {
    amountDetails,
    sendPaymentMethodId,
    receivePaymentMethodId,
    userReceiptInfo,
  };

  const exchange = await axios.post(`${exchangesApiUrl}`, createExchange, { headers: authHeaderData });

  return exchange.data;
}

const getExchange = async (exchangeId: string): Promise<ExchangeResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.get(`${exchangesApiUrl}/${exchangeId}`, { headers: authHeaderData });

  return exchanges.data;
}

export const getExchanges = async (getExchangesParams?: GetExchangesParams): Promise<ExchangeResponse[]> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.get(`${exchangesApiUrl}`, { headers: authHeaderData, params: getExchangesParams });

  return exchanges.data;
}

export const updateExchangeWhenSendPaymentOrderSuccess = async (id: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.post(`${exchangesApiUrl}/${id}/payment-order/success`, null, { headers: authHeaderData });

  return exchanges.data;
}

const updatePaymentOrderWhenPaymentProcessing = async (id: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.post(`${exchangesApiUrl}/${id}/payment-order/payment-processing`, null, { headers: authHeaderData });

  return exchanges.data;
}

export const updateExchangeWhenSendPaymentOrderFailed = async (id: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const exchanges = await axios.post(`${exchangesApiUrl}/${id}/payment-order/failed`, null, { headers: authHeaderData });

  return exchanges.data;
}

const exchangeService = {
  createExchange,
  getExchange,
  getExchanges,
  translateStateInFrench,
  updateExchangeWhenSendPaymentOrderFailed,
  updatePaymentOrderWhenPaymentProcessing,
  updateExchangeWhenSendPaymentOrderSuccess
};

export default exchangeService;