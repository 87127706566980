import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../store';
import { EMAIL_INVALID, FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import authService from '../services/auth.service';
import { clearMessage } from '../store/message/message.slice';
import { sendResetPasswordLink } from '../store/authentication/auth.actions';
import Logo from '../assets/images/logo.png';
interface FormValues {
  email: string;
}

const ForgotPassword = (props: any) => {
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);


  const initialValues: FormValues = {
    email: '',
  };


  const handleSubmit = async (values: FormValues) => {
    const { email } = values;
    setSuccessful(false);
    setLoading(true)
    try {
      await dispatch(sendResetPasswordLink({ email })).unwrap();
      setSuccessful(true);
      setLoading(false);
    } catch (err) {
      setSuccessful(false);
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(EMAIL_INVALID).required(FIELD_REQUIRED),
  });


  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  
  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

           {!successful && ( 
            <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Réinitialisez votre mot de passe</h1>
              <div className="text-base mb-6">
                Pour réinitialisez votre mot de passe, saisissez l'adresse e-mail que vous utilisez habituellement pour vous connecter à Swapier.
              </div>
              {/* Form */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="space-y-8">
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="email">Adresse e-mail</label>
                      <Field name="email" className="form-input w-full h-16 text-base" type="email" placeholder="Votre adresse e-mail"/>
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="email"/></div>
                    </div>
                  </div>
                  <div className="mt-6">
                    {message && (
                      <div className="text-center mt-4">
                        <div className="text-base mt-1 text-rose-500">{ message }</div>
                      </div>
                    )}

                    <div className="text-center mt-4">
                      <button type="submit" disabled={loading} className="btn w-full text-h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Réinitialiser le mot de passe</button>
                    </div>
                  </div>
                </Form>
              </Formik>


            {/* Footer */}
            <div className="pt-5 mt-6 border-t border-slate-200">
              <div className="text-base text-center">
                Vous avez déjà un compte? <Link className="text-lg font-medium text-amber-500 hover:text-amber-600" to="/login">Connectez-vous</Link>
              </div>
            </div>
            </div>
           )}

           {successful && (
            <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Rendez-vous dans votre boîte e-mail !</h1>
              <div className="text-lg text-center">
                Nous vous avons envoyé un lien pour choisir un nouveau mot de passe.
              </div>
              <div className="text-center mt-4">
                <Link to={'/login'}>
                  <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Connectez-vous</button>
                </Link>
              </div>
            </div>
          )}
          

          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default ForgotPassword;