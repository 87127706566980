import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { useAppSelector } from '../store';
import Logo from '../assets/images/logo.png';

const AccountVerification = () => {
  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  const userRegistrationNextStep = useAppSelector(state => state.auth.userRegistrationNextStep);

  useEffect(() => {
    // check that user can access this page
  }, [navigate, user?.user.id, userRegistrationNextStep]);

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-3xl text-slate-800 font-bold mb-6">Vérifions automatiquement votre identité en moins de 2 minutes</h1>
              <div className="text-base mb-6">
                Afin de garantir la sécurité et la conformité de votre compte, nous devons valider vos informations personnelles.
              </div>
              <div className="text-base mb-6">
                Veuillez vous munir de l'original de votre document d'identité et veillez à être dans un lieu bien éclairé lors de la procédure.
              </div>
              <div className="text-center mt-4">
                <Link to={'/kyc/process/step/1'}>
                  <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Commencez la vérification</button>
                </Link>
              </div>
              <div className="text-center mt-3">
                <Link className="text-base font-medium text-amber-500 hover:text-amber-600" to="/">Revenir à l'accueil</Link>
              </div>

            </div>

          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default AccountVerification;