import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, FieldAttributes, Form, Formik, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../store';
import { FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countries, { getName, getNames } from "i18n-iso-countries";
import fr from 'date-fns/locale/fr';
import EmojiFlags from "emoji-flags";
import Select, { OnChangeValue } from 'react-select';
import { prioritizedCountryCodes } from '../utils/config/country-code.config';
import { CountrySelectProps } from '../types/country-select.types';
import { documentTypeNames, documentTypesByCountry } from '../utils/config/id-document.config';
import { SelectOption } from '../types/select.types';
import { saveKycInfo } from '../store/authentication/auth.actions';
import Logo from '../assets/images/logo.png';
import userService, { GeoLocationData } from '../services/user.service';
import MailtoButton from '../components/MailtoButton';
import LoadingBackground from '../components/LoadingBackground';
import LoadingSpinner from '../components/LoadingSpinner';
import { KycResult } from './KycProcessStepTwo';

registerLocale('fr', fr);
countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));

interface FormValues {
  idDocumentIssuingCountry: string;
  availableDocumentTypes: {
    value: string;
    label: string;
  }[];
  idDocumentType: string;
  idDocumentNumber: string;
}

const KycProcessStepOne = () => {
  const dispatch = useAppDispatch();
  const [allowUserKycProcess, setAllowUserKycProcess] = useState(true);
  const [showMessageDocumentIDEmpty, setShowMessageDocumentIDEmpty] = useState(false);
  const [geoInfo, setGeoInfo] = useState<GeoLocationData | null>(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const { message } = useAppSelector(state => state.message);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    const fetchGeoInfo = async () => {
      const geoInfo = await userService.getGeoInfo();
      setGeoInfo(geoInfo);
    }

    fetchGeoInfo();
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // TODO: do it when user is connected / display popup dès qu'il est connecté
  useEffect(() => {
    // if (userRegistrationNextStep !== UserRegistrationProgressStep.PROFILE_PART_TWO_PROFILE_TO_COMPLETE) {  
    //   navigate('/login');
    // }

  }, [navigate, user?.user.id]);

  useEffect(() => {
    const userId = user?.user.id;
    if (!userId) {
      navigate(`/login`);
      return;
    };

    const getUserKycInfo = async (userId: string) => {
      setLoading(true);
      const userKycInfo = await userService.getUserKycInfo(userId);
      setAllowUserKycProcess(!userKycInfo || userKycInfo.verificationStatus === KycResult.NOT_STARTED);
      setLoading(false);
    };

    getUserKycInfo(userId);
  }, [navigate, user?.user.id]);

  const initialValues: FormValues = {
    idDocumentIssuingCountry: '',
    availableDocumentTypes: [],
    idDocumentType: '',
    idDocumentNumber: '',
  };

  const CountrySelect: React.FC<CountrySelectProps> = ({ field, form, options, placeholder }) => {
    const handleChange = (selectedOption: OnChangeValue<SelectOption, false>) => {
      if (selectedOption && 'value' in selectedOption) {
        form.setFieldValue(field.name, selectedOption.value);
      }
    };

    const selectedOption = options.find((option) => option.value === field.value);

    return (
      <Select
        {...field}
        placeholder={placeholder}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isSearchable
        classNames={{
          control: (state) =>
            state.isFocused ? '!border-amber-300 !shadow-none form-input w-full h-16 text-base' : '!border-gray-200 form-input w-full h-16 text-base',
          placeholder: () => '!text-gray-400',
        }}
      />
    );
  };

  const DocumentTypeField = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const [field] = useField(props);
    const { placeholder } = props;
    const issuingCountryFilled = values.idDocumentIssuingCountry !== '';

    const handleChange = (selectedOption: OnChangeValue<SelectOption, false>) => {
      if (selectedOption && 'value' in selectedOption) {
        setFieldValue(field.name, selectedOption.value);
      }
    };
  
    const selectedOption = values.availableDocumentTypes.find((option) => option.value === field.value);
  
    useEffect(() => {
      if (issuingCountryFilled) {
        if (values.idDocumentIssuingCountry in documentTypesByCountry) {
          const availableDocumentTypes = documentTypesByCountry[values.idDocumentIssuingCountry].map((idDocumentType) => ({
              value: idDocumentType,
              label: documentTypeNames[idDocumentType],
          }));
          setFieldValue('availableDocumentTypes', availableDocumentTypes);
          setShowMessageDocumentIDEmpty(false);
        } else {
          setFieldValue('availableDocumentTypes', []);
          setFieldValue('idDocumentType', '');
          setShowMessageDocumentIDEmpty(true);
        }
      }
      
    }, [issuingCountryFilled, setFieldValue, values.idDocumentIssuingCountry]);

    return (
      <>
        <label className="block text-base font-medium mb-1" htmlFor="idDocumentType">Choix de votre document d'identité</label>
        <Select
          isDisabled={!issuingCountryFilled}
          {...field}
          placeholder={placeholder}
          value={selectedOption}
          onChange={handleChange}
          options={values.availableDocumentTypes}
          noOptionsMessage={() => 'Aucun document disponible'}
          isSearchable={false}
          classNames={{
            control: (state) =>
              state.isFocused ? '!border-amber-300 !shadow-none form-input w-full h-16 text-base' : '!border-gray-200 form-input w-full h-16 text-base',
            placeholder: () => '!text-gray-400',
          }}
        />
        {showMessageDocumentIDEmpty && (
          <div className="text-center mt-4">
            <div className="text-base mt-1 text-rose-500">
              Nous vous prions de nous excuser. Pour le pays de délivrance indiqué, nous ne sommes pas en mesure de vérifier automatiquement votre document d'identité. Pour une vérification manuelle, nous vous invitons à contacter notre service de support à l'adresse suivante : support@swapier.co. Merci de votre compréhension et coopération.
            </div>
          </div>
        )}
        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="idDocumentType"/></div>
      </>
    );
  };

  const DocumentNumber = (props: FieldAttributes<any>) => {
    const { values } = useFormikContext<FormValues>();
    const issuingCountryFilled = values.idDocumentIssuingCountry !== '';
    const documentTypeFilled = values.idDocumentType !== '';
    const isDisabled = !issuingCountryFilled || !documentTypeFilled;

    return (
      <>
        <label className="block text-base font-medium mb-1" htmlFor="idDocumentNumber">Numéro du document</label>
        <Field 
          name="idDocumentNumber" type="text"
          className={`form-input w-full h-16 text-base ${isDisabled ? 'bg-gray-100 text-gray-400' : ''}`}  
          disabled={isDisabled}
          placeholder="Saisissez le numéro du document"
        />
        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="idDocumentNumber"/></div>
      </>
    );
  };

  const handleSubmit = async (values: FormValues) => {
    const { idDocumentIssuingCountry, idDocumentNumber, idDocumentType } = values;
  
    const userId = user?.user.id;

    try {
      await dispatch(saveKycInfo({ userId, kycInfo: { idDocumentIssuingCountry, idDocumentNumber, idDocumentType } })).unwrap();
      navigate('/kyc/process/step/2');
    } catch (err) {
    }
  };

  const validationSchema = Yup.object({
    idDocumentIssuingCountry: Yup.string().required(FIELD_REQUIRED),
    idDocumentType: Yup.string().required(FIELD_REQUIRED),
    idDocumentNumber: Yup.string().required(FIELD_REQUIRED),
  });

  const issuingCountryOptions = Object.entries(getNames("fr", { select: "official" })).map(([code, name]) => ({
    value: code,
    label: `${EmojiFlags.countryCode(code).emoji} ${name}`,
  } as SelectOption));

  const prioritizedIssuingCountries = prioritizedCountryCodes.map((code) => ({
    value: code,
    label: `${EmojiFlags.countryCode(code).emoji} ${getName(code, "fr", { select: "official" })}`,
  } as SelectOption));

  const otherIssuingCountries = issuingCountryOptions.filter(
    (option) => !prioritizedCountryCodes.includes(option.value)
  );

  const sortedIssuingCountryOptions = [
    ...prioritizedIssuingCountries,
    ...otherIssuingCountries,
  ];

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            {loading && (
              <><LoadingBackground /><LoadingSpinner /></>
            )}

            {!loading && allowUserKycProcess && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Renseignez les informations sur votre document d'identité</h1>
                {/* Form */}

                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="space-y-8">
                      <div>
                        <label className="block text-base font-medium mb-1" htmlFor="idDocumentIssuingCountry">Pays de délivrance</label>
                        <Field
                          name="idDocumentIssuingCountry"
                          placeholder="Sélectionnez le pays de délivrance"
                          options={sortedIssuingCountryOptions}
                          component={CountrySelect}
                          className="form-input w-full h-16 text-base"
                        />
                        <div className="text-base mt-1 text-rose-500"><ErrorMessage name="idDocumentIssuingCountry"/></div>
                      </div>

                      <div>
                        <DocumentTypeField 
                          name="idDocumentType"
                          placeholder="Sélectionnez votre document d'identité"
                          className="form-input w-full h-16 text-base"
                        />
                      </div>
                      <div>
                        <DocumentNumber
                          name="idDocumentNumber"
                          className="form-input w-full h-16 text-base"
                        />
                      </div>
                    
                    </div>
                    <div className="justify-center mt-6">
                      {message && (
                        <div className="text-center mt-4">
                          <div className="text-base mt-1 text-rose-500">{ message }</div>
                        </div>
                      )}

                      <div className="text-center mt-4">
                        <button type="submit" disabled={disableSubmit} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Valider</button>
                      </div>

                    </div>
                  </Form>
                </Formik>

                <div className="text-sm text-center pt-4">
                  Si votre pays de délivrance ou un document d'identité n'apparaît pas dans la liste, ou pour toute autre interrogation, veuillez contacter notre service de support à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
                </div>
              </div>
            )}

            {!loading && !allowUserKycProcess && (
              <div className="max-w-sm mx-auto px-2 py-6">
                <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Nous avons déjà procéder au traitement automatique de vos informations personnelles</h1>
                <div className="text-lg text-center pt-4">
                  Si vous souhaitez qu'on valide manuellement vos informations personnelles ou pour toute question, merci de contacter notre service de support à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
                </div>
                <div className="text-center mt-4">
                  <MailtoButton email={'support@swapier.co'} title={'Contacter le support'} className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center"></MailtoButton>
                </div>
                <div className="text-center mt-3">
                  <Link className="text-base font-medium text-amber-500 hover:text-amber-600" to="/">Revenir à l'accueil</Link>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default KycProcessStepOne;