export enum PaydunyaTransactionStatus {
  Completed = 'completed',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

interface InvoiceData {
  token: string;
  total_amount: number;
  description: string;
}

interface ActionsData {
  cancel_url: string;
  callback_url: string;
  return_url: string;
}

interface CustomerData {
  name: string;
  phone: string;
  email: string;
}

interface PaydunyaEventNotificationData {
  response_code: string;
  response_text: string;
  hash: string;
  invoice: InvoiceData;
  actions: ActionsData;
  mode: string;
  status: PaydunyaTransactionStatus;
  customer: CustomerData;
  receipt_url: string;
}

export interface PaydunyaEventNotification {
  data: PaydunyaEventNotificationData;
}