const MiniLoadingSpinner = () => {
  return (
    <div className="inset-0 flex justify-center items-center z-40">
        <div className="flex items-center justify-center">
          <div className="w-10 h-10 border-l-4 border-amber-500 rounded-full animate-spin"></div>
        </div>
    </div>
);
}

export default MiniLoadingSpinner;
