import { PaymentMethodExchangeResponse } from "../../services/exchange.service";

export const getPaymentMethodBlockchainUri = (paymentMethod: PaymentMethodExchangeResponse): string => {
  switch (paymentMethod.paymentType.code) {
    case 'BITCOIN':
      return 'bitcoin';
    case 'ETHEREUM':
      return 'ethereum';
    default:
      console.warn(`Payment method blockchain uri not handled for ${paymentMethod.paymentType.code}`);
      return ''
    case 'USDT':
      switch (paymentMethod.cryptoTokenStandard) {
        case 'ERC20':
          return 'ethereum';
        case 'TRC20':
          return 'tron';
        default:
          console.warn(`Payment method blockchain uri not handled for ${paymentMethod.paymentType.code}`);
          return ''
      }
  }

}