const LoadingSpinner = () => {
  return (
    <div className="absolute inset-0 flex justify-center items-center z-40">
        <div className="flex items-center justify-center">
          <div className="w-24 h-24 border-l-4 border-amber-500 rounded-full animate-spin"></div>
        </div>
    </div>
);
}

export default LoadingSpinner;
