export enum Hub2Event {
  PaymentSucceeded = 'payment.succeeded',
  PaymentActionRequired = 'payment.action_required',
  PaymentFailed = 'payment.failed',
  PaymentIntentSucceeded = 'payment_intent.succeeded',
  PaymentIntentActionRequired = 'payment_intent.action_required',
  PaymentIntentPaymentFailed = 'payment_intent.payment_failed',
  TransferSucceeded = 'transfer.succeeded',
  TransferFailed = 'transfer.failed',
}

export enum NextActionType {
  Otp = 'otp',
  Ussd = 'ussd',
  Redirection = 'redirection',
}

export enum Hub2EventNotificationStatusData {
  Successful = 'successful',
  Failed = 'failed',
  Pending = 'pending',
}

interface NextActionTypeData {
  url: string;
  method: string;
  headers: Record<string, any>;
  data: Record<string, any>;
}

export interface NextAction {
  type: NextActionType;
  message: string;
  data: NextActionTypeData;
}

interface Data {
  id: string;
  intentId: string;
  status: Hub2EventNotificationStatusData;
  nextAction: NextAction;
}

export interface Hub2EventNotification {
  type: Hub2Event;
  data: Data;
  createdAt: Date;
}