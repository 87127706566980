import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../store';
import { FIELD_REQUIRED, PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH_REQUIRED, PHONE_NUMBER_MIN_LENGTH, PHONE_NUMBER_MIN_LENGTH_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import { Gender, GenderLabels, PhoneNumber, UserRegistrationProgressStep } from '../services/auth.service';
import 'react-phone-input-2/lib/style.css';
import PhoneNumberField from '../components/forms/PhoneNumberField';
import { registerProfileStepOne } from '../store/authentication/auth.actions';
import Logo from '../assets/images/logo.png';
import userService, { GeoLocationData } from '../services/user.service';

interface FormValues {
  gender: Gender | '',
  firstName: string;
  lastName: string;
  phoneNumberValue: string;
  phoneNumberCountry: string;
}

const RegisterProfileStepOne = () => {
  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);
  const [geoInfo, setGeoInfo] = useState<GeoLocationData | null>(null);

  const [disableSubmit, setDisableSubmit] = useState(false);

  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  const userRegistrationNextStep = useAppSelector(state => state.auth.userRegistrationNextStep);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    const fetchGeoInfo = async () => {
      const geoInfo = await userService.getGeoInfo();
      setGeoInfo(geoInfo);
    }

    fetchGeoInfo();
  }, [dispatch]);

  useEffect(() => {
    if (userRegistrationNextStep !== UserRegistrationProgressStep.PROFILE_PART_ONE_PROFILE_TO_COMPLETE) {  
      navigate('/login');
    }

  }, [navigate, user?.user.id, userRegistrationNextStep]);

  const initialValues: FormValues = {
    gender: '',
    firstName: '',
    lastName: '',
    phoneNumberValue: '',
    phoneNumberCountry: '',
  };

  const handleSubmit = async (values: FormValues) => {
    const { gender, firstName, lastName, phoneNumberValue, phoneNumberCountry } = values;
    const phoneNumber: PhoneNumber = {
      value: phoneNumberValue,
      countryCode:phoneNumberCountry,
    };
  
    const userId = user?.user.id;
    
    setDisableSubmit(true);
    try {
      await dispatch(registerProfileStepOne({ userId, profile: { gender, firstName, lastName, phoneNumber } })).unwrap();
      navigate('/register/profile/step/2');
      setDisableSubmit(false);
    } catch (err) {
      setDisableSubmit(false);
    }
  };

  const validationSchema = Yup.object({
    gender: Yup.mixed<Gender>().oneOf(Object.values(Gender)).required(FIELD_REQUIRED),
    firstName: Yup.string().required(FIELD_REQUIRED),
    lastName: Yup.string().required(FIELD_REQUIRED),
    phoneNumberValue: Yup.string().required(FIELD_REQUIRED)
      .min(PHONE_NUMBER_MIN_LENGTH, PHONE_NUMBER_MIN_LENGTH_REQUIRED)
      .max(PHONE_NUMBER_MAX_LENGTH, PHONE_NUMBER_MAX_LENGTH_REQUIRED),
  });

  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

            {(
              <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-4xl text-slate-800 font-bold mb-6">Commençons par remplir votre profil pour mieux vous connaître</h1>
              {/* Form */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="space-y-8">
                    
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="gender">Je suis</label>
                      <div role="group" className="flex flex-wrap items-center -m-3">
                        {Object.values(Gender).map((gender) => (
                            <div className="m-3" key={gender}>
                              <label key={gender} className="flex items-center">
                                <Field type="radio" name="gender" className="form-radio" value={gender} />
                                <span className="text-base ml-2">{GenderLabels[gender]}</span>
                              </label>
                            </div>
                          ))}
                      </div>
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="gender"/></div>
                    </div>

                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="firstName">Prénom</label>
                      <Field name="firstName" className="form-input w-full h-16 text-base" type="text" placeholder="Saisissez votre prénom"/>
                      <div className="text-xs">Tel qu'indiqué sur votre document d'identité</div>
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="firstName"/></div>
                    </div>
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="lastName">Nom</label>
                      <Field name="lastName" className="form-input w-full h-16 text-base" type="text" placeholder="Saisissez votre nom"/>
                      <div className="text-xs">Tel qu'indiqué sur votre document d'identité</div>
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="lastName"/></div>
                    </div>
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="phoneNumberValue">Numéro de téléphone</label>
                      <Field
                        name="phoneNumberValue"
                        component={PhoneNumberField}
                        international
                        defaultCountry={geoInfo?.country}
                        style={{ width: '100%', height: '100%' }}
                      />
                      <div className="text-base mt-1 text-rose-500">
                        <ErrorMessage name="phoneNumberValue" />
                      </div>
                    </div>
                  </div>
                  <div className="justify-center mt-6">
                    {message && (
                      <div className="text-center mt-4">
                        <div className="text-base mt-1 text-rose-500">{ message }</div>
                      </div>
                    )}

                    <div className="text-center mt-4">
                      <button type="submit" disabled={disableSubmit} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Continuez</button>
                    </div>
                  </div>
                </Form>
              </Formik>

              </div>
            )}
          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default RegisterProfileStepOne;