import React, { useState } from "react";
import PaydunyaPaymentNotificationComponent from "./PaydunyaPaymentNotificationComponent";
import paydunyaService, { PaydunyaMakePaymentParams } from "../../services/paydunya.service";
import exchangeService, { ExchangeResponse, PaymentOrderExchangeResponse, updateExchangeWhenSendPaymentOrderFailed } from "../../services/exchange.service";
import paymentOrderService, { PaymentProvider, UpdatePaymentOrderExternalPaymentInfoParams } from "../../services/payment-order.service";
import MailtoLink from "../MailtoLink";

const isDev = process.env.REACT_APP_NODE_ENV === 'development';

interface PaydunyaPaymentModalProps {
  show: boolean;
  onClose: () => void;
  paymentOrder: PaymentOrderExchangeResponse;
  exchange: ExchangeResponse;
}

const PaydunyaPaymentModal: React.FC<PaydunyaPaymentModalProps> = ({ show, onClose, exchange, paymentOrder }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [showNotificationComponent, setShowNotificationComponent] = useState(false);
  const [currentPaymentToken, setCurrentPaymentToken] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<'failed' | 'pending' | 'payment-processing'>('pending');
  const [disableSubmit, setDisableSubmit] = useState(false);

  const updatePaymentOrderExternalPaymentInfos = async (token: string) => {
    const updatePaymentOrderExternalPaymentInfoParams: UpdatePaymentOrderExternalPaymentInfoParams = {
      paymentOrderId: paymentOrder.id,
      externalPaymentId: token,
      provider: PaymentProvider.Paydunya,
    };
  
    await paymentOrderService.updateExternalPaymentInfos(updatePaymentOrderExternalPaymentInfoParams);
  }

  const handleRetryPaymentStep1 = () => {
    setPaymentStatus('pending');
    setCurrentPaymentToken('');
    setPhoneNumber('');
    setOtpValue('');
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setDisableSubmit(true);

    try {
      let paymentTokenParams = currentPaymentToken;
  
      if (!paymentTokenParams) {
        const createCheckoutInvoiceResponse = await paymentOrderService.createPaydunyaCheckoutInvoice(exchange, paymentOrder);
  
        setCurrentPaymentToken(createCheckoutInvoiceResponse.token);
  
        await updatePaymentOrderExternalPaymentInfos(createCheckoutInvoiceResponse.token);
  
        paymentTokenParams = createCheckoutInvoiceResponse.token;
      }

      const user = exchange.user;

      const makePaymentPaydunyaParams: PaydunyaMakePaymentParams = {
        token: paymentTokenParams,
        countryIsoCode2: paymentOrder.paymentMethod.country?.isoCode2 ?? '',
        phoneNumber,
        paymentTypeCode: paymentOrder.paymentMethod.paymentType.code,
        customer: {
          fullname: `${user.firstName} ${user.lastName}`,
          email: user.email,
        },
        otpValue
      };

      const makePayment = await paydunyaService.makePayment(makePaymentPaydunyaParams);
      
      if (makePayment.success === true) {
        await exchangeService.updatePaymentOrderWhenPaymentProcessing(exchange.id);
        // TODO : In case of WAVE payment method when user is redirected to a url if the user stops the payment process once he is redirected to the url, 
        // the payment status will remain processing, perhaps another status should be created to manage this case 
        setPaymentStatus('payment-processing');
        if (makePayment.url) {
          window.location.href = makePayment.url;
        }
      }

      console.log("makePayment", makePayment);
      setShowNotificationComponent(true);
    } catch (error) {
      updateExchangeWhenSendPaymentOrderFailed(exchange.id);
      setPaymentStatus('failed');
      setShowNotificationComponent(false)
      console.error("error", error);
    } finally {
      setDisableSubmit(false);
    }
  }

  if (!show) return null;

  const amountWithCurrency = paymentOrder.amount.toLocaleString("fr-FR", {
    style: "currency",
    currency: paymentOrder.currency,
  });

  const paymentType = paymentOrder.paymentMethod.paymentType;
  const country = paymentOrder.paymentMethod.country;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className={`bg-white p-6 rounded-lg shadow-md w-full md:max-w-md lg:max-w-lg`}>
        <div className="flex justify-between">
          <h1 className="text-xl text-amber-500 font-semibold mb-4">Effectuer votre paiement</h1>
          <button onClick={onClose} className="text-amber-500 text-3xl hover:text-amber-700">
            ×
          </button>
        </div>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4 text-center">
            <label className="block font-medium">Montant</label>
            <div className="text-xl inline-flex font-bold">{amountWithCurrency}</div>
          </div>
          <div className="mb-4 text-center">
            <label className="block font-medium">Moyen de paiement</label>
            <div className="text-xl inline-flex font-bold">{paymentType.name}</div>
          </div>
          {paymentStatus === 'pending' && !showNotificationComponent && (
            <div>
              {((paymentType.code === 'ORANGE_MONEY') || isDev) && (
                <div className="mb-4 text-center">
                  {country?.isoCode2 === 'ML' && (
                    <>
                      <label className="block font-medium">Veuiller générer un code de paiement sur votre téléphone : </label>
                    </>
                  )}
                  {country?.isoCode2 === 'BF' && (
                    <>
                      <label className="block font-medium">Veuiller générer un code de paiement sur votre téléphone : </label>
                    </>
                  )}
                  {country?.isoCode2 === 'SN' && (
                    <>
                      <label className="block font-medium">Veuiller générer un code de paiement sur votre téléphone en saisissant le code USSD suivant : </label>
                      <div className="text-base font-medium text-amber-500">#144#391*USER_ORANGE_MONEY_PIN_CODE#</div>
                    </>
                  )}
                  {country?.isoCode2 === 'CI' && (
                    <>
                      <label className="block font-medium">Veuiller générer un code de paiement sur votre téléphone en saisissant le code USSD suivant : </label>
                      <div className="text-base font-medium text-amber-500">#144*82# + option 2</div>
                    </>
                  )}
                  {isDev && (
                    <label className="block font-medium">En environnement de test, veuillez saisir le mot de passe du client fictif</label>
                  )}
                  <input
                    type="text"
                    placeholder="Entrez le code de paiement"
                    value={otpValue}
                    onChange={(e) => setOtpValue(e.target.value)}
                    className="form-input w-full h-16 text-base"
                    required
                  />
                </div>
              )}
              <div className="mb-4">
                <label className="block font-medium mb-2">Numéro de téléphone Mobile Money</label>
                <input
                  type="text" 
                  placeholder="Saisissez votre numéro de téléphone" 
                  className="form-input w-full h-16 text-base"
                  value={phoneNumber}
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <div className="text-xs text-amber-500">Veuillez ne pas inclure l'indicatif</div>
              </div>
              <button type="submit" disabled={disableSubmit} className="bg-amber-500 hover:bg-amber-600 h-16 text-lg text-white px-4 py-2 rounded w-full">{`Payer ${amountWithCurrency}`}</button>
            </div>
          )}
        </form>
        {paymentStatus === 'payment-processing' && (<div className="mb-4 text-center">
          {paymentType.code === 'FREE_MONEY' && country?.isoCode2 === 'SN' && !showNotificationComponent && (
            <>
              <label className="block font-medium">Opération réussie, Veuillez tapez #150# pour finaliser votre paiement.</label>
            </>
          )}
          {paymentType.code === 'T_MONEY' && country?.isoCode2 === 'TG' && !showNotificationComponent && (
            <>
              <label className="block font-medium">Votre paiement est en cours de traitement. Merci de valider le paiement après reception de sms pour le compléter.</label>
            </>
          )}
          {paymentType.code === 'MOOV' && country?.isoCode2 === 'BF' && !showNotificationComponent && (
            <>
              <label className="block font-medium">Veuillez compléter le paiement en composant le *555*6#.</label>
            </>
          )}
          {paymentType.code === 'MOOV' && country?.isoCode2 === 'ML' && !showNotificationComponent && (
            <>
              <label className="block font-medium">Merci de finaliser le paiement sur votre téléphone.</label>
            </>
          )}
          {paymentType.code === 'MTN' && country?.isoCode2 === 'BJ' && !showNotificationComponent && (
            <>
              <label className="block font-medium">Votre paiement est en cours de traitement. Merci de valider le paiement après reception de sms pour le compléter.</label>
            </>
          )}
        </div>
        )}
        {showNotificationComponent && currentPaymentToken && phoneNumber &&
          <PaydunyaPaymentNotificationComponent
            token={currentPaymentToken}
            phoneNumber={phoneNumber}
            onSuccess={onClose}
            onRetry={handleRetryPaymentStep1}
          />
        }
        {paymentStatus === 'failed' && (<div className="mb-4 text-center font-medium text-lg">
          <div className="w-12 h-12 rounded-full bg-red-500 flex mx-auto" aria-hidden="true">
            <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M18 6l-12 12"></path>
              <path d="M6 6l12 12"></path>
            </svg>
          </div>
          Nous sommes désolés, mais votre paiement n'a pas abouti.<br/>Veuillez réessayer.<br/>Si vous continuez à rencontrer des problèmes, notre équipe de support est là pour vous aider à <span className="text-amber-500 hover:text-amber-600">support@swapier.co</span>.
          <button onClick={handleRetryPaymentStep1} disabled={disableSubmit} className="bg-amber-500 my-4  hover:bg-amber-600 h-16 text-lg text-white px-4 py-2 rounded w-full">Réesayer</button>
          <MailtoLink email={'support@swapier.co'} title={'Contacter le support'} className="text-base font-medium text-amber-500 hover:text-amber-600"></MailtoLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaydunyaPaymentModal;
