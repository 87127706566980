import { useCallback, useEffect, useState } from 'react';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Banner3 from '../components/Banner3';
import exchangeService, { BlockChainBlockExplorerUrl, ExchangeResponse, ExchangeState, FundsReceiptState, PaymentMethodExchangeResponse, PaymentOrderState } from '../services/exchange.service';
import moment from 'moment';
import { CountdownTimeDelta } from 'react-countdown';
import {
  openKkiapayWidget,
  addKkiapayListener,
  removeKkiapayListener,
} from 'kkiapay';
import { PaymentMethodFeeSource } from '../services/payment-order.service';
import QRCode from 'react-qr-code';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Hub2PaymentModal from '../components/Hub2/Hub2PaymentModal';
import PaydunyaPaymentModal from '../components/Paydunya/PaydunyaPaymentModal';
import io, { Socket } from 'socket.io-client';
import LoadingBackground from '../components/LoadingBackground';
import LoadingSpinner from '../components/LoadingSpinner';
import { getPaymentMethodBlockchainUri } from '../utils/helpers/payment-method.helper';
import MailtoLink from '../components/MailtoLink';

const SHOW_DATA_COPIED_TIME = 1400;

const ExchangeTracking = (props: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [exchange, setExchange] = useState<ExchangeResponse>();
  const [timeLeft, setTimeLeft] = useState<number | undefined>(undefined);
  const [amountCopied, setAmountCopied] = useState(false);
  const [addressCopied, setAddressCopied] = useState(false);
  const [referenceCopied, setReferenceCopied] = useState(false);
  const [showMobileMoneyPaymentModal, setShowMobileMoneyPaymentModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  
  const { exchangeId } = useParams();

  const navigate = useNavigate();

  const fetchExchange = async (exchangeId: string) => {
    setLoading(true);
    const exchange = await exchangeService.getExchange(exchangeId);
    setExchange(exchange);
    setLoading(false);
  }

  useEffect(() => {
    if (!exchangeId) {
      navigate(`/exchange`);
      return;
    };

    fetchExchange(exchangeId);
  },[exchangeId, navigate])

  useEffect(() => {
    const socket: Socket = io(process.env.REACT_APP_SWAPIER_SERVER_URL as string);

    socket.on(`exchange-status-${exchangeId}`, (exchange: ExchangeResponse) => {
      if (showMobileMoneyPaymentModal) {
        return;
      }
      fetchExchange(exchange.id);
    });

    return () => {
      socket.disconnect();
    };
  }, [exchangeId, showMobileMoneyPaymentModal]);

  useEffect(() => {
    if (!exchange) {
      return;
    };

    const customerPaymentExchangeDateLimit = moment(exchange.createdAt).add(120, 'minutes'); 
    if (moment().isBefore(customerPaymentExchangeDateLimit)) {
      setTimeLeft(customerPaymentExchangeDateLimit.valueOf());
    }
 
  },[exchange])

  const handleAmountOnCopy = () => {
    setAmountCopied(true);
    setTimeout(() => setAmountCopied(false), SHOW_DATA_COPIED_TIME);
  };

  const handleReferenceOnCopy = () => {
    setReferenceCopied(true);
    setTimeout(() => setReferenceCopied(false), SHOW_DATA_COPIED_TIME);
  };

  const handleAddressOnCopy = () => {
    setAddressCopied(true);
    setTimeout(() => setAddressCopied(false), SHOW_DATA_COPIED_TIME);
  };

  const successHandler = useCallback(async (response: any) => {
    // TODO: notify user that payment was successful and reload page to get update from transaction, use state variable to handle this, use fetchExchange
    // and avoid that user pay more
    console.log('-- success --', response);
    window.location.reload();
  }, []);

  const failedHandler = useCallback(async (response: any) => {
    // TODO: notify user that payment failed and reload page to get update from transaction, use fetchExchange
    console.log('-- failed --', response);
    window.location.reload();
  }, []);

  useEffect(() => {
    addKkiapayListener('success', successHandler);
    addKkiapayListener('failed', failedHandler);
    return () => {
      removeKkiapayListener('success');
      removeKkiapayListener('failed');
    };
  }, [failedHandler, successHandler]);

  if (!exchange) {
    return <>
      {loading && (
        <><LoadingBackground /><LoadingSpinner /></>
    )}</>;
  };

  // TODO: alert time is over with a modal
  // TODO: create link to resume exchange with updated rate or linked to exchange page with amounts and payment methods already filled in ?
  const ExchangeTimeoutMessage = () => <span>Temps écoulé ! Vous ne pouvez plus effectuer votre paiement ! Votre échange a été annulé ! Veuillez créer un nouvel échange</span>;

  const renderer = ({ hours, minutes, seconds, completed }: CountdownTimeDelta) => {
    if (completed) {
      setTimeLeft(undefined);
      return <ExchangeTimeoutMessage />;
    } 
      
    return <span>{hours}h:{minutes}mn:{seconds}s</span>;
  };

  const openHub2Modal = () => { 
    setShowMobileMoneyPaymentModal(true);
  };

  const openKkiapay = () => {
    if (exchange?.state !== ExchangeState.Pending) {
      // TODO: add notification customer can't pay (because exchange has successfull) and reload page ? Need to reload exchange or use new variable state?
      return;
    }

    const sendMobileMoneyOperatorFee = exchange.sendPaymentOrder.paymentOrderFees.filter(sendFeeAmount => sendFeeAmount.source === PaymentMethodFeeSource.MOBILE_MONEY_OPERATOR);
    const sendMobileMoneyOperatorFeeAmount = sendMobileMoneyOperatorFee.length > 0 ? sendMobileMoneyOperatorFee[0].amount : 0;
    const amount = exchange.sendPaymentOrder.amount - sendMobileMoneyOperatorFeeAmount;
    const user = exchange.user;

    const firstName = user.firstName.trim().split(/\s+/)[0];
    openKkiapayWidget({
      amount,
      paymentMethods: ['momo'],
      name: `${firstName} ${user.lastName}`,
      countries: [exchange.sendPaymentOrder.paymentMethod.country?.isoCode2 ?? ''],
      api_key: `${process.env.REACT_APP_KKIAPAY_PUBLIC_API_KEY}`,
      sandbox: process.env.REACT_APP_KKIAPAY_USE_SANDBOX === 'true',
      email: user.email,
      data: JSON.stringify({ exchangeId: exchange.id, paymentOrderId: exchange.sendPaymentOrder.id }),
    });
  };

  const LifecycleExchangeTimeline = () => {
    const user = exchange.user;

    let blockExplorerGetAddressLinkPrefix = '';
    switch (exchange?.receivePaymentOrder.paymentMethod.paymentType.code) {
      case 'BITCOIN':
        blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.BLOCKCHAIR;
        break;
    
      case 'ETHER':
        blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.ETHERSCAN;
        break;

      case 'ETHEREUM':
        blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.ETHERSCAN;
        break;
      
      case 'BSC':
        blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.BSCSCAN;
        break;

      case 'USDT':
        switch (exchange?.receivePaymentOrder.paymentMethod.cryptoTokenStandard) {
          case 'ERC20':
            blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.ETHERSCAN;
            break;
          case 'BEP20':
            blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.BSCSCAN;
            break;
          case 'TRC20':
            blockExplorerGetAddressLinkPrefix = BlockChainBlockExplorerUrl.TRONSCAN;
            break;
          default:
            break;
        }
      break;
    
      default:
        break;
    }
    
    let blockExplorerGetAddressLink = '';
    if (blockExplorerGetAddressLinkPrefix) {
      blockExplorerGetAddressLink = `${blockExplorerGetAddressLinkPrefix}/address/${exchange.receivePaymentOrder.userReceiptInfo}`;
    }
    
    return (
      <>
        <div className="border-slate-200">
          <div className="max-w-3xl m-auto mt-2">

            {/* Posts */}
            <div className="xl:-translate-x-16">

              {/* Post */}
              <article className="pt-1">
                <div className="xl:flex">
                  <div className="w-42 shrink-0 pr-3.5">
                    <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.createdAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                  </div>
                  <div className="grow pb-6 border-slate-200">
                    {/* List */}
                    <ul className="-my-2">
                      {/* List item */}
                      <li className="relative py-2">
                        <div className="flex items-center mb-1">
                          <div className="absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                          <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                            <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                              <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                            </svg>
                          </div>
                          <h3 className="text-lg font-bold text-slate-800 pl-9">Création de la transaction</h3>
                        </div>
                        <div className="pl-9">Vous avez initié un échange pour envoyer <span className="font-semibold">{exchange?.sendPaymentOrder.realAmount ?? exchange?.sendPaymentOrder.amount} {exchange?.sendPaymentOrder.currency}</span> via <span className="font-semibold">{exchange?.sendPaymentOrder.paymentMethod.paymentType.name} {exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard ? exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard : ''}</span> et pour recevoir <span className="font-semibold">{exchange?.receivePaymentOrder.realAmount ?? exchange?.receivePaymentOrder.amount} {exchange?.receivePaymentOrder.currency}</span> via <span className="font-semibold">{exchange?.receivePaymentOrder.paymentMethod.paymentType.name} {exchange?.receivePaymentOrder.paymentMethod.cryptoTokenStandard ? exchange?.receivePaymentOrder.paymentMethod.cryptoTokenStandard : ''}</span>.</div>
                      </li>
                      <li className="relative py-2">
                        <div className="flex items-center mb-1">
                          
                          {exchange?.state === ExchangeState.Pending && (
                            <div className="absolute left-0 rounded-full bg-white" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-slate-400" viewBox="0 0 20 20">
                                <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
                              </svg>
                            </div>
                          )}     

                          {[ExchangeState.PartiallyCompleted, ExchangeState.Completed].includes(exchange?.state) && (
                            <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                              </svg>
                            </div>
                          )}   

                          {[ExchangeState.Pending].includes(exchange?.state) && [PaymentOrderState.PaymentProcessing, PaymentOrderState.Failed].includes(exchange?.sendPaymentOrder.state) && (
                            <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                              </svg>
                            </div>
                          )}   

                          <h3 className="text-lg font-bold text-slate-800 pl-9">En attente de paiement</h3>
                        </div>
                        <div className="pl-9">Veuillez suivre les instructions pour effectuer votre paiement de <span className="font-semibold">{exchange?.sendPaymentOrder.amount} {exchange?.sendPaymentOrder.currency}</span> via <span className="font-semibold">{exchange?.sendPaymentOrder.paymentMethod.paymentType.name} {exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard ? exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard : ''}</span>.</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </article>

              {[ExchangeState.Pending].includes(exchange?.state) && PaymentOrderState.PaymentProcessing === exchange?.sendPaymentOrder.state && (
                <article className="pt-1">
                  <div className="xl:flex">
                    <div className="w-42 shrink-0 pr-3.5">
                      <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.sendPaymentOrder?.updatedAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                    </div>
                    <div className="grow pb-6 border-slate-200">
                      <ul className="-my-2">
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            
                            {exchange?.state === ExchangeState.Pending && (
                              <div className="absolute left-0 rounded-full bg-white" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-slate-400" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
                                </svg>
                              </div>
                            )}     

                            <h3 className="text-lg font-bold text-slate-800 pl-9">Paiement en cours de traitement</h3>
                          </div>
                          <div className="pl-9">
                            Nous mettons tout en œuvre pour que votre paiement soit traité rapidement et en toute sécurité. Cependant, dans de rares cas, ce processus peut prendre un peu plus de temps que prévu.
                            <br/><br/>Si vous n'avez pas reçu de notification confirmant votre paiement dans l'heure qui suit une notification à l'adresse <span className='font-semibold'>{user.email}</span>, 
                            nous vous encourageons vivement à contacter notre équipe de support dédiée à l'adresse suivante : <MailtoLink email={'support@swapier.co'} title={'support@swapier.co'} className="text-base font-medium text-amber-500 hover:text-amber-600"></MailtoLink>.
                            <br/><br/>Merci de votre compréhension et de votre patience.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              )}

              {[ExchangeState.Pending].includes(exchange?.state) && PaymentOrderState.Failed === exchange?.sendPaymentOrder.state && (
                <article className="pt-1">
                  <div className="xl:flex">
                    <div className="w-42 shrink-0 pr-3.5">
                      <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.sendPaymentOrder?.failedAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                    </div>
                    <div className="grow pb-6 border-slate-200">
                      {/* List */}
                      <ul className="-my-2">
                        {/* List item */}
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            <div className="absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                            <div className="absolute w-5 h-5 rounded-full bg-red-500 flex mx-auto" aria-hidden="true">
                              <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M18 6l-12 12"></path>
                                <path d="M6 6l12 12"></path>
                              </svg>
                            </div>
                            {/* TODO : ajouter icone help : afficher le message si le paiement s'est bien effectué de votre coté ou si vous ne comprenez pas pourquoi votre paiement échoue, constacter le support */}
                            <h3 className="text-lg font-bold text-slate-800 pl-9">Echec de votre paiement</h3>
                          </div>
                          <div className="pl-9">
                            
                            {exchange?.sendPaymentOrder.fundsReceiptState === FundsReceiptState.NotReceived && (
                              <div>Nous sommes désolés, mais votre paiement n'a pas abouti.</div>
                            )}

                            {exchange?.sendPaymentOrder.fundsReceiptState === FundsReceiptState.Insufficient && (
                              <div>Nous n'avons pas reçu la totalité de votre paiement.
                              <br/>Nous n'avons reçu que <span className="font-semibold">{exchange?.sendPaymentOrder.realAmount} {exchange?.sendPaymentOrder.currency}.</span>
                              <br/>Veuillez vous rapprocher du service support pour résoudre le problème.
                              </div>
                            )}  
                          </div>
                        </li>
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            
                            {exchange?.state === ExchangeState.Pending && (
                              <div className="absolute left-0 rounded-full bg-white" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-slate-400" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
                                </svg>
                              </div>
                            )}     

                            {[ExchangeState.PartiallyCompleted, ExchangeState.Completed].includes(exchange?.state) && (
                              <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                                </svg>
                              </div>
                            )}   

                            <h3 className="text-lg font-bold text-slate-800 pl-9">En attente de paiement</h3>
                          </div>
                          <div className="pl-9">Veuillez suivre les instructions pour effectuer votre paiement de <span className="font-semibold">{exchange?.sendPaymentOrder.amount} {exchange?.sendPaymentOrder.currency}</span> via <span className="font-semibold">{exchange?.sendPaymentOrder.paymentMethod.paymentType.name} {exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard ? exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard : ''}</span>.</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              )}

              {[ExchangeState.PartiallyCompleted, ExchangeState.Completed].includes(exchange?.state) && (
                <article className="pt-1">
                  <div className="xl:flex">
                    <div className="w-42 shrink-0 pr-3.5">
                      <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.sendPaymentOrder?.paidAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                    </div>
                    <div className="grow pb-6 border-slate-200">
                      {/* List */}
                      <ul className="-my-2">
                        {/* List item */}
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            <div className="absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                            <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                              </svg>
                            </div>
                            <h3 className="text-lg font-bold text-slate-800 pl-9">Paiement reçu</h3>
                          </div>
                          <div className="pl-9">Nous vous confirmons la bonne réception de votre paiement.</div>
                        </li>
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            
                            {exchange?.state === ExchangeState.PartiallyCompleted && (
                              <div className="absolute left-0 rounded-full bg-white" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-slate-400" viewBox="0 0 20 20">
                                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
                                </svg>
                              </div>
                            )}     

                            {[ExchangeState.Completed].includes(exchange?.state) && (
                              <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                                </svg>
                              </div>
                            )}   

                          {[ExchangeState.PartiallyCompleted].includes(exchange?.state) && PaymentOrderState.Failed === exchange?.receivePaymentOrder.state && (
                            <div className="absolute w-5 h-5 rounded-full bg-red-500 flex mx-auto" aria-hidden="true">
                              <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M18 6l-12 12"></path>
                                <path d="M6 6l12 12"></path>
                              </svg>
                            </div>
                          )}

                            <h3 className="text-lg font-bold text-slate-800 pl-9">Envoi de vos fonds</h3>
                          </div>
                          <div className="pl-9 [overflow-wrap:anywhere]">
                            Nous vous envoyons <span className="font-semibold">{exchange?.receivePaymentOrder.realAmount ?? exchange?.receivePaymentOrder.amount} {exchange?.receivePaymentOrder.currency}</span> via <span className="font-semibold">{exchange?.receivePaymentOrder.paymentMethod.paymentType.name} {exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard ? exchange?.sendPaymentOrder.paymentMethod.cryptoTokenStandard : ''}</span> à <span className="font-semibold">{exchange?.receivePaymentOrder.userReceiptInfo}</span>. 
                            <br/>Nous enverrons une notification à l'adresse <span className='font-semibold'>{user.email}</span> pour confirmer l'envoi de vos fonds.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              )}

              {[ExchangeState.PartiallyCompleted].includes(exchange?.state) && PaymentOrderState.Failed === exchange?.receivePaymentOrder.state && (
                <article className="pt-1">
                  <div className="xl:flex">
                    <div className="w-42 shrink-0 pr-3.5">
                      <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.receivePaymentOrder?.failedAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                    </div>
                    <div className="grow pb-6 border-slate-200">
                      {/* List */}
                      <ul className="-my-2">
                        {/* List item */}
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            <div className="absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                            <div className="absolute w-5 h-5 rounded-full bg-red-500 flex mx-auto" aria-hidden="true">
                              <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M18 6l-12 12"></path>
                                <path d="M6 6l12 12"></path>
                              </svg>
                            </div>
                            <h3 className="text-lg font-bold text-slate-800 pl-9">Echec d'envoi de vos fonds</h3>
                          </div>
                          <div className="pl-9">
                            Nous sommes désolés. L'envoi de vos fonds a échoué.
                            <br/>Notre équipe support a été alerté.
                          </div>
                        </li>
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            
                            <div className="absolute left-0 rounded-full bg-white" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-slate-400" viewBox="0 0 20 20">
                                <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z" />
                              </svg>
                            </div>

                            <h3 className="text-lg font-bold text-slate-800 pl-9">Résolution du problème d'envoi de vos fonds</h3>
                          </div>
                          <div className="pl-9">
                            Notre équipe support travail sur la  résolution du problème. 
                              Vous serez contacter si une action de votre part est nécéssaire pour finaliser la transaction.
                            <br/>Vous pouvez aussi contacter le support si vous souhaitez avoir des informations sur le traitement de votre transaction.  
                            <br/>En général nous résolvons les problèmes d'envois de fonds en moins de 24h.
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              )}

              {[ExchangeState.Completed].includes(exchange?.state) && (
                <article className="pt-1">
                  <div className="xl:flex">
                    <div className="w-42 shrink-0 pr-3.5">
                      <h2 className="text-s leading-snug font-bold text-slate-800 xl:leading-7 mb-4 xl:mb-0">{ moment(exchange?.receivePaymentOrder?.paidAt).format("DD MMM YY [-] HH:mm:ss") }</h2>
                    </div>
                    <div className="grow pb-6 border-slate-200">
                      {/* List */}
                      <ul className="-my-2">
                        {/* List item */}
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">
                            <div className="absolute left-0 h-full w-0.5 bg-slate-200 self-start ml-2.5 -translate-x-1/2 translate-y-3" aria-hidden="true"></div>
                            <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                              <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                              </svg>
                            </div>
                            <h3 className="text-lg font-bold text-slate-800 pl-9">Fonds envoyés</h3>
                          </div>
                          <div className="pl-9">Nous vous confirmons que vos fonds ont été transférés avec succès.
                            {exchange?.receivePaymentOrder.paymentMethod.paymentType.category === 'CRYPTO_CURRENCY' && (
                              <>
                                {blockExplorerGetAddressLink && (<span><br/>Le traitement de votre transaction est en cours et dans un délai moyen de 10 minutes vous pourrez suivre son évolution sur la blockchain via <a className="text-amber-500" href={blockExplorerGetAddressLink} target="_blank" rel='noreferrer'>ce lien</a>.</span>)}
                                <br/><br/><span className="font-semibold">Veuillez noter que les fonds devraient être disponibles dans votre portefeuille généralement en moins de 15 minutes</span>.{/* TODO : ajouter i avec intervalle de temps, explication sur la durée de transaction */}
                              </>
                            )}
                          </div> 
                        </li>
                        <li className="relative py-2">
                          <div className="flex items-center mb-1">

                            {[ExchangeState.Completed].includes(exchange?.state) && (
                              <div className="absolute left-0 rounded-full bg-green-500" aria-hidden="true">
                                <svg className="w-5 h-5 fill-current text-white" viewBox="0 0 20 20">
                                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                                </svg>
                              </div>
                            )}   

                            <h3 className="text-lg font-bold text-slate-800 pl-9">Fin de la transaction</h3>
                          </div>
                          <div className="pl-9">
                            Excellente nouvelle {exchange.user.firstName} ! Nous sommes ravis de vous annoncer que votre échange s'est parfaitement déroulé.
                            <br/><br/>Merci de nous avoir fait confiance.
                            <br/><br/>Au plaisir de vous revoir bientôt !
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </article>
              )}
            </div>

          </div>
        </div>
      </>
    );
  }

  const PaydunyaMobileMoneyPaymentBlock = () => {
    return (
      <div className="px-3 py-3">
        <div className="max-w-3xl m-auto mt-2">
          <div className="text-center mt-4">
            <button type="submit" onClick={openHub2Modal} className="btn lg:w-80 w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center" name="PAYMENT_METHOD">Payer</button>
          </div>
            <PaydunyaPaymentModal
              show={showMobileMoneyPaymentModal}
              onClose={() => {
                setShowMobileMoneyPaymentModal(false);
                fetchExchange(exchange.id);
              }} 
              paymentOrder={exchange.sendPaymentOrder}
              exchange={exchange}
            />
      </div>
    </div>
    );
  }

  const Hub2MobileMoneyPaymentBlock = () => {
    const sendPaymentOrder = exchange.sendPaymentOrder;
    return (
      <div className="px-3 py-3">
        <div className="max-w-3xl m-auto mt-2">
          <div className="text-center mt-4">
            <button type="submit" onClick={openHub2Modal} className="btn lg:w-80 w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center" name="PAYMENT_METHOD">Payer</button>
          </div>
            <Hub2PaymentModal
              show={showMobileMoneyPaymentModal}
              onClose={() => {
                setShowMobileMoneyPaymentModal(false);
                fetchExchange(exchange.id);
              }} 
              paymentOrder={sendPaymentOrder}
              exchangeId={exchange.id}
            />
      </div>
    </div>
    );
  }

  const KkiapayMobileMoneyPaymentBlock = () => {
    return (
      <div className="px-3 py-3">
        <div className="max-w-3xl m-auto mt-2">
          <Banner3 type="warning">
            En cliquant sur le button ci-dessous, vous serez automatiquement redirigé vers notre partenaire Kkiapay.<br />
            Vous devrez ensuite saisir votre numéro de téléphone et le réseau associé pour effectuer le paiement.
          </Banner3>

          <div className="text-center mt-4">
            <button type="submit" onClick={openKkiapay} className="btn w-80 h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white ml-3 whitespace-nowrap text-center" name="PAYMENT_METHOD">Payer</button>
          </div>

      </div>
    </div>
    );
  }

  const CryptoCurrencyPaymentBlock = () => { 
    const AMOUNT_NB_DECIMALS = 8;
    const sendPaymentOrder = exchange.sendPaymentOrder;
    const amount = sendPaymentOrder.amount;
    const address = sendPaymentOrder.userReceiptInfo;
    const realAmount = sendPaymentOrder.realAmount ?? 0;
    let amountToSend = amount.toString();
    if ([ExchangeState.Pending].includes(exchange?.state) && PaymentOrderState.Failed === exchange?.sendPaymentOrder.state && sendPaymentOrder.fundsReceiptState === FundsReceiptState.Insufficient) {
      amountToSend = (amount - realAmount).toFixed(AMOUNT_NB_DECIMALS);
    }

    const amountToSendWithCurrency = `${amountToSend} ${sendPaymentOrder.currency}`;
    const user = exchange.user;
    let cryptoUri = '';
    const paymentMethodNameUri = getPaymentMethodBlockchainUri(sendPaymentOrder.paymentMethod);
    if (paymentMethodNameUri) {
      cryptoUri = `${paymentMethodNameUri}:${address}?amount=${amountToSend}`;
    }

    return (
      <div className="px-3 py-3">
        <div className="max-w-3xl m-auto mt-2">
          <Banner3 type="warning">
            Afin que la transaction se déroule avec succès, veuillez envoyer le montant exact à l'adresse indiquée ci-dessous.
            <br/>Vous serez notifié sur {user.email} lorsque nous aurons reçu votre paiement.
          </Banner3>

          <div className="text-center font-semibold text-lg">
            <div>Envoyez ce montant : </div>
            
            <div className="mb-2">

              <CopyToClipboard text={ amountToSend.toString() } onCopy={handleAmountOnCopy}>
                <button>
                  <div className="text-lg inline-flex font-medium bg-amber-100 text-amber-600 text-center px-2 py-2">
                    {amountCopied ? "Copié !" : amountToSendWithCurrency}
                    <svg className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <rect x="8" y="8" width="12" height="12" rx="2" />
                      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                    </svg>
                  </div>
                </button>
              </CopyToClipboard>

            </div>
            
            <div>À cette adresse : </div>
            
            <CopyToClipboard text={ address } onCopy={handleAddressOnCopy}>
                <button>
                  <div className="lg:w-80 w-full text-sm font-medium bg-amber-100 text-amber-600 text-center px-2 py-2 [overflow-wrap:anywhere]">
                    {addressCopied ? "Copié !" : address}
                    <svg className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <rect x="8" y="8" width="12" height="12" rx="2" />
                      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                    </svg>
                  </div>
                </button>
              </CopyToClipboard>
            
            {cryptoUri && (<QRCode className='mx-auto my-4' value={ cryptoUri } />)}
          </div>
        </div>
      </div>
    );
  }

  const PerfectMoneyPaymentBlock = () => {
    const sendMobileMoneyOperatorFee = exchange.sendPaymentOrder.paymentOrderFees.filter(sendFeeAmount => sendFeeAmount.source === PaymentMethodFeeSource.DIGITAL_WALLET);
    const sendMobileMoneyOperatorFeeAmount = sendMobileMoneyOperatorFee.length > 0 ? sendMobileMoneyOperatorFee[0].amount : 0;
    const amount = exchange.sendPaymentOrder.amount - sendMobileMoneyOperatorFeeAmount;
    const amountRounded = Math.round(amount * 100) / 100;
    const paymentUrl = `${process.env.REACT_APP_SWAPIER_FRONTEND_URL}/exchange/tracking/${exchange?.id}`;
    const statusUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/payment-orders/digital-wallet/perfect-money/transaction/notification`;

    return (
      <div className="px-3 py-3">
        <div className="max-w-3xl m-auto mt-2">
          <Banner3 type="warning">
            En cliquant sur le button ci-dessous, vous serez automatiquement redirigé vers le site Perfect Money afin d'effectuer le paiement.<br />
            Les frais Perfect Money seront ajoutés à votre paiement lors de la deuxième étape du processus.<br />
            Le montant total à payer sur le site Perfect Money peut être légèrement différent du montant affiché ici.
          </Banner3>

          <div className="text-center mt-4">
            <form action="https://perfectmoney.is/api/step1.asp" method="POST">
              <input type="hidden" name="PAYEE_ACCOUNT" value="U38649995" />
              <input type="hidden" name="PAYEE_NAME" value="Swapier" />
              <input type="hidden" name="PAYMENT_AMOUNT" value={amountRounded} />
              <input type="hidden" name="PAYMENT_UNITS" value={exchange?.sendPaymentOrder.currency} />
              <input type="hidden" name="PAYMENT_ID" value={exchange?.sendPaymentOrder.id} />
              <input type="hidden" name="PAYMENT_URL_METHOD" value="GET" />
              <input type="hidden" name="NOPAYMENT_URL_METHOD" value="GET" />
              <input type="hidden" name="STATUS_URL" value={statusUrl} />
              <input type="hidden" name="PAYMENT_URL" value={paymentUrl} />
              <input type="hidden" name="NOPAYMENT_URL" value={paymentUrl} />
              <input type="hidden" name="BAGGAGE_FIELDS" value="EXCHANGE_ID CUSTOMER_ID" />
              <input type="hidden" name="EXCHANGE_ID" value={exchange.id} />
              <input type="hidden" name="CUSTOMER_ID" value={exchange.user.id} />
              <button type="submit" className="btn w-80 h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white ml-3 whitespace-nowrap text-center" name="PAYMENT_METHOD">Payer</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
        {loading && (
          <><LoadingBackground /><LoadingSpinner /></>
        )}
          <div className="px-4 py-8 w-full max-w-5xl mx-auto">

            <div className='sm:flex sm:justify-between sm:items-center mb-5'> 
              <div className='mb-4 sm:mb-0'>
                <h1 className='text-2xl md:text-3xl text-amber-500 font-bold'>Suivi de la transaction</h1>
              </div>
            </div>

            <div className='grid grid-cols-12 gap-6'>
              {/*<div className="col-span-full xl:col-span-8 bg-white shadow-lg rounded-sm border border-slate-200"></div>*/}
              
              <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
                <div className="px-3 py-3">
                  Numéro :
                  <CopyToClipboard text={ exchange?.reference } onCopy={handleReferenceOnCopy}>
                    <button>
                      <div className="text-base inline-flex font-semibold">
                      {referenceCopied ? "Copié !" : exchange?.reference}
                        <svg className="icon icon-tabler icon-tabler-copy" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                          <rect x="8" y="8" width="12" height="12" rx="2" />
                          <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                        </svg>
                      </div>
                    </button>
                  </CopyToClipboard>
                </div>
              </div>

              <div className="col-span-full bg-white shadow-lg rounded-sm border border-slate-200">
                <div className="px-5 py-1">
                  <LifecycleExchangeTimeline />
                </div>
              </div>

              <div className="col-span-full bg-white shadow-lg rounded-sm border-slate-200">
                {exchange?.state === ExchangeState.Pending && exchange?.sendPaymentOrder?.state !== PaymentOrderState.PaymentProcessing && timeLeft && exchange?.sendPaymentOrder.paymentMethod.paymentType.category === 'MOBILE_MONEY' && <PaydunyaMobileMoneyPaymentBlock />}
                {exchange?.state === ExchangeState.Pending && exchange?.sendPaymentOrder?.state !== PaymentOrderState.PaymentProcessing && timeLeft && exchange?.sendPaymentOrder.paymentMethod.paymentType.code === 'PERFECT_MONEY' && <PerfectMoneyPaymentBlock />}
                {exchange?.state === ExchangeState.Pending && exchange?.sendPaymentOrder?.state !== PaymentOrderState.PaymentProcessing && timeLeft && exchange?.sendPaymentOrder.paymentMethod.paymentType.category === 'CRYPTO_CURRENCY' && <CryptoCurrencyPaymentBlock />}
              </div>
            </div>

          </div>
        </main>

      </div>

    </div>
  );
}

export default ExchangeTracking;