interface ErrorCode {
  resultText: string;
  description: string;
  category: ResultCategory | string;
  resultTextFrench: string;
  descriptionFrench: string;
}

export enum ResultCategory {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const kycErrorCodes: Record<string, ErrorCode> = {
  '0001': {
    resultText: 'Data Invalid',
    description: 'An unknown error has occurred. Please contact someone at Swapier.',
    category: '',
    resultTextFrench: 'Données invalides',
    descriptionFrench: "Une erreur inconnue est survenue. Veuillez contacter quelqu'un chez Swapier.",
  },
  '0001b': {
    resultText: 'ID Authority image not available',
    description: 'There was no photo returned by the id authority for the submitted ID number.',
    category: '',
    resultTextFrench: "Image de l'autorité ID non disponible",
    descriptionFrench: "Aucune photo n'a été renvoyée par l'autorité ID pour le numéro d'identification soumis.",
  },
  '0001c': {
    resultText: 'ID Card image unusable',
    description: 'The submitted ID card image is corrupted or was not included in zip',
    category: '',
    resultTextFrench: "Image de la carte d'identité inutilisable",
    descriptionFrench:
      "L'image de la carte d'identité soumise est corrompue ou n'était pas incluse dans le fichier zip",
  },
  '0001d': {
    resultText: 'Selfie image unusable',
    description: 'The submitted selfie is corrupted or was not included in zip',
    category: '',
    resultTextFrench: 'Image du selfie inutilisable',
    descriptionFrench: "Le selfie soumis est corrompu ou n'était pas inclus dans le fichier zip",
  },
  '1013': {
    resultText: 'Unable to Validate ID',
    description: 'The ID info was not found in the ID authority database.',
    category: 'Rejected',
    resultTextFrench: "Impossible de valider l'identité",
    descriptionFrench: "Les informations d'identité n'ont pas été trouvées dans la base de données de l'autorité ID.",
  },
  '0814': {
    resultText: 'Machine Judgement - Provisional - Possible Spoof',
    description:
      "The machine thinks there is a possible spoof but can't make a final decision. A document reviewer will check the Selfie for potential fraud attempts.",
    category: 'Suspected for spoof and sent to document reviewer for final decision',
    resultTextFrench: 'Jugement de la machine - provisoire - éventuelle usurpation',
    descriptionFrench:
      "La machine pense qu'il y a une possible usurpation, mais ne peut pas prendre de décision finale. Un examinateur de documents vérifiera le selfie pour détecter d'éventuelles tentatives de fraude.",
  },
  '0815': {
    resultText: 'Machine Judgement - Provisional - Compare Unsure',
    description: 'The machine is not sure if both images match, so a document reviewer will compare.',
    category: 'Provisionally Approved',
    resultTextFrench: 'Jugement de la machine - provisoire - comparaison incertaine',
    descriptionFrench:
      "La machine n'est pas sûre que les deux images correspondent, un examinateur de documents effectuera donc la comparaison.",
  },
  '1210': {
    resultText: 'Enroll User. Human Judgement - PASS',
    description: 'The human reviewer marked that both images are of the same person and no spoof was detected.',
    category: 'Approved',
    resultTextFrench: "Inscription de l'utilisateur. Jugement humain - PASS",
    descriptionFrench:
      "L'examinateur humain a indiqué que les deux images sont de la même personne et aucune usurpation n'a été détectée.",
  },
  '1211': {
    resultText: 'Failed Enroll Human Judgement - FAIL - Human Compare Failed',
    description: 'The human reviewer marked that both images are different persons.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'inscription. Jugement humain - ÉCHEC - Comparaison humaine échouée",
    descriptionFrench: "L'examinateur humain a indiqué que les deux images sont de personnes différentes.",
  },
  '1213': {
    resultText: 'Human Judgement - Provisional - Liveliness Unsure',
    description: 'The human reviewer is not sure if there is a possible fraud attempt on the Selfie.',
    category: 'Inconclusive',
    resultTextFrench: 'Jugement humain - Provisoire - Vivacité incertaine',
    descriptionFrench: "L'évaluateur humain n'est pas sûr s'il y a une tentative de fraude possible sur le Selfie.",
  },
  '1214': {
    resultText: 'Human Judgement - ID Card Check Failed',
    description: 'The human reviewer has identified a problem with the ID card photo',
    category: 'Rejected',
    resultTextFrench: "Jugement humain - Vérification de la carte d'identité échouée",
    descriptionFrench: "L'évaluateur humain a identifié un problème avec la photo de la carte d'identité",
  },
  '0908': {
    resultText: 'Error - Issuer not available',
    description: 'The ID authority is unavailable. Re-run the job when ID authority is back online',
    category: '',
    resultTextFrench: 'Erreur - Émetteur non disponible',
    descriptionFrench:
      "L'autorité ID n'est pas disponible. Relancez le travail lorsque l'autorité ID est de nouveau en ligne",
  },
  '1016': {
    resultText: 'Error - Need to Activate Product',
    description: 'You do not have access to the ID Type. Please contact support for more information.',
    category: '',
    resultTextFrench: "Erreur - Besoin d'activer le produit",
    descriptionFrench:
      "Vous n'avez pas accès au type d'identité. Veuillez contacter le support pour plus d'informations.",
  },
  '0810': {
    resultText: 'Document Verified',
    description: 'Images matched, no spoof was detected on Selfie and the document is valid.',
    category: 'Approved',
    resultTextFrench: 'Document vérifié',
    descriptionFrench:
      "Les images correspondent, aucune usurpation n'a été détectée sur le Selfie et le document est valide.",
  },
  '0811': {
    resultText: 'Unable to Verify Document - reason',
    description:
      "Document image was usable but document verification failed due to any of the following reasons: - Document is expired - The selfie did not match the photo on document - Selfie failed liveness checks - Some security features were missing on the document - The information on document is inconsistent i.e. the MRZ information did not match the OCR'ed information - A wrong document type was uploaded",
    category: 'Rejected',
    resultTextFrench: 'Impossible de vérifier le document - raison',
    descriptionFrench:
      "L'image du document était utilisable, mais la vérification du document a échoué pour l'une des raisons suivantes : - Le document est expiré - Le selfie ne correspond pas à la photo sur le document - Le selfie a échoué aux vérifications de vivacité - Certaines caractéristiques de sécurité étaient absentes sur le document - Les informations sur le document sont incohérentes, c'est-à-dire que les informations MRZ ne correspondent pas aux informations OCR - Un mauvais type de document a été téléchargé",
  },
  '0812': {
    resultText: 'Unable to verify Document - reason',
    description:
      'Document verification failed because the document image is unusable or an invalid document image was uploaded e.g. work ID card',
    category: 'Rejected',
    resultTextFrench: 'Impossible de vérifier le document - raison',
    descriptionFrench:
      "La vérification du document a échoué car l'image du document est inutilisable ou une image de document invalide a été téléchargée, par exemple une carte d'identité professionnelle",
  },
  '1014': {
    resultText: 'Unsupported ID Type',
    description:
      '​The country_code and or the id_type in the info.json file is not supported, please use any of the supported country_codes and id_types in the documentation​',
    category: 'Rejected',
    resultTextFrench: "Type d'identité non pris en charge",
    descriptionFrench:
      "Le country_code et/ou le id_type dans le fichier info.json ne sont pas pris en charge, veuillez utiliser l'un des country_codes et id_types pris en charge dans la documentation",
  },
  '0921': {
    resultText: 'FAIL - No Face Found',
    description: 'There was no face found in the uploaded selfie. Upload an image with a face.',
    category: 'Rejected',
    resultTextFrench: 'ÉCHEC - Pas de visage trouvé',
    descriptionFrench: "Aucun visage n'a été trouvé dans le selfie téléchargé. Téléchargez une image avec un visage.",
  },
  '0922': {
    resultText: 'FAIL - Image Quality Judged Too Poor',
    description: 'The uploaded selfie quality was too poor. Upload a higher quality selfie.',
    category: 'Rejected',
    resultTextFrench: "ÉCHEC - Qualité de l'image jugée trop faible",
    descriptionFrench: 'La qualité du selfie téléchargé était trop faible. Téléchargez un selfie de meilleure qualité.',
  },
  '0820': {
    resultText: 'Authenticate User Machine Judgement - PASS',
    description: 'Comparison between the images matched.',
    category: 'Approved',
    resultTextFrench: "Authentification de l'utilisateur - RÉUSSITE",
    descriptionFrench: 'Comparaison entre les images assorties.',
  },
  '0821': {
    resultText: 'Failed Authentication Machine Judgement - FAIL - COMPARISON',
    description: 'Images did not match and therefore job was rejected.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'authentification - ÉCHEC - COMPARAISON de la machine",
    descriptionFrench: 'Les images ne correspondent pas et donc la tâche a été rejetée.',
  },
  '0822': {
    resultText: 'Machine Judgement - PURE PROVISIONAL',
    description: "Waiting on document reviewer's decision.",
    category: 'Provisionally Approved',
    resultTextFrench: 'Jugement de la machine - PROVISIONNEL PUR',
    descriptionFrench: "En attente de la décision de l'examinateur de documents.",
  },
  '0823': {
    resultText: 'Failed Authentication Machine Judgement - FAIL - Possible Spoof',
    description:
      'Machine rejected job due to possible fraud attempt on the Selfie - This can be fraud attempt or sometimes images are too blurry or dark in the liveness check to make a decision.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'authentification - ÉCHEC - Possible Spoof de la machine",
    descriptionFrench:
      "La machine a rejeté la tâche en raison d'une tentative de fraude possible sur le Selfie - Il peut s'agir d'une tentative de fraude ou parfois les images sont trop floues ou sombres lors de la vérification de la vivacité pour prendre une décision.",
  },
  '0824': {
    resultText: 'Machine Judgement - PROVISIONAL - Possible Spoof',
    description:
      "The machine thinks there is a possible spoof but can't make a final decision. A document reviewer will check the Selfie for potential fraud attempts.",
    category: 'Suspected for spoof and sent to document reviewer for final decision',
    resultTextFrench: 'Jugement de la machine - PROVISIONNEL - Possible Spoof',
    descriptionFrench:
      "La machine pense qu'il y a un possible spoof mais ne peut pas prendre une décision finale. Un examinateur de documents vérifiera le Selfie pour des tentatives de fraude potentielles.",
  },
  '0825': {
    resultText: 'Machine Judgement - PROVISIONAL - Machine Compare Unsure',
    description: 'The machine is not sure if both images match, so a document reviewer will compare.',
    category: 'Provisionally Approved',
    resultTextFrench: 'Jugement de la machine - PROVISIONNEL - Machine Compare Incertaine',
    descriptionFrench:
      "La machine n'est pas sûre si les deux images correspondent, donc un examinateur de documents va comparer.",
  },
  '1220': {
    resultText: 'Authenticate User Human Judgement - PASS',
    description: 'The document reviewer marked that both images are of the same person.',
    category: 'Approved',
    resultTextFrench: 'Authentification utilisateur - DÉCISION HUMAINE - RÉUSSIE',
    descriptionFrench: "L'examinateur de documents a confirmé que les deux images sont de la même personne.",
  },
  '1221': {
    resultText: 'Failed Authentication Human Judgement - FAIL - Human Compare Failed',
    description: 'The document reviewer marked that both images are different persons.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'authentification - DÉCISION HUMAINE - ÉCHEC - Comparaison humaine échouée",
    descriptionFrench:
      "L'examinateur de documents a confirmé que les deux images représentent des personnes différentes.",
  },
  '1222': {
    resultText: 'Failed Authentication Human Judgement - FAIL - Spoof Detected',
    description:
      'The document reviewer detected a possible fraud attempt on the Selfie - This can be a fraud attempt or sometimes images are too blurry or dark in the liveness check to make a decision.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'authentification - DÉCISION HUMAINE - ÉCHEC - Tentative de fraude détectée",
    descriptionFrench:
      "L'examinateur de documents a détecté une tentative de fraude possible sur le Selfie - Il peut s'agir d'une tentative de fraude ou parfois les images sont trop floues ou sombres lors de la vérification de la vivacité pour prendre une décision.",
  },
  '0941': {
    resultText: 'FAIL - No Face Found',
    description: 'There was no face found in the uploaded selfie. Upload an image with a face.',
    category: 'Rejected',
    resultTextFrench: 'ÉCHEC - Aucun visage trouvé',
    descriptionFrench: "Aucun visage n'a été trouvé dans le selfie téléchargé. Téléchargez une image avec un visage.",
  },
  '0942': {
    resultText: 'FAIL - Image Quality Judged Too Poor',
    description: 'The uploaded selfie quality was too poor. Upload a higher quality selfie.',
    category: 'Rejected',
    resultTextFrench: "ÉCHEC - Qualité d'image jugée trop faible",
    descriptionFrench: 'La qualité du selfie téléchargé était trop faible. Téléchargez un selfie de meilleure qualité.',
  },
  '0840': {
    resultText: 'Enroll User PASS - Machine Judgement',
    description: 'Machine passed Selfie - no spoof detected and image contains a face.',
    category: 'Approved',
    resultTextFrench: 'Inscription utilisateur RÉUSSIE - Jugement de la machine',
    descriptionFrench:
      "La machine a approuvé le selfie - aucune tentative de spoofing détectée et l'image contient un visage.",
  },
  '0843': {
    resultText: 'Failed Enroll FAIL - Possible Spoof - Machine Judgement',
    description: 'Machine rejected job due to possible fraud attempt on the Selfie.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'inscription - ÉCHEC - Possible Spoof - Jugement de la machine",
    descriptionFrench: "La machine a rejeté la tâche en raison d'une tentative de fraude possible sur le Selfie.",
  },
  '0844': {
    resultText: 'PROVISIONAL - Possible Spoof - Machine Judgement',
    description:
      "The machine thinks there is a possible spoof but can't make a final decision. A document reviewer will check the Selfie for potential fraud attempts.",
    category: 'Suspected for spoof and sent to document reviewer for final decision',
    resultTextFrench: 'PROVISIONNEL - Possible Spoof - Jugement de la machine',
    descriptionFrench:
      "La machine pense qu'il y a un possible spoof mais ne peut pas prendre une décision finale. Un examinateur de documents vérifiera le Selfie pour des tentatives de fraude potentielles.",
  },
  '0846': {
    resultText: 'PENDING - Possible Spoof - Machine Judgement',
    description: 'Awaiting document review result.',
    category: 'Pending',
    resultTextFrench: 'EN ATTENTE - Possible Spoof - Jugement de la machine',
    descriptionFrench: "En attente du résultat de l'examen de documents.",
  },
  '1240': {
    resultText: 'Enroll User PASS - Human Judgement',
    description: 'The document reviewer did not find any fraud attempts on the Selfie.',
    category: 'Approved',
    resultTextFrench: 'Inscription utilisateur RÉUSSIE - DÉCISION HUMAINE',
    descriptionFrench: "L'examinateur de documents n'a détecté aucune tentative de fraude sur le Selfie.",
  },
  '1241': {
    resultText: 'Failed Enroll. FAIL - Image Unusable',
    description:
      'The document reviewer marked the Selfie as unusable either because the Selfie is too dark or too blurry.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'inscription - ÉCHEC - Image inutilisable",
    descriptionFrench:
      "L'examinateur de documents a marqué le Selfie comme inutilisable car il est trop sombre ou trop flou.",
  },
  '1242': {
    resultText: 'Failed Enroll Spoof detected - Human Judgement',
    description:
      'The document reviewer detected a possible fraud attempt on the Selfie - This can be a fraud attempt or sometimes images are too blurry or dark in the liveness check to make a decision.',
    category: 'Rejected',
    resultTextFrench: "Échec de l'inscription - DÉTECTION DE SPOOF - DÉCISION HUMAINE",
    descriptionFrench:
      "L'examinateur de documents a détecté une tentative de fraude possible sur le Selfie - Il peut s'agir d'une tentative de fraude ou parfois les images sont trop floues ou sombres lors de la vérification de la vivacité pour prendre une décision.",
  },
};

// Add the new error codes
kycErrorCodes['1014'] = {
  resultText: 'Error - Unsupported ID number format',
  description: 'The ID number submitted was of an invalid format. Please use our regex samples as a guide.',
  category: '',
  resultTextFrench: "Erreur - Format de numéro d'identité non pris en charge",
  descriptionFrench:
    "Le numéro d'identité soumis était d'un format invalide. Veuillez utiliser nos exemples de regex comme guide.",
};

kycErrorCodes['1015'] = {
  resultText: 'Error - Queried Database Unavailable',
  description: 'The ID authority is unavailable.',
  category: '',
  resultTextFrench: 'Erreur - Base de données interrogée indisponible',
  descriptionFrench: "L'autorité ID est indisponible.",
};

kycErrorCodes['1016'] = {
  resultText: 'Error - Need to Activate Product',
  description: 'You do not have access to the ID Type. Please contact support for more information.',
  category: '',
  resultTextFrench: "Erreur - Besoin d'activer le produit",
  descriptionFrench:
    "Vous n'avez pas accès au type d'identité. Veuillez contacter le support pour plus d'informations.",
};

kycErrorCodes['1020'] = {
  resultText: 'Exact Match',
  description: 'The user submitted details exactly match the ID info in the ID authority database.',
  category: 'Approved',
  resultTextFrench: 'Correspondance exacte',
  descriptionFrench:
    "Les détails soumis par l'utilisateur correspondent exactement aux informations d'identité dans la base de données de l'autorité ID.",
};

kycErrorCodes['1021'] = {
  resultText: 'Partial Match',
  description:
    'At least one of the user submitted details partially/exactly match the ID info in the ID authority database.',
  category: 'Approved',
  resultTextFrench: 'Correspondance partielle',
  descriptionFrench:
    "Au moins un des détails soumis par l'utilisateur correspond partiellement/exactement aux informations d'identité dans la base de données de l'autorité ID.",
};

kycErrorCodes['1022'] = {
  resultText: 'No Match',
  description: 'None of the user submitted details partially/exactly match the ID info in the ID authority database.',
  category: 'Rejected',
  resultTextFrench: 'Aucune correspondance',
  descriptionFrench:
    "Aucun des détails soumis par l'utilisateur ne correspond partiellement/exactement aux informations d'identité dans la base de données de l'autorité ID.",
};

kycErrorCodes['1012'] = {
  resultText: 'Valid ID',
  description: '',
  category: 'Approved',
  resultTextFrench: 'Identité valide',
  descriptionFrench: '',
};

// Add the new error codes
kycErrorCodes['1013'] = {
  resultText: 'Unable to Validate ID',
  description: 'The ID info was not found in the ID authority database.',
  category: 'Rejected',
  resultTextFrench: "Impossible de valider l'identité",
  descriptionFrench: "Les informations d'identité n'ont pas été trouvées dans la base de données de l'autorité ID.",
};

kycErrorCodes['1014'] = {
  resultText: 'Error - Unsupported ID number format',
  description: 'The ID number submitted was of an invalid format. Please use our regex samples as a guide.',
  category: '',
  resultTextFrench: "Erreur - Format de numéro d'identité non pris en charge",
  descriptionFrench:
    "Le numéro d'identité soumis était d'un format invalide. Veuillez utiliser nos exemples de regex comme guide.",
};

kycErrorCodes['1015'] = {
  resultText: 'Error - Queried Database Unavailable',
  description: 'The ID authority is unavailable.',
  category: '',
  resultTextFrench: 'Erreur - Base de données interrogée indisponible',
  descriptionFrench: "L'autorité ID est indisponible.",
};

kycErrorCodes['1016'] = {
  resultText: 'Error - Need to Activate Product',
  description: 'You do not have access to the ID Type. Please contact support for more information.',
  category: '',
  resultTextFrench: "Erreur - Besoin d'activer le produit",
  descriptionFrench:
    "Vous n'avez pas accès au type d'identité. Veuillez contacter le support pour plus d'informations.",
};

// Add the rest of the error codes
kycErrorCodes['0911'] = {
  resultText: 'No Face Found',
  description: 'There was no face found in the uploaded selfie. Upload an image with a face.',
  category: 'Rejected',
  resultTextFrench: 'Aucun visage trouvé',
  descriptionFrench: "Aucun visage n'a été trouvé sur le selfie téléchargé. Téléchargez une image avec un visage.",
};

kycErrorCodes['0912'] = {
  resultText: 'Image Quality Judged Too Poor',
  description: 'The uploaded selfie quality was too poor. Upload a higher quality selfie.',
  category: 'Rejected',
  resultTextFrench: "Qualité d'image jugée trop mauvaise",
  descriptionFrench: 'La qualité du selfie téléchargé était trop mauvaise. Téléchargez un selfie de meilleure qualité.',
};

kycErrorCodes['0810'] = {
  resultText: 'Enroll User Machine Judgement - PASS',
  description: 'Images matched and no spoof detected on the selfie.',
  category: 'Approved',
  resultTextFrench: "Inscription de l'utilisateur - Jugement de la machine - VALIDÉ",
  descriptionFrench: "Les images correspondent et aucune usurpation n'a été détectée sur le selfie.",
};

kycErrorCodes['0811'] = {
  resultText: 'Failed Enroll Machine Judgement - FAIL - Compare Rejected',
  description: 'Images did not match and job was therefore rejected.',
  category: 'Rejected',
  resultTextFrench: "Échec de l'inscription - Jugement de la machine - REJETÉ - Comparaison refusée",
  descriptionFrench: 'Les images ne correspondent pas et le travail a donc été refusé.',
};

kycErrorCodes['0812'] = {
  resultText: 'Machine Judgement - Pure Provisional',
  description: "Waiting on document reviewer's decision.",
  category: 'Provisionally Approved',
  resultTextFrench: 'Jugement de la machine - Purement provisoire',
  descriptionFrench: "En attente de la décision de l'examinateur des documents.",
};

kycErrorCodes['0813'] = {
  resultText: 'Failed Enroll Machine Judgement - FAIL, Possible Spoof',
  description:
    'Machine rejected job due to possible fraud attempt on the Selfie - This can be fraud attempt or some times images are too blurry or dark in the liveness check to make a decision.',
  category: 'Rejected',
  resultTextFrench: "Échec de l'inscription - Jugement de la machine - ÉCHEC, Usurpation possible",
  descriptionFrench:
    "La machine a rejeté le travail en raison d'une possible tentative de fraude sur le Selfie - Cela peut être une tentative de fraude ou parfois les images sont trop floues ou sombres lors de la vérification de vivacité pour prendre une décision.",
};
