import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import SidebarLinkGroup from './SidebarLinkGroup';

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const user = JSON.parse(localStorage.getItem('user'));
  const isUserKycCompleted = user && user?.user?.isKycCompleted;

  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector('body').classList.add('sidebar-expanded');
    } else {
      document.querySelector('body').classList.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-80 lg:w-28 lg:sidebar-expanded:!w-64 2xl:!w-80 shrink-0 bg-white p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? 'translate-x-0' : '-translate-x-80'
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-amber-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <div className="md:block md:absolute top-0 bottom-0 left-0" aria-hidden="true">
              <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
            </div>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 mt-10">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* Investir */}
              <li className={`px-3 py-4 rounded-sm border-b border-b-solid hover:bg-gray-100 last:border-none`}>
                <NavLink
                  end
                  to="/exchange"
                  className={`block truncate transition duration-150${
                    (pathname === '/' || pathname.includes('exchange')) && ''
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">

                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <title/>
                        <g id="growth">
                        <path className={`fill-current text-slate-600 ${pathname.includes('exchange') && '!text-amber-500'}`}
                          d="M21.5,21H20V9.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V21H14V13.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V21H8V14.5a.5.5,0,0,0-.5-.5h-3a.5.5,0,0,0-.5.5V21H2.5a.5.5,0,0,0,0,1h19a.5.5,0,0,0,0-1ZM7,21H5V15H7Zm6,0H11V14h2Zm6,0H17V10h2Z"/>
                        <path className={`fill-current text-slate-600 ${pathname.includes('exchange') && '!text-amber-500'}`}
                          d="M2.5,13a.47.47,0,0,0,.35-.15l4.7-4.69L11.2,10.9a.49.49,0,0,0,.65-.05L19,3.71V5.5a.5.5,0,0,0,1,0v-3a.41.41,0,0,0,0-.19A.51.51,0,0,0,19.69,2a.41.41,0,0,0-.19,0h-3a.5.5,0,0,0,0,1h1.79L11.45,9.84,7.8,7.1a.49.49,0,0,0-.65.05l-5,5a.48.48,0,0,0,0,.7A.47.47,0,0,0,2.5,13Z"/>
                        </g>
                      </svg>
                      <span className={`text-lg font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                        (pathname === '/' || pathname.includes('exchange')) && 'text-amber-500'
                      }`}>
                        Investir
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
              {/* Mes transactions */}
              <li className={`px-3 py-4 rounded-sm border-b border-b-solid hover:bg-gray-100 last:border-none`}>
                <NavLink
                  end
                  to="/transaction-history"
                  className={`block truncate transition duration-150${
                    (pathname.includes('transaction-history')) && ''
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <svg className={`w-4 h-4 shrink-0 fill-current text-slate-600 ${pathname.includes('/transaction-history') && '!text-amber-500'}`} viewBox="0 0 16 16">
                        <path d="M5 9h11v2H5V9zM0 9h3v2H0V9zm5 4h6v2H5v-2zm-5 0h3v2H0v-2zm5-8h7v2H5V5zM0 5h3v2H0V5zm5-4h11v2H5V1zM0 1h3v2H0V1z" />
                      </svg>
                      <span className={`text-lg font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ${
                        (pathname.includes('transaction-history')) && 'text-amber-500'
                      }`}>
                        Mes transactions
                      </span>
                    </div>
                  </div>
                </NavLink>
              </li>
              {/* Mon compte */}
              <SidebarLinkGroup activecondition={pathname.includes('account')}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block truncate transition duration-150 ${
                          pathname.includes('account') && ''
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                              <path className={`fill-current text-slate-600 ${pathname.includes('account') && '!text-amber-500'}`}
                                d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                              />
                              <path className={`fill-current text-slate-600 ${pathname.includes('account') && '!text-amber-500'}`}
                                d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z" 
                              />
                            </svg>
                            <span className="text-lg font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Mon compte
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${open && 'transform rotate-180'}`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-1 ${!open && 'hidden'}`}>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/account/personal-informations"
                              className={({ isActive }) =>
                                'block text-slate-400 hover:text-slate-500 transition duration-150 truncate ' + (isActive ? '!text-amber-500' : '')
                              }
                            >
                              <span className="text-base font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Mes informations personnelles
                              </span>
                            </NavLink>
                          </li>
                          {!isUserKycCompleted && (<li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/account/verification"
                              className={({ isActive }) =>
                                'block text-slate-400 hover:text-slate-500 transition duration-150 truncate ' + (isActive ? '!text-amber-500' : '')
                              }
                            >
                              <span className="text-base font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Vérifiez mon compte
                              </span>
                            </NavLink>
                          </li>)}
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              {/* Support */}
              <li className={`px-3 py-4 rounded-sm border-b border-b-solid hover:bg-gray-100 last:border-none`}>
                <a
                  href="mailto:support@swapier.co"
                  className={`block truncate transition duration-150`}
                >
                  <div className="flex items-center justify-between">
                    <div className="grow flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                        <path d="M12 16v.01" />
                        <path d="M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" />
                      </svg>
                      <span className={`text-lg font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200`}>
                        Support
                      </span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
 
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;