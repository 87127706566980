import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import moment from "moment";

const usersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/users`;
export interface AuthCredentials {
  email: string;
  password: string;
}

export interface KycJobResponse {
  timestamp: string;
  signature: string;
  job_complete: boolean;
  job_success: boolean;
  code: string;
  kyc_receipt: string;
  result: {
    Source: string;
    DOB: string;
    IDType: string;
    Actions: {
      Liveness_Check: string;
      Register_Selfie: string;
      Verify_Document: string;
      Human_Review_Compare: string;
      Return_Personal_Info: string;
      Selfie_To_ID_Card_Compare: string;
      Human_Review_Liveness_Check: string;
    };
    ResultCode: string;
    ResultText: string;
    ResultType: string;
    SmileJobID: string;
    JSONVersion: string;
    Country: string;
    Document: string;
    IDNumber: string;
    IsFinalResult: string;
    FullName: string;
    ExpirationDate: string;
    SecondaryIDNumber: string;
    PartnerParams: {
      job_id: string;
      user_id: string;
      job_type: string;
      libraryVersion: string;
    };
    ConfidenceValue: string;
    IsMachineResult: string;
  };
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

export interface PhoneNumber {
  value: string;
  countryCode: string | null;
}

export interface RegisterProfileStepOne {
  gender: Gender | '';
  firstName: string;
  lastName: string;
  phoneNumber: PhoneNumber;
}

export interface RegisterProfileStepTwo {
  birthdate: Date | null;
  nationality: string;
  addressCountry: string;
}

export interface SaveKycInfo {
  idDocumentIssuingCountry: string;
  idDocumentNumber: string;
  idDocumentType: string;
}

export interface VerifyUserAccountParams {
  userId?: string;
  code: string;
}

export interface SendResetPasswordLink {
  email: string;
}

export interface ResetPassword {
  password: string;
  token: string;
}

export interface SignUpResponse {
  id: string;
  email: string;
}

export interface LoginResponse {
  user: {
    id: string;
    email: string;
    firstName?: string;
    lastName?: string;
  };
  accessToken: string;
}

export interface LoginFailedResponse {
  user: {
    id: string;
    email: string;
  };
  messageCode: string;
}

export enum UserRegistrationProgressStep {
  EMAIL_TO_VERIFY = 'EMAIL_TO_VERIFY',
  PROFILE_PART_ONE_PROFILE_TO_COMPLETE = 'PROFILE_PART_ONE_PROFILE_TO_COMPLETE',
  PROFILE_PART_TWO_PROFILE_TO_COMPLETE = 'PROFILE_PART_TWO_PROFILE_TO_COMPLETE',
}

export const GenderLabels = {
  [Gender.FEMALE]: 'une femme',
  [Gender.MALE]: 'un homme',
  [Gender.OTHER]: 'autre',
};

const signUp = async ({ email, password }: AuthCredentials) => {
  const signUpResponse = await axios.post(`${usersApiUrl}/sign-up`, {
    email,
    password,
  });

  return signUpResponse.data as SignUpResponse;
};

const registerVerifyEmail = async ({ userId, code }: VerifyUserAccountParams) => {
  const registerVerifyEmailResponse = await axios.post(`${usersApiUrl}/sign-up/${userId}/verify-email`, { code });

  return registerVerifyEmailResponse.data.success === "true";
};

const registerProfileStepOne = async ({ gender, firstName, lastName, phoneNumber }: RegisterProfileStepOne, userId?: string) => {
  const registerProfileResponse = await axios.post(`${usersApiUrl}/${userId}/profile/step/one`, {
    gender,
    firstName,
    lastName,
    phoneNumber,
  });

  return registerProfileResponse.data;
};

const registerProfileStepTwo = async ({ birthdate, nationality, addressCountry }: RegisterProfileStepTwo, userId?: string) => {
  const birthdateFormatted = moment(birthdate).format('YYYY-MM-DDT00:00:00.000Z');

  const registerProfileResponse = await axios.post(`${usersApiUrl}/${userId}/profile/step/two`, {
    birthdate: birthdateFormatted, nationality, addressCountry 
  });

  return registerProfileResponse.data;
};

const saveKycInfo = async ({ idDocumentIssuingCountry, idDocumentNumber, idDocumentType }: SaveKycInfo, userId?: string) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  await axios.post(`${usersApiUrl}/${userId}/kyc/info`, {
    idDocumentIssuingCountry, idDocumentNumber, idDocumentType
  }, { headers: authHeaderData });
};

const sendKycImages = async (imagesData: any, userId?: string): Promise<KycJobResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const responseSendKycImages = await axios.post(
    `${usersApiUrl}/${userId}/kyc/images`,
    imagesData,
    { headers: authHeaderData }
  );

  return responseSendKycImages.data;
};

const login = async ({ email, password }: AuthCredentials): Promise<LoginResponse> => {
  const loginResponse = await axios.post(`${usersApiUrl}/login`, { email, password });
  if (loginResponse.data.accessToken) {
    localStorage.setItem("user", JSON.stringify(loginResponse.data))
  }

  return loginResponse.data;
};

const registerResendEmailConfirmationCode = async (userId?: string) => {
  await axios.post(`${usersApiUrl}/sign-up/${userId}/resend-email-confirmation-code`);
};

const sendResetPasswordLink = async (email: string) => {
  await axios.post(`${usersApiUrl}/passwords/reset/send-link`, { email });
};

const resetPassword = async ({password, token }: ResetPassword) => {
  await axios.post(`${usersApiUrl}/passwords/reset`, { password, token });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  signUp,
  registerProfileStepOne,
  registerProfileStepTwo,
  login,
  logout,
  registerVerifyEmail,
  registerResendEmailConfirmationCode,
  sendResetPasswordLink,
  resetPassword,
  saveKycInfo,
  sendKycImages
};

export default authService;