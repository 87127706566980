import { useEffect, useState } from 'react';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import { useNavigate, Link } from 'react-router-dom';
import { useAppSelector } from '../store';
import userService from '../services/user.service';
import LoadingBackground from '../components/LoadingBackground';
import LoadingSpinner from '../components/LoadingSpinner';
import moment from 'moment';
import { Field, Form, Formik } from 'formik';
import { Gender, GenderLabels } from '../services/auth.service';
import { FIELD_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import * as Yup from 'yup';
import MailtoLink from '../components/MailtoLink';

interface FormValues {
  gender: Gender | '',
  email: string;
  firstName: string;
  lastName: string;
  phoneNumberValue: string;
  nationalityCountry: string;
  addressCountry: string;
  birthdate: string;
}

const PersonalInformations = (props: any) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfos, setUserInfos] = useState<any>();

  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  
  const fetchUserInfos = async (userId: string) => {
    setLoading(true);
    const getUserInfos = await userService.getUser(userId as string);
    setUserInfos(getUserInfos);
    setLoading(false);
  }

  useEffect(() => {
    fetchUserInfos(user?.user.id as string);
  },[navigate, user?.user.id])

  const initialValues: FormValues = {
    gender: userInfos?.userProfile?.gender ?? '',
    email: userInfos?.email ?? '',
    firstName: userInfos?.userProfile?.firstName ?? '',
    lastName: userInfos?.userProfile?.lastName ?? '',
    phoneNumberValue: userInfos?.userProfile?.phoneNumberValue ?? '',
    nationalityCountry: userInfos?.userProfile?.nationalityCountry.name ?? '',
    addressCountry: userInfos?.userProfile?.userAddress?.country?.name ?? '',
    birthdate: userInfos?.userProfile?.birthdate ? moment(userInfos?.userProfile?.birthdate).format('DD/MM/YYYY') : '',
  };

  const handleSubmit = async (values: FormValues) => {
  };

  const validationSchema = Yup.object({
    gender: Yup.mixed<Gender>().oneOf(Object.values(Gender)).required(FIELD_REQUIRED),
  });

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          {loading && (
            <><LoadingBackground /><LoadingSpinner /></>
          )}
          {!loading && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="px-4 py-8 w-full max-w-6xl mx-auto">
                  <div className="bg-white shadow-lg rounded-sm">
                    <header className="px-5 py-4">
                      <h2 className="text-2xl font-semibold text-amber-500 text-center">Mes informations personnelles</h2>
                    </header>
                      <div className="px-6 md:px-14 py-4">
                        <div className="flex flex-wrap mb-2">
                          
                          {userInfos?.userRegistrationProgress?.isKycCompleted && (<>
                            <span className="text-lg font-semibold mr-1">Compte vérifié</span>
                              <div className="rounded-full bg-green-500" aria-hidden="true">
                                <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 20 20">
                                  <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z" />
                                </svg>
                              </div>
                            </>
                          )}
                          {!userInfos?.userRegistrationProgress?.isKycCompleted && (<>
                            <span className="text-lg font-semibold mr-1">Compte non vérifié</span>
                              <div className="w-6 h-6 rounded-full bg-red-500 flex" aria-hidden="true">
                                <svg className="fill-current text-white" viewBox="0 0 24 24" stroke="currentColor">
                                  <path d="M18 6l-12 12"></path>
                                  <path d="M6 6l12 12"></path>
                                </svg>
                              </div>
                            </>
                          )}
                        </div>

                        {!userInfos?.userRegistrationProgress?.isKycCompleted && (
                          <div>
                            Afin de garantir la sécurité et la conformité de votre compte, il est essentiel de vérifier vos informations personnelles.
                            <div className="text-center mt-3">
                              <Link to={'/account/verification'}>
                                <button className="btn md:w-1/3 w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Vérifiez mon compte</button>
                              </Link>
                            </div>  
                          </div>
                        )}

                      </div>
                      <div className="flex flex-wrap px-3 md:px-10 py-4">
                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="gender">Je suis</label>
                          <div role="group" className="flex flex-wrap items-center -m-3">
                            {Object.values(Gender).map((gender) => (
                                <div className="m-3" key={gender}>
                                  <label key={gender} className="flex items-center">
                                    <Field disabled readonly type="radio" name="gender" className="form-radio bg-gray-100 cursor-not-allowed" value={gender} />
                                    <span className="text-base ml-2">{GenderLabels[gender]}</span>
                                  </label>
                                </div>
                              ))}
                          </div>
                              
                        </div>
                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="firstName">Email</label>
                          <Field disabled readonly name="email" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="email" placeholder="Saisissez votre adresse e-mail"/>
                        </div>
                        
                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="firstName">Prénom</label>
                          <Field disabled readonly name="firstName" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text" placeholder="Saisissez votre prénom"/>
                        </div>

                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="lastName">Nom</label>
                          <Field disabled readonly name="lastName" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text" placeholder="Saisissez votre nom"/>
                        </div>
                        
                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="phoneNumberValue">Numéro de téléphone</label>
                          <Field disabled readonly name="phoneNumberValue" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text" placeholder="XXXXXXXX"/>
                        </div>

                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="nationality">Date de naissance</label>
                          <Field disabled readonly name="birthdate" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text"/>
                        </div>

                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="nationality">Pays de la nationalité</label>
                          <Field disabled readonly name="nationalityCountry" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text" placeholder="Sélectionnez votre nationalité"/>
                        </div>

                        <div className="w-full lg:w-1/2 mb-4 px-4">
                          <label className="block text-base font-medium mb-1" htmlFor="addressCountry">Pays de résidence</label>
                          <Field disabled readonly name="addressCountry" className="form-input w-full h-16 text-base bg-gray-100 cursor-not-allowed" type="text" placeholder="Sélectionnez votre pays de résidence"/>
                        </div>

                      </div>

                      <div className='px-6 md:px-14 pb-4'>
                        <div className='font-semibold'>Besoin de mettre à jour votre profil ?</div> Veuillez envoyer une demande à notre équipe de support à l'adresse suivante <MailtoLink email={'support@swapier.co'} title={'support@swapier.co'} className="text-base font-medium text-amber-500 hover:text-amber-600"></MailtoLink>.
                      </div>
                  </div>
                </div>
              </Form>
            </Formik>
          )}
        </main>

      </div>

    </div>
  );
}

export default PersonalInformations;