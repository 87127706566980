import axios from "axios";
import authHeader from "../utils/helpers/auth-header";
import { ExchangeResponse, PaymentOrderExchangeResponse } from "./exchange.service";

const paymentOrdersApiUrl = `${process.env.REACT_APP_SWAPIER_SERVER_URL}/payment-orders`;

export enum PaymentMethodFeeSource {
  TRADING_CURRENCY_PAIRS_PROCESSING = 'TRADING_CURRENCY_PAIRS_PROCESSING',
  BLOCKCHAIN_NETWORK = 'BLOCKCHAIN_NETWORK',
  MOBILE_MONEY_OPERATOR = 'MOBILE_MONEY_OPERATOR',
  DIGITAL_WALLET = 'DIGITAL_WALLET',
}

export enum PaymentProvider {
  Hub2 = 'HUB2',
  Kkiapay = 'KKIAPAY',
  Paydunya = 'PAYDUNYA',
  Fireblocks = 'FIREBLOCKS',
  Swapzone = 'SWAPZONE',
}


interface FeeAmountDescriptionResponse {
  amount: string;
  source: PaymentMethodFeeSource;
}

export interface UpdatePaymentOrderExternalPaymentInfoParams {
  paymentOrderId: string;
  externalPaymentId: string;
  provider: PaymentProvider;
}

export interface AmountDetailsResponse {
  sendAmount?: string;
  sendCurrencyCode?: string;
  sendFeeAmounts: FeeAmountDescriptionResponse[];
  receiveAmountInSendCurrency?: string;
  receiveCurrencyToSendCurrencyRate: string;
  receiveFeeAmountsInSendCurrency: FeeAmountDescriptionResponse[];
  receiveAmount?: string;
  receiveCurrencyCode?: string;
  receiveFeeAmounts: FeeAmountDescriptionResponse[];
}

export interface GetAmountDetailsFilters {
  sendPaymentMethodId: string;
  receivePaymentMethodId: string;
  sendAmount?: number;
  receiveAmount?: number;
}

export enum KkiapayPaymentStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export interface CreateHub2PaymentIntentResponse {
  id: string;
  token: string;
}

export interface CreateHub2PaymentIntentsDto {
  purchaseReference: string;
  amount: number;
  currency: string;
}

export interface CreatePaydunyaCheckoutInvoiceDto {
  amount: number;
  description: string;
  exchangeId: string;
  paymentOrderId: string;
}

export interface CreatePaydunyaCheckoutInvoiceResponse {
  token: string;
}

const getAmountDetails = async (getAmountDetailsFilters: GetAmountDetailsFilters): Promise<AmountDetailsResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const paymentMethods = await axios.get(`${paymentOrdersApiUrl}/amount-details`, { headers: authHeaderData, params: getAmountDetailsFilters  });

  return paymentMethods.data;
}

const createHub2PaymentIntents = async (paymentOrder: PaymentOrderExchangeResponse): Promise<CreateHub2PaymentIntentResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const createHub2PaymentIntentsDto: CreateHub2PaymentIntentsDto = {
    purchaseReference: paymentOrder.id,
    amount: paymentOrder.amount,
    currency: paymentOrder.currency,
  };

  const paymentIntentsResponse = await axios.post(`${paymentOrdersApiUrl}/hub2/payment-intents`, createHub2PaymentIntentsDto, { headers: authHeaderData });

  return paymentIntentsResponse.data;
}

const updateExternalPaymentInfos = async (updatePaymentOrderExternalPaymentInfoParams: UpdatePaymentOrderExternalPaymentInfoParams) => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  await axios.post(`${paymentOrdersApiUrl}/external-payment-infos`, updatePaymentOrderExternalPaymentInfoParams, { headers: authHeaderData });
}

const createPaydunyaCheckoutInvoice = async (exchange: ExchangeResponse, paymentOrder: PaymentOrderExchangeResponse): Promise<CreatePaydunyaCheckoutInvoiceResponse> => {
  const authHeaderData = authHeader();
  if (!authHeaderData) {
    throw new Error('Veuillez vous connecter');
  }

  const createPaydunyaCheckoutInvoiceDto: CreatePaydunyaCheckoutInvoiceDto = {
    amount: paymentOrder.amount,
    description: `Votre paiement pour la transaction n°${exchange.reference}`,
    exchangeId: exchange.id,
    paymentOrderId: paymentOrder.id,
  };

  const paydunyaCheckoutInvoiceResponse = await axios.post(`${paymentOrdersApiUrl}/paydunya/checkout-invoice`, createPaydunyaCheckoutInvoiceDto, { headers: authHeaderData });

  return paydunyaCheckoutInvoiceResponse.data;
}

const paymentOrderService = {
  getAmountDetails,
  createHub2PaymentIntents,
  updateExternalPaymentInfos,
  createPaydunyaCheckoutInvoice
};

export default paymentOrderService;