interface NationalityByCountryCode {
  [key: string]: string;
}

export const nationalityMapping: NationalityByCountryCode = {
  CI: "Ivoirien(ne)",
  DZ: "Algérien(ne)",
  AO: "Angolais(e)",
  BJ: "Béninois(e)",
  BW: "Botswanais(e)",
  BF: "Burkinabè",
  BI: "Burundais(e)",
  CM: "Camerounais(e)",
  CV: "Cap-verdien(ne)",
  CF: "Centrafricain(e)",
  TD: "Tchadien(ne)",
  KM: "Comorien(ne)",
  CG: "Congolais(e)",
  CD: "Congolais(e)",
  DJ: "Djiboutien(ne)",
  EG: "Égyptien(ne)",
  GQ: "Équatoguinéen(ne)",
  ER: "Érythréen(ne)",
  ET: "Éthiopien(ne)",
  GA: "Gabonais(e)",
  GM: "Gambien(ne)",
  GH: "Ghanéen(ne)",
  GN: "Guinéen(ne)",
  GW: "Bissau-Guinéen(ne)",
  KE: "Kényan(e)",
  LS: "Lesothan(e)",
  LR: "Libérien(ne)",
  LY: "Libyen(ne)",
  MG: "Malgache",
  MW: "Malawite",
  ML: "Malien(ne)",
  MR: "Mauritanien(ne)",
  MU: "Mauricien(ne)",
  MA: "Marocain(e)",
  MZ: "Mozambicain(e)",
  NA: "Namibien(ne)",
  NE: "Nigérien(ne)",
  NG: "Nigérian(e)",
  RW: "Rwandais(e)",
  ST: "Santoméen(ne)",
  SN: "Sénégalais(e)",
  SC: "Seychellois(e)",
  SL: "Sierra-léonais(e)",
  SO: "Somalien(ne)",
  ZA: "Sud-Africain(e)",
  SS: "Sud-Soudanais(e)",
  SD: "Soudanais(e)",
  SZ: "Swazi(e)",
  TZ: "Tanzanien(ne)",
  TG: "Togolais(e)",
  TN: "Tunisien(ne)",
  UG: "Ougandais(e)",
  ZM: "Zambien(ne)",
  ZW: "Zimbabwéen(ne)",
  US: "Américain(e)",
  GB: "Britannique",
  CA: "Canadien(ne)",
  FR: "Français(e)",
  DE: "Allemand(e)",
  IT: "Italien(ne)",
  ES: "Espagnol(e)",
  PT: "Portugais(e)",
  NL: "Néerlandais(se)",
  BE: "Belge",
  CH: "Suisse",
  SE: "Suédois(e)",
  NO: "Norvégien(ne)",
  DK: "Danois(e)",
  FI: "Finlandais(e)",
  RU: "Russe",
  CN: "Chinois(e)",
  JP: "Japonais(e)",
  KR: "Sud-Coréen(ne)",
  IN: "Indien(ne)",
  AU: "Australien(ne)",
  NZ: "Néo-Zélandais(e)",
  MX: "Mexicain(e)",
  BR: "Brésilien(ne)",
  AF: "Afghan(e)",
  AL: "Albanais(e)",
  AM: "Arménien(ne)",
  AT: "Autrichien(ne)",
  AZ: "Azerbaïdjanais(e)",
  BH: "Bahreïni(en(ne))",
  BD: "Bangladais(e)",
  BY: "Biélorusse",
  BT: "Bhoutanais(e)",
  BA: "Bosniaque",
  BG: "Bulgare",
  HR: "Croate",
  CY: "Chypriote",
  CZ: "Tchèque",
  EE: "Estonien(ne)",
  GE: "Géorgien(ne)",
  GR: "Grec(que)",
  HU: "Hongrois(e)",
  IS: "Islandais(e)",
  ID: "Indonésien(ne)",
  IR: "Iranien(ne)",
  IQ: "Irakien(ne)",
  IE: "Irlandais(e)",
  IL: "Israélien(ne)",
  KZ: "Kazakh",
  KW: "Koweïtien(ne)",
  KG: "Kirghize",
  LV: "Letton(ne)",
  LB: "Libanais(e)",
  LT: "Lituanien(ne)",
  LU: "Luxembourgeois(e)",
  MK: "Macédonien(ne)",
  MY: "Malaisien(ne)",
  MV: "Maldivien(ne)",
  MT: "Maltais(e)",
  MD: "Moldave",
  MC: "Monégasque",
  MN: "Mongol(e)",
  ME: "Monténégrin(e)",
  MM: "Myanmarais(e)",
  NP: "Népalais(e)",
  OM: "Omanais(e)",
  PK: "Pakistanais(e)",
  PS: "Palestinien(ne)",
  PH: "Philippin(e)",
  PL: "Polonais(e)",
  PR: "Portoricain(e)",
  QA: "Qatari(en(ne))",
  RO: "Roumain(e)",
  SM: "Saint-Marinais(e)",
  SA: "Saoudien(ne)",
  RS: "Serbe",
  SG: "Singapourien(ne)",
  SK: "Slovaque",
  SI: "Slovène",
  LK: "Sri-lankais(e)",
  SY: "Syrien(ne)",
  TW: "Taïwanais(e)",
  TJ: "Tadjik",
  TH: "Thaïlandais(e)",
  TT: "Trinidadien(ne) et Tobagonien(ne)",
  TR: "Turc(que)",
  TM: "Turkmène",
  UA: "Ukrainien(ne)",
  AE: "Émirati(en(ne))",
  UY: "Uruguayen(ne)",
  UZ: "Ouzbek",
  VE: "Vénézuélien(ne)",
  VN: "Vietnamien(ne)",
  YE: "Yéménite",
};