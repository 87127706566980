export const FIELD_REQUIRED = 'Ce champ est obligatoire';
export const EMAIL_INVALID = 'Veuillez entrer une adresse e-mail au format yourname@example.com ';
export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH_REQUIRED = 'Votre mot de passe doit contenir au minimum 6 caractères';
export const PASSWORD_MAX_LENGTH_REQUIRED = 'Votre mot de passe doit contenir au maximum 30 caractères';
export const PASSWORD_FORMAT_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&.]+$/;
export const INVALID_PASSWORD_FORMAT = 'Votre mot de passe doit contenir au moins un chiffre, une lettre minuscule et une lettre majuscule';
export const FIELD_POSITIVE_NUMBER = 'Veuillez saisir un montant positif';
export const AMOUNT_INTEGER_REQUIRED = 'Veuillez saisir un montant entier, sans decimale';
export const AMOUNT_MULTIPLE_OF_REQUIRED = 'Veuillez saisir un montant multiple de';
export const MIN_AMOUNT_REQUIRED = 'Le montant minimum est';
export const MAX_AMOUNT_REQUIRED = 'Le montant maximum est';
export const NUMBER_REQUIRED = 'Veuillez entrer des chiffres ';
export const CODE_CONFIRMATION_LENGTH_REQUIRED = 'Le code doit contenir exactement 6 chiffres. Veuillez vérifier et réessayer';
export const CODE_CONFIRMATION_TYPE_REQUIRED = 'Le code doit contenir que des chiffres. Veuillez vérifier et réessayer';
export const PHONE_NUMBER_MIN_LENGTH_REQUIRED = 'Le numéro de téléphone doit contenir au moins 10 chiffres';
export const PHONE_NUMBER_MAX_LENGTH_REQUIRED = 'Le numéro de téléphone doit contenir au plus 15 chiffres';
export const PHONE_NUMBER_MIN_LENGTH = 10;
export const PHONE_NUMBER_MAX_LENGTH = 15;
export const BIRTHDATE_DATE_INVALID = 'Veuillez entrer une date au format JJ/MM/AAAA';



