import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CryptoCurrency from '../assets/images/crypto-currency.jpg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../store';
import { FIELD_REQUIRED, INVALID_PASSWORD_FORMAT, PASSWORD_MAX_LENGTH_REQUIRED, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED, PASSWORD_FORMAT_REGEX } from '../utils/constants/form-field-invalid-messages.constants';
import { clearMessage } from '../store/message/message.slice';
import { resetPassword } from '../store/authentication/auth.actions';
import Logo from '../assets/images/logo.png';

interface FormValues {
  password: string;
}

const ResetPassword = (props: any) => {
  const [successful, setSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { message } = useAppSelector(state => state.message);

  const [queryParameters] = useSearchParams();
  const resetPasswordToken = queryParameters.get('reset_password_token') ?? '';

  const initialValues: FormValues = {
    password: '',
  };

  const handleSubmit = async (values: FormValues) => {
    const { password } = values;
    setSuccessful(false);
    setLoading(true)
    try {
      await dispatch(resetPassword({ password, token: resetPasswordToken })).unwrap();
      setSuccessful(true);
      setLoading(false);
    } catch (err) {
      setSuccessful(false);
      setLoading(false);
    }
  };

  const validationSchema = Yup.object({
    password: Yup.string().required(FIELD_REQUIRED)
    .min(PASSWORD_MIN_LENGTH, PASSWORD_MIN_LENGTH_REQUIRED)
    .max(PASSWORD_MAX_LENGTH, PASSWORD_MAX_LENGTH_REQUIRED)
    .matches(PASSWORD_FORMAT_REGEX, INVALID_PASSWORD_FORMAT),
  });


  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);
  
  return (
    <main className="bg-white">

      <div className="relative md:flex">

        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">

            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center" src={Logo} width="110" height="70" alt="Logo" />
                </Link>
              </div>
            </div>

           {!successful && ( 
            <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Choisissez un nouveau mot de passe</h1>
              <div className="text-base mb-6">
                Votre nouveau mot de passe doit être suffisamment sécurisé et facile à retenir.
              </div>
              {/* Form */}

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="space-y-8">
                    <div>
                      <label className="block text-base font-medium mb-1" htmlFor="password">Nouveau mot de passe</label>
                      <Field name="password" className="form-input w-full h-16 text-base" type="password" placeholder="Saisissez nouveau mot de passe" autoComplete="on" />
                      <div className="text-base mt-1 text-rose-500"><ErrorMessage name="password"/></div>
                      <div className="text-xs">Veuillez entrer un mot de passe comportant au moins <span className="text-amber-500">6 caractères</span>, comprenant au moins <span className="text-amber-500">un chiffre</span>, <span className="text-amber-500">une lettre minuscule</span> et <span className="text-amber-500">une lettre majuscule</span>.</div>
                    </div>
                  </div>
                  <div className="mt-6">
                    {message && (
                      <div className="text-center mt-4">
                        <div className="text-base mt-1 text-rose-500">{ message }</div>
                      </div>
                    )}

                    <div className="text-center mt-4">
                      <button type="submit" disabled={loading} className="btn w-full h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Confirmer</button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
           )}

           {successful && (
            <div className="max-w-sm mx-auto px-2 py-6">
              <h1 className="text-4xl text-slate-800 font-bold mb-6 text-center">Votre mot de passe a été réinitialisé</h1>
              <div className="text-lg text-center">
                C'est bon ! Vous pouvez utiliser votre nouveau mot de passe pour vous connecter dès maintenant.
              </div>
              <div className="text-center mt-4">
                <Link to={'/login'}>
                  <button className="btn w-full text-base bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Connectez-vous</button>
                </Link>
              </div>
            </div>
          )}

          </div>
        </div>

        {/* Image */}
        <div className="hidden justify-center items-center md:w-1/2 md:flex" aria-hidden="true">
          <img className="max-w-full max-h-[512px]" src={CryptoCurrency} alt="Cryptomonnaies" />
        </div>

      </div>

    </main>
  );
}

export default ResetPassword;