import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../store/authentication/auth.actions';
import { useAppDispatch } from '../store';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout()).unwrap();
    navigate("/login", { replace: true });
  }, [dispatch, navigate]);

  return (<></>);
}

export default Logout;