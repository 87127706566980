import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, FieldAttributes, Form, Formik, useField, useFormikContext } from 'formik';
import Sidebar from './partials/Sidebar';
import Header from './partials/Header';
import * as Yup from 'yup';
import { AMOUNT_MULTIPLE_OF_REQUIRED, FIELD_POSITIVE_NUMBER, FIELD_REQUIRED, MAX_AMOUNT_REQUIRED, MIN_AMOUNT_REQUIRED } from '../utils/constants/form-field-invalid-messages.constants';
import paymentMethodService, { PaymentMethod, PaymentTypeCategory, GetPaymentMethodFilters, PaymentMethodTradingPair } from '../services/payment-method.service';
import { AxiosError } from 'axios';
import paymentOrderService, { AmountDetailsResponse, GetAmountDetailsFilters, PaymentMethodFeeSource } from '../services/payment-order.service';
import { useNavigate } from 'react-router-dom';
import AccordionBasic from '../components/AccordionBasic';
import userService from '../services/user.service';
import exchangeService from '../services/exchange.service';
import LoadingSpinner from '../components/LoadingSpinner';
import LoadingBackground from '../components/LoadingBackground';
import { useAppSelector } from '../store';

interface ItemFormValues {
  paymentMethod: string;
  amount: number | '';
  availablePaymentMethods: PaymentMethod[];
  validateAmount: void | null;
  validateUserInfos: void | null;
  userInfos: {
    value: string;
    label: string;
  };
}

interface FormValues {
  send: ItemFormValues;
  receive: ItemFormValues;
  amountDetails: AmountDetailsResponse | null;
  paymentMethodTradingPair: PaymentMethodTradingPair | null;
}

const serviceAvailableCountryCodes = process.env.REACT_APP_SWAPIER_SERVICE_AVAILABLE_COUNTRY_CODES?.split(',') ?? [];

console.log('-- serviceAvailableCountryCodes --', serviceAvailableCountryCodes);

const Exchange = (props: any) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [receiptUserInfosErrorMessage, setReceiptUserInfosErrorMessage] = useState<string | null>(null);
  const [isServiceAvailableIntoCountry, setIsServiceAvailableIntoCountry] = useState<boolean>(true); 

  const sendAmountRef = useRef<HTMLInputElement>(null);
  const receiveAmountRef = useRef<HTMLInputElement>(null);
  const sendPaymentMethodRef = useRef<HTMLSelectElement>(null);
  const receivePaymentMethodRef = useRef<HTMLSelectElement>(null);


  const navigate = useNavigate();

  const user = useAppSelector(state => state.auth.user);
  const userCountryCodeId = user?.user?.residenceCountryCode ?? '';

  const roundTo2Decimals = (amount: number) => {
    return Math.round(amount * 100) / 100
  };

  const validateAmount = (minAmount?: number | null, maxAmount?: number | null, multipleOf?: number | null) => (amount: number) =>{
    const checkMultipleOfValidateAmountRes = checkMultipleOfValidateAmount(multipleOf)(amount);
    if (checkMultipleOfValidateAmountRes) {
      return checkMultipleOfValidateAmountRes;
    }

    const checkMinAmountRes = checkMinAmount(minAmount)(amount);
    if (checkMinAmountRes) {
      return checkMinAmountRes;
    }

    const checkMaxAmountRes = checkMaxAmount(maxAmount)(amount);
    if (checkMaxAmountRes) {
      return checkMaxAmountRes;
    }
  }

  const checkMultipleOfValidateAmount = (multipleOf?: number | null) => (amount: number) => {
    if (multipleOf && amount%multipleOf !== 0) {
      return AMOUNT_MULTIPLE_OF_REQUIRED + ` ${multipleOf}`;
    }

    return;
  }

  const checkMinAmount = (minAmount?: number | null) => (amount: number) => {
    if (minAmount && amount < minAmount) {
      return `${MIN_AMOUNT_REQUIRED} ${minAmount}`;
    }

    return;
  }

  const checkMaxAmount = (maxAmount?: number | null) => (amount: number) => {
    if (maxAmount && amount > maxAmount) {
      return `${MAX_AMOUNT_REQUIRED} ${maxAmount}`;
    }

    return;
  }

  useEffect(() => {
    console.log('-- setIsServiceAvailableIntoCountry --', serviceAvailableCountryCodes.includes(userCountryCodeId));
    setIsServiceAvailableIntoCountry(serviceAvailableCountryCodes.includes(userCountryCodeId));
  }, [userCountryCodeId]);

  const SendAmount = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const sendAmount = values.send.amount;
    const sendPaymentMethod = values.send.paymentMethod;
    const receivePaymentMethod = values.receive.paymentMethod;
    const sendAvailablePaymentMethods = values.send.availablePaymentMethods;
    const receiveAvailablePaymentMethods = values.receive.availablePaymentMethods;
    const paymentMethodTradingPair = values.paymentMethodTradingPair;
    const [field] = useField({ ...props, validate: values.send.validateAmount });

    const isAtLeastOneFieldFocused = sendAmountRef.current === document.activeElement;
    const isAllPaymentMethodsFilled = values.send.paymentMethod && values.receive.paymentMethod;

    useEffect(() => {
      if (isAllPaymentMethodsFilled && sendAmount !== '' && sendAmount > 0 && isAtLeastOneFieldFocused) {
        const fetchAmountDetailsByFilters = async (getAmountDetailsFilters: GetAmountDetailsFilters) => {
          setError(null);
          setLoading(true);
          try {
            const amountDetails = await paymentOrderService.getAmountDetails(getAmountDetailsFilters);
            setFieldValue('receive.amount', amountDetails.receiveAmount);
            setFieldValue('amountDetails', amountDetails);
          } catch (error) {
            setLoading(false);
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
          setLoading(false);
        }
      
        const getAmountDetailsFilters: GetAmountDetailsFilters = {
          sendPaymentMethodId: values.send.paymentMethod,
          receivePaymentMethodId: values.receive.paymentMethod,
          sendAmount,
        };
      
        const timer = setTimeout(() => { 
          fetchAmountDetailsByFilters(getAmountDetailsFilters); 
        }, 1500);
        return () => clearTimeout(timer);
      }
    
    }, [setFieldValue, props.name, sendAmount, values.send.paymentMethod, values.receive.paymentMethod, isAtLeastOneFieldFocused, isAllPaymentMethodsFilled]);

    useEffect(() => {
      if (isAllPaymentMethodsFilled) {
        const sendPaymentMethodInfos = sendAvailablePaymentMethods.filter(paymentMethod => paymentMethod.id === sendPaymentMethod)[0];
        const sendMinAmount = paymentMethodTradingPair?.configuration?.sendAmount?.min;
        const sendMaxAmount = paymentMethodTradingPair?.configuration?.sendAmount?.max;

        setFieldValue('send.validateAmount', validateAmount(sendMinAmount, sendMaxAmount, sendPaymentMethodInfos.multipleOf));

        const receievPaymentMethodInfos = receiveAvailablePaymentMethods.filter(paymentMethod => paymentMethod.id === receivePaymentMethod)[0];
        const receiveMinAmount = paymentMethodTradingPair?.configuration?.receiveAmount?.min;
        const receiveMaxAmount = paymentMethodTradingPair?.configuration?.receiveAmount?.max;
        setFieldValue('receive.validateAmount', validateAmount(receiveMinAmount, receiveMaxAmount, receievPaymentMethodInfos.multipleOf));
      }

    }, [isAllPaymentMethodsFilled, paymentMethodTradingPair, receiveAvailablePaymentMethods, receivePaymentMethod, sendAvailablePaymentMethods, sendPaymentMethod, setFieldValue]);

    return (
      <>
        <input {...props} {...field} ref={sendAmountRef} />
      </>
    );
  };

  const ReceiveAmount = (props: FieldAttributes<any>) => {
    const { values } = useFormikContext<FormValues>();
    const receivePaymentMethod = values.receive.paymentMethod;
    const sendPaymentMethod = values.send.paymentMethod;
    const [field] = useField({ ...props, validate: values.receive.validateAmount });


    const receiveAvailablePaymentMethods = values.receive.availablePaymentMethods;
    const sendAvailablePaymentMethods = values.send.availablePaymentMethods;
    const receivePaymentMethodInfos = receiveAvailablePaymentMethods.filter(paymentMethod => paymentMethod.id === receivePaymentMethod)[0];
    const sendPaymentMethodInfos = sendAvailablePaymentMethods.filter(paymentMethod => paymentMethod.id === sendPaymentMethod)[0];

    return (
      <>
        <input {...props} {...field} ref={receiveAmountRef} />
        { (PaymentTypeCategory.MobileMoney === sendPaymentMethodInfos?.paymentType?.category && PaymentTypeCategory.CryptoCurrency === receivePaymentMethodInfos?.paymentType?.category) && (
          <div className="text-xs text-slate-500">En raison des fluctuations du marché, le montant final que vous percevrez peut varier</div>
        )}
        { (PaymentTypeCategory.CryptoCurrency === sendPaymentMethodInfos?.paymentType?.category && PaymentTypeCategory.MobileMoney === receivePaymentMethodInfos?.paymentType?.category) && (
          <div className="text-xs text-slate-500">En raison des fluctuations du marché, le montant final que vous percevrez peut varier</div>
        )}
      </>
    );
  };
  
  const SendPaymentMethodField = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const [field] = useField(props);
    const receivePaymentMethod = values.receive.paymentMethod;
    const sendPaymentMethod = values.send.paymentMethod;
    const sendAmount = values.send.amount;
    const receiveAmount = values.receive.amount;
    const sendAvailablePaymentMethods = values.send.availablePaymentMethods;
  
    useEffect(() => {
      if (receivePaymentMethod.trim() !== '') {
        const fetchPaymentMethodsByFilters = async (receivePaymentMethod: string) => {
          try {
            const paymentMethods = await paymentMethodService.getPaymentMethods({
              receivePaymentMethodSelectedId: receivePaymentMethod,
              countryCodeId: userCountryCodeId,
            });
            setFieldValue('send.availablePaymentMethods', paymentMethods);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
      
        fetchPaymentMethodsByFilters(receivePaymentMethod);
      }

    }, [receivePaymentMethod, setFieldValue]);
  
    useEffect(() => {
      if (sendPaymentMethod === '') {
        const fetchAllPaymentMethods = async () => {
          try {
            const paymentMethods = await paymentMethodService.getPaymentMethods({
              countryCodeId: userCountryCodeId,
            });
            setFieldValue('send.availablePaymentMethods', paymentMethods);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
        
        fetchAllPaymentMethods();
      }

    }, [sendPaymentMethod, setFieldValue]);

    useEffect(() => {
      const isAllPaymentMethodsFilled = sendPaymentMethod && receivePaymentMethod;
      const isSendPaymentMethodFocused = sendPaymentMethodRef.current === document.activeElement;
      const isAtLeastOneAmountFilled = sendAmount || receiveAmount;
  
      if (isAllPaymentMethodsFilled && isAtLeastOneAmountFilled && isSendPaymentMethodFocused) {
        const fetchAmountDetailsByFilters = async (getAmountDetailsFilters: GetAmountDetailsFilters) => {
          setError(null);
          setLoading(true);
          try {
            const amountDetails = await paymentOrderService.getAmountDetails(getAmountDetailsFilters);
            setFieldValue('receive.amount', amountDetails.receiveAmount);
            setFieldValue('send.amount', amountDetails.sendAmount);
            setFieldValue('amountDetails', amountDetails);
          } catch (error) {
            setLoading(false);
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
          setLoading(false);
        }
      
        const getAmountDetailsFilters: GetAmountDetailsFilters = {
          sendPaymentMethodId: sendPaymentMethod,
          receivePaymentMethodId: receivePaymentMethod,
          sendAmount: sendAmount || undefined,
          receiveAmount: receiveAmount || undefined,
        };
      
        fetchAmountDetailsByFilters(getAmountDetailsFilters);
      }

    }, [receiveAmount, receivePaymentMethod, sendAmount, sendPaymentMethod, setFieldValue]);

    useEffect(() => {
      const isAllPaymentMethodsFilled = sendPaymentMethod && receivePaymentMethod;
  
      if (isAllPaymentMethodsFilled) {
        const fetchPaymentMethodTradingPairsSelected = async (getPaymentMethodFilters: GetPaymentMethodFilters) => {
          try {
            const paymentMethodTradingPairSelected = await paymentMethodService.getPaymentMethodTradingPairs(getPaymentMethodFilters);
            setFieldValue('paymentMethodTradingPair', paymentMethodTradingPairSelected.length > 0 ? paymentMethodTradingPairSelected[0] : null);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
      
        const getAmountDetailsFilters: GetPaymentMethodFilters = {
          sendPaymentMethodSelectedId: sendPaymentMethod,
          receivePaymentMethodSelectedId: receivePaymentMethod,
        };
      
      }

    }, [receivePaymentMethod, sendPaymentMethod, setFieldValue]);

    return (
      <>
        <select {...props} {...field} ref={sendPaymentMethodRef}>
          <option value="" disabled>Sélectionner</option>
          {sendAvailablePaymentMethods.length > 0 && sendAvailablePaymentMethods.map((paymentMethod, key) => {
            return <option key={key} value={paymentMethod.id}>{paymentMethod.paymentType.name} {paymentMethod?.country ? ` - ${paymentMethod?.country?.isoCode3}` : ''} ({paymentMethod.currencyCode}{paymentMethod?.cryptoTokenStandard ? `${paymentMethod?.cryptoTokenStandard}` : ''})</option>
          })}
        </select>
      </>
    );
  };

  const ReceivePaymentMethodField = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const [field] = useField(props);
    const receivePaymentMethod = values.receive.paymentMethod;
    const sendPaymentMethod = values.send.paymentMethod;
    const sendAmount = values.send.amount;
    const receiveAmount = values.receive.amount;
    const receiveAvailablePaymentMethods = values.receive.availablePaymentMethods;
  
    useEffect(() => {
      if (sendPaymentMethod.trim() !== '') {
        const fetchPaymentMethodsByFilters = async (sendPaymentMethod: string) => {
          try {
            const paymentMethods = await paymentMethodService.getPaymentMethods({
              sendPaymentMethodSelectedId: sendPaymentMethod,
              countryCodeId: userCountryCodeId,
            });
            setFieldValue('receive.availablePaymentMethods', paymentMethods);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
      
        fetchPaymentMethodsByFilters(sendPaymentMethod);
      }

    }, [sendPaymentMethod, setFieldValue]);

    useEffect(() => {
      if (receivePaymentMethod === '') { 
        const fetchAllPaymentMethods = async () => {
          try {
            const paymentMethods = await paymentMethodService.getPaymentMethods({
              countryCodeId: userCountryCodeId,
            });
            setFieldValue('receive.availablePaymentMethods', paymentMethods);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
      
        fetchAllPaymentMethods();
      }
    }, [receivePaymentMethod, setFieldValue]);

    useEffect(() => {
      const isAllPaymentMethodsFilled = sendPaymentMethod && receivePaymentMethod;
      const isAtLeastOneAmountFilled = sendAmount || receiveAmount;
      const isReceivePaymentMethodFocused = receivePaymentMethodRef.current === document.activeElement;
    
      if (isAllPaymentMethodsFilled && isAtLeastOneAmountFilled && isReceivePaymentMethodFocused) {
        const fetchAmountDetailsByFilters = async (getAmountDetailsFilters: GetAmountDetailsFilters) => {
          setError(null);
          setLoading(true);
          try {
            const amountDetails = await paymentOrderService.getAmountDetails(getAmountDetailsFilters);
            setFieldValue('receive.amount', amountDetails.receiveAmount);
            setFieldValue('send.amount', amountDetails.sendAmount);
            setFieldValue('amountDetails', amountDetails);
          } catch (error) {
            setLoading(false);
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
          setLoading(false);
        }
      
        const getAmountDetailsFilters: GetAmountDetailsFilters = {
          sendPaymentMethodId: sendPaymentMethod,
          receivePaymentMethodId: receivePaymentMethod,
          sendAmount: sendAmount || undefined,
          receiveAmount: receiveAmount || undefined,
        };
      
        fetchAmountDetailsByFilters(getAmountDetailsFilters);
      }

    }, [receiveAmount, receivePaymentMethod, sendAmount, sendPaymentMethod, setFieldValue]);
  
    useEffect(() => {
      const isAllPaymentMethodsFilled = sendPaymentMethod && receivePaymentMethod;
  
      if (isAllPaymentMethodsFilled) {
        const fetchPaymentMethodTradingPairsSelected = async (getPaymentMethodFilters: GetPaymentMethodFilters) => {
          try {
            const paymentMethodTradingPairSelected = await paymentMethodService.getPaymentMethodTradingPairs(getPaymentMethodFilters);
            setFieldValue('paymentMethodTradingPair', paymentMethodTradingPairSelected.length > 0 ? paymentMethodTradingPairSelected[0] : null);
          } catch (error) {
            if (error instanceof AxiosError && error?.response?.data?.message) {
              setError(error?.response?.data?.message);
            }
          }
        }
      
        const getAmountDetailsFilters: GetPaymentMethodFilters = {
          sendPaymentMethodSelectedId: sendPaymentMethod,
          receivePaymentMethodSelectedId: receivePaymentMethod,
        };
      
        fetchPaymentMethodTradingPairsSelected(getAmountDetailsFilters);
      }

    }, [receivePaymentMethod, sendPaymentMethod, setFieldValue]);
  
    return (
      <>
        <select {...props} {...field} ref={receivePaymentMethodRef}>
          <option value="" disabled>Sélectionnez</option>
          {receiveAvailablePaymentMethods.length > 0 && receiveAvailablePaymentMethods.map((paymentMethod, key) => {
            return <option key={key} value={paymentMethod.id}>{paymentMethod.paymentType.name} {paymentMethod?.country ? ` - ${paymentMethod?.country?.isoCode3}` : ''} ({paymentMethod.currencyCode}{paymentMethod?.cryptoTokenStandard ? `${paymentMethod?.cryptoTokenStandard}` : ''})</option>
          })}
        </select>
      </>
    );
  };

  const getReceiveUserInfosLabelByPaymentMethod = (paymentMethod: PaymentMethod) => {
    const paymentType = paymentMethod.paymentType.category;
    switch (paymentType) {
      case PaymentTypeCategory.CryptoCurrency:
        return 'Adresse de réception de votre portefeuille';
      case PaymentTypeCategory.MobileMoney:
        return 'Numéro de téléphone sans indicatif';
      case PaymentTypeCategory.DigitalWallet:
        return 'Identifiant de votre compte de reception';
      default:
        return 'Informations de réception';
    }
  }

  const validateUserInfos = async (value: string) => {
    if (value === '') {
      return FIELD_REQUIRED;
    }

    // let result;

    // validateUserInfosTimerRef.current = setTimeout(async () => { 
    //     const receiptUserInfosResponse = await userService.validateReceiptUserInfos({ value, paymentMethodId: '0658dbb3-2358-4cf5-a88a-e704fcd6c480' });
    //     console.log('receiptUserInfosResponse', receiptUserInfosResponse);
    //     if (!receiptUserInfosResponse.isValid) {
    //       result = receiptUserInfosResponse?.error?.message;
    //     }
    // }, 2000);

    // console.log('result', result);

    // return result;
  }

  // const validateUserInfos = async (value: string) => {
  //   if (value === '') {
  //     return FIELD_REQUIRED;
  //   }

  //   const receiptUserInfosResponse = await userService.validateReceiptUserInfos({ value, paymentMethodId: '0658dbb3-2358-4cf5-a88a-e704fcd6c480' });
  //   console.log('receiptUserInfosResponse', receiptUserInfosResponse);
  //   if (!receiptUserInfosResponse.isValid) {
  //     return receiptUserInfosResponse?.error?.message;
  //   }
  // }

  const ReceiveUserInfos = (props: FieldAttributes<any>) => {
    const { values, setFieldValue } = useFormikContext<FormValues>();
    const receivePaymentMethod = values.receive.paymentMethod;
    const receiveUserInfosLabel = values.receive.userInfos.label;
    const receiveAvailablePaymentMethods = values.receive.availablePaymentMethods;
    const fieldName = 'receive.userInfos.value';
    const fieldProps = { ...props, name: fieldName, validate: values.receive.validateUserInfos };
    const [field] = useField(fieldProps);

    useEffect(() => {
      if (receivePaymentMethod.trim() !== '') {
        setFieldValue('receive.validateUserInfos', validateUserInfos);
        const receivePaymentMethodInfos = receiveAvailablePaymentMethods.filter(paymentMethod => paymentMethod.id === receivePaymentMethod)[0];
        setFieldValue('receive.userInfos.label', getReceiveUserInfosLabelByPaymentMethod(receivePaymentMethodInfos));
      }
    }, [receiveAvailablePaymentMethods, receivePaymentMethod, setFieldValue]);
  
    if (receivePaymentMethod.trim() === '') {
      return <></>;
    }
  
    return (
      <>
        <label className="block text-xs lg:text-base font-medium mb-1" htmlFor={fieldName}>{receiveUserInfosLabel}</label>
        <input {...props} {...field} name={fieldName}/>
      </>
    );
  };

  const ExchangeSummary = () => {
    const { values } = useFormikContext<FormValues>();
    const amountDetails = values.amountDetails;

    if (!amountDetails) {
      return <></>;
    }

    const sendAmount = amountDetails.sendAmount;
    const receiveAmount = amountDetails.receiveAmount;
    const receiveAmountInSendCurrency = amountDetails.receiveAmountInSendCurrency;
    const receiveCurrencyToSendCurrencyRate = amountDetails.receiveCurrencyToSendCurrencyRate;
  
    const sendCurrencyCode = amountDetails.sendCurrencyCode;
    const receiveCurrencyCode = amountDetails.receiveCurrencyCode; 
  
    const sendMobileMoneyOperatorFee = amountDetails.sendFeeAmounts.filter(sendFeeAmount => sendFeeAmount.source === PaymentMethodFeeSource.MOBILE_MONEY_OPERATOR);
    const sendMobileMoneyOperatorFeeAmount = sendMobileMoneyOperatorFee.length > 0 ? Number(sendMobileMoneyOperatorFee[0].amount) : 0;

    const sendDigitalWalletFee = amountDetails.sendFeeAmounts.filter(sendFeeAmount => sendFeeAmount.source === PaymentMethodFeeSource.DIGITAL_WALLET);
    const sendDigitalWalletFeeAmount = sendDigitalWalletFee.length > 0 ? Number(sendDigitalWalletFee[0].amount) : 0;

    const sendSwapierFee = amountDetails.sendFeeAmounts.filter(sendFeeAmount => sendFeeAmount.source === PaymentMethodFeeSource.TRADING_CURRENCY_PAIRS_PROCESSING);
    const sendSwapierFeeAmount = sendSwapierFee.length > 0 ? Number(sendSwapierFee[0].amount) : 0;
  
    const receiveBlockchainNetworkFeeInSendCurrency = amountDetails.receiveFeeAmountsInSendCurrency.filter(receiveFeeAmount => receiveFeeAmount.source === PaymentMethodFeeSource.BLOCKCHAIN_NETWORK);
    const receiveBlockchainNetworkFeeAmountInSendCurrency = receiveBlockchainNetworkFeeInSendCurrency.length > 0 ? Number(receiveBlockchainNetworkFeeInSendCurrency[0].amount) : 0;
  
    const receiveDigitalWalletFeeInSendCurrency = amountDetails.receiveFeeAmountsInSendCurrency.filter(receiveFeeAmount => receiveFeeAmount.source === PaymentMethodFeeSource.DIGITAL_WALLET);
    const receiveDigitalWalletFeeAmountInSendCurrency = receiveDigitalWalletFeeInSendCurrency.length > 0 ? Number(receiveDigitalWalletFeeInSendCurrency[0].amount) : 0;
  
    const receiveMobileMoneyFeeInSendCurrency = amountDetails.receiveFeeAmountsInSendCurrency.filter(receiveFeeAmount => receiveFeeAmount.source === PaymentMethodFeeSource.MOBILE_MONEY_OPERATOR);
    const receiveMobileMoneyFeeAmountInSendCurrency = receiveMobileMoneyFeeInSendCurrency.length > 0 ? Number(receiveMobileMoneyFeeInSendCurrency[0].amount) : 0;

    const receiveSwapierFee = amountDetails.receiveFeeAmountsInSendCurrency.filter(receiveFeeAmount => receiveFeeAmount.source === PaymentMethodFeeSource.TRADING_CURRENCY_PAIRS_PROCESSING);
    const receiveSwapierFeeAmount = receiveSwapierFee.length > 0 ? Number(receiveSwapierFee[0].amount) : 0;

    const swapierServiceFeeAmount = sendSwapierFeeAmount + receiveSwapierFeeAmount;

    return (
      <div className="bg-white shadow-lg rounded-sm">
        <div className="px-5 py-4 space-y-2">
          
          <div className="text-xl text-amber-500 font-bold">Details</div>
            <AccordionBasic title={"Vous recevez " + receiveAmount + " " + receiveCurrencyCode + " pour " + sendAmount + " " + sendCurrencyCode}>
              <div>
                <ul>
                  <li className="flex items-center justify-between py-1 border-b border-slate-200">
                    <div className="text-sm font-medium">Taux de change* 1 {receiveCurrencyCode}  ≈ {receiveCurrencyToSendCurrencyRate} {sendCurrencyCode} <div className='text-xs'>*Profitez d'un taux de change plus avantageux en augmentant le montant de votre transaction</div></div>
                  </li>
                  <li className="flex items-center justify-between py-1 border-b border-slate-200">
                    <div className="text-sm">{receiveAmount} {receiveCurrencyCode}</div>
                    <div className="text-sm font-medium text-slate-800 ml-2">{receiveAmountInSendCurrency} {sendCurrencyCode}</div>
                  </li>

                  {sendMobileMoneyOperatorFeeAmount > 0 && (
                    <li className="flex items-center justify-between py-1 border-b border-slate-200">
                      <div className="text-sm">Frais du réseau mobile (≈ {roundTo2Decimals(sendMobileMoneyOperatorFeeAmount*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{sendMobileMoneyOperatorFeeAmount} {sendCurrencyCode}</div>
                    </li>
                  )}
        
                  {sendDigitalWalletFeeAmount > 0 && (
                    <li className="flex items-center justify-between py-1 border-b border-slate-200">
                      <div className="text-sm">Frais de transfert du portefeuille électronique (≈ {roundTo2Decimals(sendDigitalWalletFeeAmount*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{sendDigitalWalletFeeAmount} {sendCurrencyCode}</div>
                    </li>
                  )}
  
                  {receiveBlockchainNetworkFeeAmountInSendCurrency > 0 && (
                    <li className="flex items-center justify-between py-1 border-b border-slate-200">
                      <div className="text-sm">Frais de transfert de la blockchain (≈ {roundTo2Decimals(receiveBlockchainNetworkFeeAmountInSendCurrency*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{receiveBlockchainNetworkFeeAmountInSendCurrency} {sendCurrencyCode}</div>
                    </li>
                  )}

                  {receiveDigitalWalletFeeAmountInSendCurrency > 0 && (
                    <li className="flex items-center justify-between py-1 border-b border-slate-200">
                      <div className="text-sm">Frais de transfert du portefeuille électronique (≈ {roundTo2Decimals(receiveDigitalWalletFeeAmountInSendCurrency*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{receiveDigitalWalletFeeAmountInSendCurrency} {sendCurrencyCode}</div>
                    </li>
                  )}

                  {receiveMobileMoneyFeeAmountInSendCurrency > 0 && (
                    <li className="flex items-center justify-between py-1 border-b border-slate-200">
                      <div className="text-sm">Frais du réseau mobile (≈ {roundTo2Decimals(receiveMobileMoneyFeeAmountInSendCurrency*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{receiveMobileMoneyFeeAmountInSendCurrency} {sendCurrencyCode}</div>
                    </li>
                  )}

                  {swapierServiceFeeAmount > 0 && (
                    <li className="flex items-center justify-between py-1">
                      <div className="text-sm">Frais de service (≈ {roundTo2Decimals(swapierServiceFeeAmount*100 / Number(sendAmount)) }%)</div>
                      <div className="text-sm font-medium text-slate-800 ml-2">{swapierServiceFeeAmount} {sendCurrencyCode}</div>
                    </li>
                  )}
                  <li className="flex items-center justify-between py-1">
                    <div className="text-base font-medium">Total</div>
                    <div className="text-sm font-medium text-slate-800 ml-2">{sendAmount} {sendCurrencyCode}</div>
                  </li>
                </ul>
              </div>
            </AccordionBasic>                      
        </div>
      </div>
    )
  }
  
  const initialValues: FormValues = {
    send: {
      paymentMethod: '',
      amount: '',
      availablePaymentMethods: [],
      validateAmount: null,
      validateUserInfos: null,
      userInfos: {
        value: '',
        label: '',
      },
    },
    receive: {
      paymentMethod: '',
      amount: '',
      availablePaymentMethods: [],
      validateAmount: null,
      validateUserInfos: null,
      userInfos: {
        value: '',
        label: '',
      },
    },
    amountDetails: null,
    paymentMethodTradingPair: null,
  };

  const handleExchangeSubmit = async (values: FormValues) => {
    if (user?.user?.isKycCompleted === false) {
      navigate(`/account/verification`);
    }
    try {
      setLoading(true);
      const validateReceiptUserInfosResponse = await userService.validateReceiptUserInfos({ value: values.receive.userInfos.value, paymentMethodId: values.receive.paymentMethod });
      if (!validateReceiptUserInfosResponse.isValid) {
        setReceiptUserInfosErrorMessage(validateReceiptUserInfosResponse?.error?.message ?? 'Une erreur est survenue');
        setLoading(false);
        return;
      } else {
        // TODO: set null also when user focus on input
        setReceiptUserInfosErrorMessage(null);
      }
      const exchange = await exchangeService.createExchange(values.amountDetails, values.send.paymentMethod, values.receive.paymentMethod, values.receive.userInfos.value);

      navigate(`/exchange/tracking/${exchange.id}`);
      setLoading(false);
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.message) {
        setError(error?.response?.data?.message);
      }
    }
  };

  const validationSchema = Yup.object({
    send: Yup.object({
      paymentMethod: Yup.string().required(FIELD_REQUIRED), 
      amount: Yup.number().typeError(FIELD_POSITIVE_NUMBER).positive(FIELD_POSITIVE_NUMBER).required(FIELD_REQUIRED)
    }),
    receive: Yup.object({
      paymentMethod: Yup.string().required(FIELD_REQUIRED), 
      amount: Yup.number().typeError(FIELD_POSITIVE_NUMBER).positive(FIELD_POSITIVE_NUMBER).required(FIELD_REQUIRED),
    }),   
  });

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 py-8 w-full max-w-5xl mx-auto">
            {!isServiceAvailableIntoCountry && (
              <div className="bg-white shadow-lg rounded-sm">
                <header className="px-5 py-4">
                  <h2 className="text-2xl font-semibold text-amber-500 text-center">Bientôt dans votre pays !</h2>
                </header>
                <div className="px-3 py-4 space-y-6">
                  Nous travaillons à rendre notre service disponible dans votre pays.<br />Merci pour l'intérêt que vous portez à notre plateforme. Nous vous contacterons par e-mail dès que nous serons opérationnels dans votre pays.
                </div>
              </div>
            )}
            {isServiceAvailableIntoCountry && (<Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleExchangeSubmit}
            >
              <Form className="relative">
                {loading && (
                  <><LoadingBackground /><LoadingSpinner /></>
                )}
                <div className="bg-white shadow-lg rounded-sm">
                  <header className="px-5 py-4">
                    <h2 className="text-2xl font-semibold text-amber-500 text-center">Investir</h2>
                  </header>
                  <div className="px-3 py-4 space-y-6">
                    <div className="grid grid-cols-8 gap-2">
                      <div className="col-span-full">
                        <div className="text-lg font-semibold text-amber-500">Vous envoyez</div>
                      </div>
                      <div className="lg:col-span-5 col-span-full">
                        <label className="block text-xs lg:text-base font-medium mb-1" htmlFor="send.paymentMethod">Moyen de paiement</label>
                        <SendPaymentMethodField name="send.paymentMethod" as="select" className="form-select w-full h-16 text-xs lg:text-base" />
                        <div className="text-xs lg:text-base mt-1 text-rose-500"><ErrorMessage name="send.paymentMethod"/></div>
                      </div>
                      <div className="lg:col-span-5 col-span-full">
                        <label className="block text-xs lg:text-base font-medium mb-1" htmlFor="send.amount">Montant</label>
                        <SendAmount name="send.amount" className="form-input w-full h-16 text-xs lg:text-base" type="number" min="0" step="any" placeholder="0.00" />
                        <div className="text-xs lg:text-base mt-1 text-rose-500"><ErrorMessage name="send.amount"/></div>
                      </div>
                    </div>

                    <div className="grid grid-cols-8 gap-2">
                      <div className="col-span-full">
                        <div className="text-lg font-semibold text-amber-500">Vous recevez</div>
                      </div>
                      <div className="lg:col-span-5 col-span-full">
                        <label className="block text-xs lg:text-base font-medium mb-1" htmlFor="receive.paymentMethod">Moyen de paiement</label>
                        <ReceivePaymentMethodField name="receive.paymentMethod" as="select" className="form-select w-full h-16 text-xs lg:text-base" />
                        <div className="text-xs lg:text-base mt-1 text-rose-500"><ErrorMessage name="receive.paymentMethod"/></div>
                      </div>
                      <div className="lg:col-span-5 col-span-full">
                        <label className="block text-xs lg:text-base font-medium mb-1" htmlFor="receive.amount">Montant</label>
                        <ReceiveAmount name="receive.amount" className="form-input w-full h-16 text-sm lg:text-lg font-semibold border-none" type="number" min="0" step="any" placeholder="0.00" readOnly="readonly"/>
                        <div className="text-xs lg:text-base mt-1 text-rose-500"><ErrorMessage name="receive.amount"/></div>
                      </div>
                    </div>

                    <div className="grid grid-cols-8 gap-2">
                      <div className="lg:col-span-5 col-span-full">
                        <ReceiveUserInfos type="text" className="form-input w-full h-16 text-xs lg:text-base" />
                        <div className="text-xs lg:text-base mt-1 text-rose-500">
                          <ErrorMessage name="receive.userInfos.value"/>
                          {receiptUserInfosErrorMessage && <div>{receiptUserInfosErrorMessage}</div>}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <ExchangeSummary />

                <div className="bg-white shadow-lg rounded-sm text-center">
                  <div className="px-5 py-4 space-y-6">
                    {error && (<div className="text-base mt-1 text-rose-500">{ error }</div>)}
                    <button type="submit" className="btn w-full lg:w-80 h-16 text-lg bg-amber-500 hover:bg-amber-600 text-white whitespace-nowrap text-center">Continuer</button>
                  </div>
                </div>

              </Form>
            </Formik>
            )}
          </div>
        </main>

      </div>

    </div>
  );
}

export default Exchange;