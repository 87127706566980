import React from 'react';
import { FieldProps } from 'formik';
import 'react-phone-number-input/style.css';
import PhoneInput, { Country } from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr.json';

interface PhoneNumberFieldProps extends FieldProps {
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({ field, form, ...props }) => {
  const handleOnChange = (value: string | undefined) => {
    form.setFieldValue(field.name, value);
  };

  const handleOnCountryChange = (country: Country) => {
    form.setFieldValue('phoneNumberCountry', country);
  };


  return (
    <>
      <style>{`
        .custom-phone-input .PhoneInputInput {
          border-radius: 0.25rem !important;
          border-color: rgb(226, 232, 240) !important;
          border-width: 1px !important;
          width: 100%;
          height: 4rem;
        }
        .custom-phone-input .PhoneInputInput:focus {
          border-color: rgb(252 211 77) !important;
          --tw-ring-color: transparent !important;
        }
      `}</style>
      <div className="w-full h-16 custom-phone-input">
        <PhoneInput    
          value={field.value}
          onChange={handleOnChange}
          onCountryChange={handleOnCountryChange}
          onBlur={() => form.setFieldTouched(field.name, true)}
          labels={fr}
          {...props}
        />
      </div>
    </>
  );
};

export default PhoneNumberField;
